import React, { Component } from "react";
import axios from "axios";
import { createListConsts } from "../../utils/constants";
import downloadFile from "../../utils/downloadFile";

import CreateListTargetingComponent from "./CreateListTargetingComponent";
import CreateListParametersPageComponent from "./CreateListParametersComponent";
import CreateListExportPageComponent from "./CreateListExportComponent";
import CreateListConfirmPageComponent from "./CreateListConfirmComponent";
import { ParamsContext } from "../../../hooks/ParamsContext";

class CreateListPageComponent extends Component {
  FULL_EXPORTFILE_URL = process.env.REACT_APP_API_URL;
  static contextType = ParamsContext;

  constructor(props) {
    super(props);
    // Save params to state
    this.state = {
      requestParams: {},
    };

    this.sendQuery = this.sendQuery.bind(this);
    this.updateRequestParams = this.updateRequestParams.bind(this);
    this.handleDownloadExtract = this.handleDownloadExtract.bind(this);
    this.useSavedTargeting = this.useSavedTargeting.bind(this);

    this.goToFirstStep = this.goToFirstStep.bind(this);
    this.goToSecondStep = this.goToSecondStep.bind(this);
    this.goToThirdStep = this.goToThirdStep.bind(this);
    this.goToFourthStep = this.goToFourthStep.bind(this);
  }

  componentDidMount() {
    this.setCurrentParams();
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (!np.parameters.length) {
      this.setState({ requestParams: np.parameters });
    }
  }

  setCurrentParams() {
    if (this.props.parameters) {
      // Set new params
      this.setState({ requestParams: this.props.parameters });
    }
  }

  updateRequestParams(step, q) {
    const currentParams = this.state.requestParams;

    switch (step) {
      case createListConsts.thirdStep:
        if (q) {
          currentParams.fileFormat = q;
        }
        break;
      case createListConsts.secondStep:
        if (q) {
          currentParams.packExports = q;
        }
        break;
      case createListConsts.firstStep:
        if (q) {
          currentParams.packExports = q;
        }
        break;
      default:
        break;
    }

    this.setState({ requestParams: currentParams });
  }

  sendQuery(step = createListConsts.firstStep, q, betweenSteps) {
    let params = this.state.requestParams;

    switch (step) {
      case createListConsts.secondStep:
        if (q) {
          params.packExports = q;
        }
        break;
      default:
        if (q) {
          Object.keys(q).forEach((k) => {
            params[k] = q[k];
          });

          const newParams = {};
          Object.keys({ ...params })
            .filter((key) => !(key.match(/tab_.*/) && !q[key]))
            .map((key) => (newParams[key] = params[key]));
          params = { ...newParams };
        }
        break;
    }

    // Save query
    this.props.sendQueryToApi({ params, stepId: step }, betweenSteps);
    this.setState({
      requestParams: params,
      step: step,
    });
  }

  goToFirstStep() {
    this.sendQuery(createListConsts.firstStep, null, true);
  }
  goToSecondStep() {
    this.sendQuery(createListConsts.secondStep, null, true);
  }
  goToThirdStep() {
    this.sendQuery(createListConsts.thirdStep, null, true);
  }
  goToFourthStep() {
    this.sendQuery(createListConsts.fourthStep, null, true);
  }

  useSavedTargeting(id) {
    let params = {};
    params.myTargetingId = id;

    this.setState(
      {
        requestParams: params,
        step: createListConsts.firstStep,
      },
      () => {
        // Get props for first step
        this.props.sendQueryToApi(
          { params, stepId: createListConsts.firstStep },
          true,
        );
      },
    );
  }

  handleDownloadExtract() {
    const query = new URLSearchParams();

    if (this.state.requestParams) {
      Object.keys(this.state.requestParams).forEach((j) => {
        query.append(j, this.state.requestParams[j]);
      });
    }

    axios
      .post(
        this.FULL_EXPORTFILE_URL +
          "extract/file/?hl=fr&ds=" +
          this.props.accountId,
        query,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      )
      .then((r) => {
        downloadFile(r.data.url);
      });
  }

  render() {
    if (!this.props.components) {
      return null;
    }
    if (!this.props.parameters) {
      return null;
    }
    const currentStep = this.props.parameters.stepId;

    switch (currentStep) {
      case createListConsts.secondStep:
        return (
          <CreateListParametersPageComponent
            requestParams={this.props.parameters}
            accountId={this.props.accountId}
            components={this.props.components}
            sendQuery={this.sendQuery}
            updateQuery={this.updateRequestParams}
            handleDownload={this.handleDownloadExtract}
            goTo={this.sendQuery}
            next={this.goToThirdStep}
            back={this.goToFirstStep}
            isMobile={this.props.isMobile}
          />
        );
      case createListConsts.thirdStep:
        return (
          <CreateListExportPageComponent
            accountId={this.props.accountId}
            components={this.props.components}
            sendQuery={this.sendQuery}
            updateQuery={this.updateRequestParams}
            handleDownload={this.handleDownloadExtract}
            currentQuery={this.state.requestParams}
            goTo={this.sendQuery}
            next={this.goToFourthStep}
            back={this.goToSecondStep}
            isMobile={this.props.isMobile}
          />
        );
      case createListConsts.fourthStep:
        return (
          <CreateListConfirmPageComponent
            requestParams={this.props.parameters}
            components={this.props.components}
            accountId={this.props.accountId}
            goTo={this.sendQuery}
            goToFirst={this.goToFirstStep}
            back={this.goToThirdStep}
            isMobile={this.props.isMobile}
          />
        );
      default:
        return (
          <CreateListTargetingComponent
            isModalLoading={this.props.isModalLoading}
            pageIsLoading={this.props.pageIsLoading}
            accountId={this.props.accountId}
            components={this.props.components}
            sendQuery={this.sendQuery}
            goTo={this.sendQuery}
            next={this.goToSecondStep}
            useSavedTargeting={this.useSavedTargeting}
            currentQuery={this.state.requestParams}
            isMobile={this.props.isMobile}
          />
        );
    }
  }
}

export default CreateListPageComponent;
