import React from "react";
import { Col, Row } from "react-flexbox-grid";

import ListsTableComponent from "../../ListsTable/ListsTableComponent";

export default function AdminSegmentation({
  accountId,
  pageId,
  component,
  sendQueryToApi,
}) {
  return (
    <div className='scrollAuto'>
      <div className='page-heading'>
        <Row className='ovv'>
          <Col sm={12} md={9}>
            <h2>Segmentation</h2>
            <p>Retrouver la liste des segments créés sur votre compte.</p>
          </Col>
        </Row>
      </div>
      <ListsTableComponent
        deleteModalTitle='Supprimer une segmentation'
        deleteModalMessage='Voulez-vous vraiment supprimer cette segmentation ?'
        accountId={accountId}
        pageId={pageId}
        table={component}
        sendQueryToApi={(params, callBack) =>
          sendQueryToApi(
            {
              ...params,
              tabId: "segmentation",
            },
            callBack,
          )
        }
      />
    </div>
  );
}
