import React from "react";
import { Col, Row } from "react-flexbox-grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import ListsTableComponent from "../../ListsTable/ListsTableComponent";
import { Box } from "@mui/material";

export default function AdminConnector({
  accountId,
  pageId,
  component,
  sendQueryToApi,
}) {
  const [open, setOpen] = React.useState(false);
  const [isTestCrmSuccess, setIsTestCrmSuccess] = React.useState(false);
  const [crmType, setCrmType] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <div className='scrollAuto'>
        <div className='page-heading'>
          <Row className='ovv'>
            <Col sm={12} md={9}>
              <h2>Connecteurs</h2>
              <p>Retrouver la liste des connecteurs créés sur votre compte.</p>
            </Col>
          </Row>
        </div>
        <ListsTableComponent
          deleteModalTitle='Supprimer un connecteur'
          deleteModalMessage='Voulez-vous vraiment supprimer ce connecteur ?'
          accountId={accountId}
          pageId={pageId}
          table={component}
          sendQueryToApi={(params, callBack) =>
            sendQueryToApi(
              {
                ...params,
                tabId: "connector",
              },
              callBack,
            )
          }
          setOpen={setOpen}
          setIsTestCrmSuccess={setIsTestCrmSuccess}
          setCrmType={setCrmType}
        />
      </div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ position: "absolute" }}
      >
        <Alert
          onClose={handleClose}
          severity={isTestCrmSuccess ? "success" : "error"}
          variant='filled'
          sx={{
            width: "100%",
            backgroundColor: isTestCrmSuccess ? "#8DD674" : "#F7786B",
          }}
        >
          {isTestCrmSuccess
            ? `La liaison entre mList et votre CRM ${crmType} est établie.`
            : `La liaison avec votre CRM ${crmType} n'a pas été établie.`}
        </Alert>
      </Snackbar>
    </Box>
  );
}
