import React, { Component } from "react";
import axios from "axios";
import Select from "react-select";
import "./CreateFilterComponent.scss";

import Dropzone from "react-dropzone";
import { Col, Row } from "react-flexbox-grid";
// List images
import checkboxListImg from "../../../../../assets/images/illu_list.png";
import sliderListImg from "../../../../../assets/images/illu_slide.png";
import switchListImg from "../../../../../assets/images/illu_switch.png";

const companyTypes = [
  { value: "siret", label: "Siret" },
  { value: "siren", label: "Siren" },
];

const filterTypes = [
  {
    label: "Liste",
    text: `Le filtre de type liste vous permet de combiner ou d'exclure une liste de plusieurs critères pour réaliser vos sélections.`,
    img: checkboxListImg,
    type: "CHECKBOX_LIST",
  },
  {
    label: "Numérique",
    text: `Le filtre de type numérique vous permet de sélectionner des tranches de données. Vous pourrez utiliser la réglette ou entrer directement la plage de données dans les champs.`,
    img: sliderListImg,
    type: "SLIDER",
  },
  {
    label: "On/Off",
    text: `Le filtre de type On/Off vous permet d'utiliser ou d'exclure des données issues de vos fichiers pour votre sélection.`,
    img: switchListImg,
    type: "SWITCH",
  },
];

export default class CreateFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      filterName: "",
      serverFileName: "",
      acceptedFile: null,
      selectedCompanyType: "",
      columnCompanyId: null,
      columnFilter: null,
      headers: [],
      filterTypeAvailable: [],
      selectedFilterType: "",
    };

    this.handleFilterName = this.handleFilterName.bind(this);
    this.handleColumnCompany = this.handleColumnCompany.bind(this);
    this.handleColumnFilter = this.handleColumnFilter.bind(this);
    this.goPrevStep = this.goPrevStep.bind(this);
    this.goNextStep = this.goNextStep.bind(this);

    this.updateRadio = this.updateRadio.bind(this);
    this.updateFilterTypeRadio = this.updateFilterTypeRadio.bind(this);

    this.onDrop = this.onDrop.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  componentWillUnmount() {
    // Need to clear all things
    if (this.state.currentStep === 5) {
      this.props.reloadItems();
    }
    this.clearAllData();
  }

  clearAllData() {
    this.setState({
      currentStep: 1,
      filterName: "",
      serverFileName: "",
      acceptedFile: null,
      selectedCompanyType: "",
      columnCompanyId: null,
      columnFilter: null,
      headers: [],
      filterTypeAvailable: [],
      selectedFilterType: "",
    });
    this.props.updateLoader(false);
  }

  handleFilterName(e) {
    this.setState({
      filterName: e.target.value,
    });
  }

  handleColumnCompany(e) {
    this.setState({
      columnCompanyId: {
        value: e.value,
        label: e.label,
      },
    });
  }

  handleColumnFilter(e) {
    const filterTypes = this.state.serverHeaders[e.value];

    this.setState({
      columnFilter: {
        value: e.value,
        label: e.label,
      },
      filterTypeAvailable: filterTypes.filterTypeAvailable,
    });
  }

  getModalHeader() {
    return (
      <header>
        <span>Créer un filtre</span>
        {this.getStepsList()}
      </header>
    );
  }

  getStepsList() {
    return (
      <ul className='stepsList'>
        <li className={"step " + (this.state.currentStep >= 1 ? "active" : "")}>
          <span>1</span>
          {this.state.currentStep > 1 ? (
            <span className='checkmark'>
              <span className='icon icon-confirm'></span>
            </span>
          ) : null}
        </li>
        <li className={"step " + (this.state.currentStep >= 2 ? "active" : "")}>
          <span>2</span>
          {this.state.currentStep > 2 ? (
            <span className='checkmark'>
              <span className='icon icon-confirm'></span>
            </span>
          ) : null}
        </li>
        <li className={"step " + (this.state.currentStep >= 3 ? "active" : "")}>
          <span>3</span>
          {this.state.currentStep > 3 ? (
            <span className='checkmark'>
              <span className='icon icon-confirm'></span>
            </span>
          ) : null}
        </li>
        <li className={"step " + (this.state.currentStep >= 4 ? "active" : "")}>
          <span>4</span>
          {this.state.currentStep > 4 ? (
            <span className='checkmark'>
              <span className='icon icon-confirm'></span>
            </span>
          ) : null}
        </li>
        <li className={"step " + (this.state.currentStep >= 5 ? "active" : "")}>
          <span>5</span>
        </li>
      </ul>
    );
  }

  sendFile(nextStep) {
    const data = new FormData();
    data.append("file", this.state.acceptedFile);

    this.props.updateLoader(true);
    axios
      .post("/api/mlist/filters/import?filterType=CUSTOMFILTER", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        const serverHeaders = {};
        const headers = [
          {
            value: "",
            label: "Choisir une colonne",
          },
        ];

        res.data.headers.forEach((h) => {
          const id = h.columnName;
          serverHeaders[id] = h;

          headers.push({
            value: id,
            label: id,
          });
        });

        this.setState({
          serverHeaders,
          headers,
          serverFileName: res.data.fileName,
          currentStep: nextStep,
        });
        this.props.updateLoader(false);
      });
  }

  saveFilter(nextStep) {
    // const data = new FormData();
    //
    // data.append("name", this.state.filterName);
    // data.append("fileName", this.state.serverFileName);
    // data.append("accountId", this.props.ds);
    // data.append(
    //   "identifier",
    //   JSON.stringify({
    //     type: this.state.selectedCompanyType,
    //     columnIndex: this.getColumnIndex(this.state.columnCompanyId.value)
    //   })
    // );
    // data.append(
    //   "filter",
    //   JSON.stringify({
    //     type: this.state.selectedFilterType,
    //     columnIndex: this.getColumnIndex(this.state.columnFilter.value),
    //     columnName: this.getColumnName(this.state.columnFilter.value)
    //   })
    // );

    this.props.updateLoader(true);
    axios
      .post(
        "/api/mlist/web/customFilters",
        {
          name: this.state.filterName,
          fileId: this.state.serverFileName,
          accountId: this.props.accountId,
          identifier: {
            type: this.state.selectedCompanyType,
            columnIndex: this.getColumnIndex(this.state.columnCompanyId.value),
          },
          options: {
            type: this.state.selectedFilterType,
            columnIndex: this.getColumnIndex(this.state.columnFilter.value),
            columnName:
              this.state.serverHeaders[this.state.columnFilter.value]
                .columnName,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        this.setState(
          {
            currentStep: nextStep,
            filterName: "",
            serverFileName: "",
            acceptedFile: null,
            selectedCompanyType: "",
            columnCompanyId: null,
            columnFilter: null,
            headers: [],
            filterTypeAvailable: [],
            selectedFilterType: "",
          },
          () => {
            this.props.updateLoader(false);
          },
        );
      });
  }

  getColumnIndex(id) {
    return this.state.serverHeaders[id].columnIndex;
  }

  getColumnName(id) {
    return this.state.serverHeaders[id].value;
  }

  updateRadio(e) {
    this.setState({
      selectedCompanyType: e.target.value,
    });
  }

  updateFilterTypeRadio(e) {
    this.setState({
      selectedFilterType: e.target.value,
    });
  }

  onDrop(acceptedFiles) {
    this.setState({
      acceptedFile: acceptedFiles[0],
    });
  }

  removeFile(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      acceptedFile: null,
    });
  }

  getSelectedCompanyName() {
    const filtered = companyTypes.filter((i) => {
      return i.value === this.state.selectedCompanyType;
    });

    if (filtered.length > 0) {
      return filtered[0].label;
    }

    return null;
  }

  getFilteredFormats() {
    return filterTypes.filter((t) => {
      return this.state.filterTypeAvailable.indexOf(t.type) > -1;
    });
  }

  getFilteredOptions() {
    const options = [];

    this.state.headers.forEach((h) => {
      const item = h;
      let disabled = false;

      if (this.state.columnCompanyId) {
        if (this.state.columnCompanyId.value === h.value) {
          disabled = true;
        }
      }
      if (this.state.columnFilter) {
        if (this.state.columnFilter.value === h.value) {
          disabled = true;
        }
      }

      item.disabled = disabled;
      options.push(item);
    });

    return options;
  }

  getDragFileZone() {
    return (
      <Dropzone onDrop={this.onDrop} accept='.csv'>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              className: "dropzone " + (isDragActive ? "active-area" : ""),
            })}
          >
            <input {...getInputProps()} />
            <div className='drop-container'>
              <div className='title-icon'>
                <span
                  className={
                    "icon " +
                    (this.state.acceptedFile ? "icon-confirm" : "icon-upload")
                  }
                ></span>
              </div>
              <div className='description'>
                <h3 className='desc-title'>
                  {this.state.acceptedFile
                    ? `Fichier importé avec succès`
                    : `Importer le fichier`}
                </h3>
                <p className='desc-text'>
                  {this.state.acceptedFile
                    ? `${this.state.acceptedFile.name}`
                    : `Format d'import CSV`}
                  {this.state.acceptedFile ? (
                    <span className='remove-file' onClick={this.removeFile}>
                      <span className='icon icon-remove'></span>
                    </span>
                  ) : null}
                </p>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    );
  }

  getThirdPartSelection() {
    return (
      <React.Fragment>
        <Row>
          <Col xs={9}>
            <p className='step-subtitle'>
              Quel identifiant entreprise avez-vous importé?
            </p>
            <div className='radio-holder'>
              {companyTypes.map((r, i) => {
                return (
                  <div className='radio-item ' key={i}>
                    <label htmlFor={"radio" + r.value}>
                      <div
                        className={
                          "custom-radio " +
                          (this.state.selectedCompanyType === r.value
                            ? "checked"
                            : "")
                        }
                      >
                        <input
                          id={"radio" + r.value}
                          type='radio'
                          checked={this.state.selectedCompanyType === r.value}
                          onChange={this.updateRadio}
                          value={r.value}
                        />
                      </div>
                      {r.label}
                    </label>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={9}>
            <p className='step-subtitle'>
              Quel nom porte votre colonne contenant l'identifiant?
            </p>
            <span className='selected-company-type'>
              {this.getSelectedCompanyName()}
            </span>
            <Select
              className='select company-id-select'
              classNamePrefix='react-select'
              isDisabled={!this.state.selectedCompanyType}
              value={this.state.columnCompanyId}
              placeholder='Choisir une colonne'
              onChange={this.handleColumnCompany}
              isOptionDisabled={(option) => option.disabled}
              options={this.getFilteredOptions()}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={9}>
            <p className='step-subtitle'>
              Quel nom porte votre colonne contenant la donnée de filtre ?
            </p>
            <Select
              className='select company-id-select'
              classNamePrefix='react-select'
              value={this.state.columnFilter}
              placeholder='Choisir une colonne'
              onChange={this.handleColumnFilter}
              isOptionDisabled={(option) => option.disabled}
              options={this.getFilteredOptions()}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  getFourthPartSelection() {
    const availableFilterFormats = this.getFilteredFormats();
    return (
      <div className='formats-list'>
        <ul>
          {availableFilterFormats.map((filter, i) => {
            return (
              <li key={i}>
                <p className='filter-title'>{filter.label}</p>
                <label htmlFor={"radio" + filter.type}>
                  <div
                    className={
                      "custom-radio " +
                      (this.state.selectedFilterType === filter.type
                        ? "checked"
                        : "")
                    }
                  >
                    <input
                      id={"radio" + filter.type}
                      type='radio'
                      checked={this.state.selectedFilterType === filter.type}
                      onChange={this.updateFilterTypeRadio}
                      value={filter.type}
                    />
                  </div>
                  <img src={filter.img} alt={filter.title} />
                </label>
                <p className='info-text'>{filter.text}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  getStepContent() {
    switch (this.state.currentStep) {
      case 2:
        return (
          <React.Fragment>
            <h3 className='step-title'>Chargement de votre fichier</h3>
            {this.getDragFileZone()}
          </React.Fragment>
        );
      case 3:
        return <React.Fragment>{this.getThirdPartSelection()}</React.Fragment>;
      case 4:
        return (
          <React.Fragment>
            <h3 className='step-title'>
              Sélectionnez le format de votre choix pour l'affichage de vos
              données
            </h3>
            {this.getFourthPartSelection()}
          </React.Fragment>
        );
      case 5:
        return (
          <p>
            Vous avez terminé la création de votre filtre, ce dernier sera
            disponible aprés traitement directement à I'étape de ciblage.
          </p>
        );
      default:
        return (
          <React.Fragment>
            <h3 className='step-title'>Nommer votre filtre</h3>
            <input
              type='text'
              name='filter-name'
              id='filter-name'
              value={this.state.filterName}
              onChange={this.handleFilterName}
              placeholder='Nom du fichier...'
            />
          </React.Fragment>
        );
    }
  }

  getStepButtons() {
    if (this.state.currentStep === 5) {
      return null;
    }

    return (
      <div className='actions-holder'>
        <button
          className={
            "btn btn-secondary " +
            (this.state.currentStep === 1 ? "disabled" : "")
          }
          onClick={this.goPrevStep}
        >
          Précédent
        </button>
        <button
          className={
            "btn btn-primary " + (this.disableNextBtn() ? "disabled" : "")
          }
          onClick={this.goNextStep}
        >
          Suivant
        </button>
      </div>
    );
  }

  goPrevStep() {
    let prevStep = this.state.currentStep - 1;
    switch (prevStep) {
      case 3:
        this.setState({
          selectedFilterType: "",
        });
        break;
      case 2:
        this.setState({
          selectedCompanyType: "",
          columnCompanyId: null,
          columnFilter: null,
        });
        break;
      default:
        this.setState({
          acceptedFile: null,
        });
        break;
    }
    this.setState({
      currentStep: prevStep,
    });
  }

  goNextStep() {
    const nextStep = this.state.currentStep + 1;
    switch (this.state.currentStep) {
      case 2:
        this.sendFile(nextStep);
        break;
      case 4:
        this.saveFilter(nextStep);
        break;
      default:
        this.setState({
          currentStep: nextStep,
        });
        break;
    }
  }

  disableNextBtn() {
    let need2disabled = false;

    switch (this.state.currentStep) {
      case 2:
        need2disabled = !this.state.acceptedFile;
        break;
      case 3:
        if (this.state.selectedCompanyType === "") {
          need2disabled = true;
        }
        if (!this.state.columnCompanyId) {
          need2disabled = true;
        } else {
          if (this.state.columnCompanyId.value === "") {
            need2disabled = true;
          }
        }
        if (!this.state.columnFilter) {
          need2disabled = true;
        } else {
          if (this.state.columnFilter.value === "") {
            need2disabled = true;
          }
        }
        break;
      case 4:
        need2disabled = !this.state.selectedFilterType;
        break;
      case 5:
        break;
      default:
        need2disabled = this.state.filterName === "";
    }

    return need2disabled;
  }

  render() {
    return (
      <React.Fragment>
        {this.getModalHeader()}
        <div className='single-tab-modal-content'>
          <div className='step-content'>{this.getStepContent()}</div>
          {this.getStepButtons()}
        </div>
      </React.Fragment>
    );
  }
}
