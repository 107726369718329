import React, { useState } from "react";

import { Col, Row } from "react-flexbox-grid";
import ColumnChartComponent from "../../CreateList/components/ColumnChart/ColumnChartComponent";
import DoughnutGraphicComponent from "../../CreateList/components/DoughnutGraphic/DoughnutGraphicComponent";
import DashboardAccount from "../../Dashboard/components/DashboardAccount";
import DashboardContact from "../../Dashboard/components/DashboardContact";
import NoData from "../../NoData/NoData";
import { format, parse } from "date-fns";
import DatePicker from "react-datepicker";
import PageLoader from "../../pageLoader/pageLoaderComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { defaults, Line } from "react-chartjs-2";
import HorizontalColumnChartComponent from "../../CreateList/components/HorizontalColumnChart/HorizontalColumnChartComponent";
defaults.global.defaultFontFamily = "Raleway";

const AdministrationDashboard = ({ component, sendQueryToApi }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const [dateBegin, setDateBegin] = useState(
    parse(component.dateBegin, "yyyyMMdd", new Date()),
  );
  const [dateEnd, setDateEnd] = useState(
    parse(component.dateEnd, "yyyyMMdd", new Date()),
  );
  const history = useHistory();

  const applyDate = () => {
    setIsUpdating(true);
    sendQueryToApi(
      {
        tabId: "dashboard",
        stat_date_begin: format(dateBegin, "yyyyMMdd"),
        stat_date_end: format(dateEnd, "yyyyMMdd"),
      },
      () => setIsUpdating(false),
    );
    setIsUpdating(true);
  };

  const primaryComponents = component.components.find(
    (c) => c.type === "DashboardPrimaryPanel",
  );
  const secondaryComponents = component.components.find(
    (c) => c.type === "DashboardSecondaryPanel",
  );

  const link = () => {
    let urlParameter = `?tabId=dashboard&date00=${format(
      dateBegin,
      "yyyyMMdd",
    )}&date01=${format(dateEnd, "yyyyMMdd")}`;
    history.push(urlParameter);
  };

  const renderDashboardAccount = () => {
    return (
      primaryComponents &&
      primaryComponents.myAccount && (
        <DashboardAccount
          component={primaryComponents.myAccount}
          hideHeader={true}
        />
      )
    );
  };

  const renderDashboardContact = () => {
    return (
      primaryComponents &&
      primaryComponents.contact &&
      primaryComponents.contact.authorized && (
        <DashboardContact component={primaryComponents.contact} />
      )
    );
  };

  const renderStatistics = () => {
    return secondaryComponents.dashboardStatistics.map((statistic, key) => {
      return (
        <div key={key} className='panel-block dashboard-account-component'>
          <div className='title-links-container my-account'>
            <div className='my-account__label'>Activité sur le compte</div>
            <div className='admin-dashboard dates'>
              <div className='label'>Depuis</div>
              <DatePicker
                classname='date_picker_begin'
                dateFormat='dd/MM/yyyy'
                selected={dateBegin}
                maxDate={new Date()}
                onChange={setDateBegin}
                locale='fr'
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 0],
                    },
                  },
                  {
                    name: "preventOverflow",
                    options: {
                      rootBoundary: "viewport",
                      tether: false,
                      altAxis: true,
                    },
                  },
                ]}
              />
              <div className='label'>Jusqu'à</div>
              <DatePicker
                dateFormat='dd/MM/yyyy'
                selected={dateEnd}
                minDate={dateBegin}
                maxDate={new Date()}
                locale='fr'
                onChange={setDateEnd}
                popperPlacement='bottom-start'
                popperModifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 0],
                    },
                  },
                  {
                    name: "preventOverflow",
                    options: {
                      rootBoundary: "viewport",
                      tether: false,
                      altAxis: true,
                    },
                  },
                ]}
              />
              <button
                className='btn btn-primary btn-primary-small'
                onClick={() => {
                  applyDate();
                  link();
                }}
                type='button'
              >
                <span className='icon icon-step-confirmation' />
              </button>
            </div>
          </div>

          {statistic.charts && renderChartComponents(statistic.charts)}
        </div>
      );
    });
  };

  const renderChartComponents = (charts) => {
    return (
      <Row>
        {charts.map((chart, key) => {
          const datavizData = chart.chartType === "line" && {
            labels: chart.data.map((el) => el["createAt"]),
            datasets: [
              {
                label: "Toutes les actions ",
                data: chart.data.map((el) => el["total"]),
                fill: true,
                backgroundColor: "#8dd674",
                borderColor: "#8dd674",
                tension: 0,
              },
            ],
          };

          const optionDataviz = chart.chartType === "line" && {
            title: {
              display: true,
              text: chart.title,
              fontColor: "#4a63a1",
              fontSize: "14",
              fontStyle: "bold",
            },
            legend: {
              display: false,
              position: "bottom",
              onHover: function (e) {
                e.target.style.cursor = "pointer";
              },
            },
            hover: {
              onHover: function (e) {
                var point = this.getElementAtEvent(e);
                if (point.length) e.target.style.cursor = "pointer";
                else e.target.style.cursor = "default";
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: true,
                  },
                  type: "time",
                  time: {
                    parser: "YYYY-MM-DD",
                    tooltipFormat: "DD/MM/YYYY",
                    unit: "day",
                    unitStepSize: 1,
                    displayFormats: {
                      month: "DD/MM/YYYY",
                    },
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          };
          switch (chart.chartType) {
            case "doughnut":
              return (
                <Col key={key} className='card-item chart-item' sm={12} md={6}>
                  <DoughnutGraphicComponent item={chart} />
                </Col>
              );
            case "bar":
              return (
                <Col key={key} className='card-item chart-item' sm={12} md={6}>
                  <ColumnChartComponent item={chart} />
                </Col>
              );
            case "basic":
              return (
                <Col
                  key={key}
                  className='card-item chart-item basic-chart'
                  sm={12}
                  md={6}
                >
                  <div className='chart-content'>
                    <div className='chart-values'>
                      <div className='chart-title'>{chart.title}</div>
                      <div className='chart-value'>{chart.value}</div>
                    </div>
                  </div>
                </Col>
              );
            case "horizontalBar":
              return (
                <Col key={key} className='card-item chart-item' sm={12} md={6}>
                  <HorizontalColumnChartComponent item={chart} />
                </Col>
              );
            case "line":
              return (
                <Col key={key} className='card-item chart-item' sm={12} md={12}>
                  <div style={{ backgroundColor: "#FFFFFF" }}>
                    <Line
                      data={datavizData}
                      options={optionDataviz}
                      width={4}
                      height={1}
                    ></Line>
                  </div>
                </Col>
              );
            default:
              return (
                <Col
                  key={key}
                  className='card-item chart-item basic-chart'
                  sm={12}
                  md={6}
                >
                  <div className='chart-content'>
                    <div className='chart-values'>
                      <div className='chart-title mb-10'>{chart.title}</div>
                      <NoData />
                    </div>
                  </div>
                </Col>
              );
          }
        })}
      </Row>
    );
  };

  return (
    <Row className={"PageContent h-100 dashboard-view"}>
      <Col
        sm={12}
        md={12}
        lg={5}
        className='h-100 with-shadow primary-panel'
        style={{ paddingBottom: "3%" }}
      >
        <>
          {renderDashboardAccount()}
          {renderDashboardContact()}
        </>
      </Col>
      <Col sm={12} md={12} lg={7} className='h-100 secondary-panel'>
        {isUpdating && <PageLoader opacity={true} />}
        <div>{renderStatistics()}</div>
      </Col>
    </Row>
  );
};

export default AdministrationDashboard;
