import React, { Component } from "react";
import "./MapComponent.scss";
import { divIcon } from "leaflet";
import { DomEvent } from "leaflet";
import EditControlFeatureGroup from "./FeatureGroup";
import {
  Map as LeafletMap,
  TileLayer,
  Marker,
  Polygon,
  Circle,
  ZoomControl,
} from "react-leaflet";
import axios from "axios";
import Control from "react-leaflet-control";
import FullscreenControl from "react-leaflet-fullscreen";
import { createListConsts } from "../../../../utils/constants";

// const stamenTonerTiles ="https://{s}.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png";
const stamenTonerTiles = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const stamenTonerAttr =
  'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';

export default class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.zoomTimer = null;

    this.state = {
      mapElements: new Map(),
      zoom: 4,
      drawing: false,
      timer: null,
      query: {},
      latTopLeft: createListConsts.mapPositionDef.latTopLeft,
      lonTopLeft: createListConsts.mapPositionDef.lonTopLeft,
      latBottomRight: createListConsts.mapPositionDef.latBottomRight,
      lonBottomRight: createListConsts.mapPositionDef.lonBottomRight,
      bounds: [
        [
          createListConsts.mapPositionDef.latTopLeft,
          createListConsts.mapPositionDef.lonTopLeft,
        ],
        [
          createListConsts.mapPositionDef.latBottomRight,
          createListConsts.mapPositionDef.lonBottomRight,
        ],
      ],
      companiesList: [],
      isFullScreen: window.fullScreenApi.isFullScreen(),
    };

    this.zoomInTwice = () => this.setState({ zoom: this.state.zoom + 2 });
    this.zoomOutTwice = () => this.setState({ zoom: this.state.zoom - 2 });

    this.toggleDrawing = this.toggleDrawing.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.handleSizeChanges = this.handleSizeChanges.bind(this);
    this.refContainer = this.refContainer.bind(this);
    this.removeCurrentCompany = this.removeCurrentCompany.bind(this);
    this.sendQuery = this.sendQuery.bind(this);

    // Get data
    this.getData(this.props.currentQuery);
  }

  componentDidMount() {}

  refContainer(el) {
    if (el) {
      this.customContainer = el;

      DomEvent.disableClickPropagation(
        this.customContainer,
      ).disableScrollPropagation(this.customContainer);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.currentQuery) {
      if (
        this.state.query.query !== newProps.currentQuery.query ||
        this.state.query.segments !== newProps.currentQuery.segments
      ) {
        const newPr = JSON.parse(JSON.stringify(newProps.currentQuery));
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getData(newPr);
        }, 300);
      }
    }
  }

  _getHighestId = () => {
    // Include 0 to make sure we always have a valid id
    return Math.max(0, ...this.state.mapElements.keys());
  };

  _handleCreated = (evt) => {
    const { layerType, layer } = evt;

    const newMap = new Map(this.state.mapElements.entries());
    const newId = this._getHighestId() + 1;
    const props = {
      polygon: (l) => ({ positions: l.getLatLngs()[0] }),
      circle: (l) => ({ center: l.getLatLng(), radius: l.getRadius() }),
    }[layerType](layer);

    newMap.set(newId, { type: layerType, ...props });

    this.setState({ mapElements: newMap }, () => {
      this.sendQuery();
    });
  };

  sendQuery() {
    const mapElements = this.state.mapElements;
    const tableId = [...mapElements.keys()];

    let newCriterionGroup = [];

    let newQueryObject = {
      query: this.state.query.query ? JSON.parse(this.state.query.query) : [],
      queryCondition: this.state.query.queryCondition
        ? JSON.parse(this.state.query.queryCondition)
        : [],
    };

    tableId.forEach((id) => {
      const element = mapElements.get(id);
      let fullCriteria = {
        query: [0, "mlist.location"],
        queryCondition: "OR",
      };

      var criteriaValues = [];

      if (element.type === "circle") {
        //it's a circle
        fullCriteria.query.push("CIRCLE_METER");
        criteriaValues.push(element.center.lat.toString());
        criteriaValues.push(element.center.lng.toString());
        criteriaValues.push(element.radius.toString());
      } else {
        //it's a polygon
        fullCriteria.query.push("POLYGONE");
        element.positions.forEach((position) => {
          criteriaValues.push(
            position.lat.toString() + "," + position.lng.toString(),
          );
        });
      }
      fullCriteria.query.push(criteriaValues);
      fullCriteria.query.push(0);
      newCriterionGroup.push(fullCriteria);
    });

    let isCriterionGroupExisting = false;

    if (this.state.query.query) {
      newQueryObject.query.forEach((criterionGroup, criterionGroupKey) => {
        criterionGroup.forEach((criterion) => {
          const criterionConceptName = criterion[1];
          if (
            criterionConceptName === "mlist.location" &&
            !isCriterionGroupExisting
          ) {
            // Adding criterion into an existing criterionGroup
            newQueryObject.query[criterionGroupKey] = [];
            newCriterionGroup.forEach((newCriterionGroup) => {
              newQueryObject.query[criterionGroupKey].push(
                newCriterionGroup.query,
              );
            });

            if (
              !newQueryObject.query[criterionGroupKey].length ||
              !newCriterionGroup.length
            ) {
              //Delete criterion group
              newQueryObject.query.splice(criterionGroupKey, 1);
              newQueryObject.queryCondition.splice(criterionGroupKey, 1);
            }
            isCriterionGroupExisting = true;
          }
        });
      });
    }

    if (!isCriterionGroupExisting || !this.state.query.query) {
      newCriterionGroup.forEach((criterionGroup) => {
        newQueryObject.query.push([criterionGroup.query]);
      });
      newQueryObject.queryCondition.push(newCriterionGroup[0].queryCondition);
    }

    newQueryObject.query = JSON.stringify(newQueryObject.query);
    newQueryObject.queryCondition = JSON.stringify(
      newQueryObject.queryCondition,
    );

    this.props.sendQuery(createListConsts.firstStep, newQueryObject, true);
  }

  _handleDeleted = (elem, layer, evt) => {
    const id = Number.parseInt(elem.key);
    const item = this.state.mapElements.get(id);
    if (!item) {
      console.log("No matching item, perhaps a race? skip");
      return;
    }

    const newMap = new Map(this.state.mapElements.entries());

    if (item.type.indexOf("marker") !== -1) {
      const newId = this._getHighestId() + 1;
      newMap.set(newId, newMap.get(id));
    }

    newMap.delete(id);
    this.setState({ mapElements: newMap }, () => {
      this.sendQuery();
    });
  };

  getData(newQuery) {
    if (
      this.state.latTopLeft !== this.state.latBottomRight ||
      this.state.lonTopLeft !== this.state.lonBottomRight
    ) {
      const BASE_DOMAIN = process.env.REACT_APP_API_URL;

      const FULL_URL = `${BASE_DOMAIN}v2/maps?ds=${this.props.ds}&hl=fr`;

      const query = new URLSearchParams();

      const data = {
        latTopLeft: this.state.latTopLeft,
        lonTopLeft: this.state.lonTopLeft,
        latBottomRight: this.state.latBottomRight,
        lonBottomRight: this.state.lonBottomRight,
      };

      query.append("nbTopHits", 5); //Always set to 5
      query.append("maxOfDisplayedClusterReturned", 20); //Always set to 20

      // Set selected bounds
      if (this.state.selectedBounds) {
        data.latTopLeft = this.state.selectedBounds.latTopLeft;
        data.lonTopLeft = this.state.selectedBounds.lonTopLeft;
        data.latBottomRight = this.state.selectedBounds.latBottomRight;
        data.lonBottomRight = this.state.selectedBounds.lonBottomRight;

        query.append("latTopLeft", this.state.selectedBounds.latTopLeft);
        query.append("lonTopLeft", this.state.selectedBounds.lonTopLeft);
        query.append(
          "latBottomRight",
          this.state.selectedBounds.latBottomRight,
        );
        query.append(
          "lonBottomRight",
          this.state.selectedBounds.lonBottomRight,
        );
      } else {
        query.append("latTopLeft", this.state.latTopLeft);
        query.append("lonTopLeft", this.state.lonTopLeft);
        query.append("latBottomRight", this.state.latBottomRight);
        query.append("lonBottomRight", this.state.lonBottomRight);
      }

      const precision = this._getPrecisionLevel(
        this._getDistanceFromLatLonInKm(
          data.latTopLeft,
          data.lonTopLeft,
          data.latBottomRight,
          data.lonBottomRight,
        ),
      );

      query.append("precision", precision);

      if (newQuery) {
        Object.keys(newQuery).forEach((k) => {
          query.append(k, newQuery[k]);
        });
      }

      axios
        .post(FULL_URL, query, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((resp) => {
          const newMap = new Map();
          if (resp.data.drawings) {
            resp.data.drawings.forEach((draw, key) => {
              if (draw.type === "CIRCLE_METER") {
                newMap.set(key + 1, {
                  type: "circle",
                  center: {
                    lat: parseFloat(draw.expression[0]),
                    lng: parseFloat(draw.expression[1]),
                  },
                  radius: parseFloat(draw.expression[2]),
                });
              } else if (draw.type === "POLYGONE") {
                let positions = [];
                draw.expression.forEach((position) => {
                  position = position.split(",");
                  let positionObject = {
                    lat: parseFloat(position[0]),
                    lng: parseFloat(position[1]),
                  };
                  positions.push(positionObject);
                });
                newMap.set(key + 1, {
                  type: "polygon",
                  positions,
                });
              }
            });
          }
          this.setState({
            companiesList: resp.data.companies,
            query: newQuery,
            mapElements: newMap,
          });
        });
    }
  }

  handleLocationChange(e) {
    const bounds = e.sourceTarget.getBounds();
    const newBounds = {
      latTopLeft: bounds.getNorthWest().lat,
      lonTopLeft: bounds.getNorthWest().lng,
      latBottomRight: bounds.getSouthEast().lat,
      lonBottomRight: bounds.getSouthEast().lng,
    };

    this.setState(
      {
        selectedBounds: newBounds,
      },
      () => {
        // Get new list of companies
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getData(this.state.query);
        }, 1000);
      },
    );
  }

  handleMarkerClick(c) {
    const currentCompany = this.state.selectedCompany;
    if (currentCompany) {
      if (
        currentCompany.geoLocation.latitude === c.geoLocation.latitude &&
        currentCompany.geoLocation.longitude === c.geoLocation.longitude
      ) {
        this.setState({
          selectedCompany: null,
        });
      } else {
        this.setState({
          selectedCompany: c,
        });
      }
    } else {
      this.setState({
        selectedCompany: c,
      });
    }
  }

  removeCurrentCompany() {
    this.setState({
      selectedCompany: null,
    });
  }

  getMarker(c, idx) {
    let activeClassName = "";
    if (this.state.selectedCompany) {
      if (
        this.state.selectedCompany.geoLocation.latitude ===
          c.geoLocation.latitude &&
        this.state.selectedCompany.geoLocation.longitude ===
          c.geoLocation.longitude
      ) {
        activeClassName += "active";
      }
    }

    const icon = divIcon({
      className: "map-label",
      html: `<div class="map-marker ${activeClassName}"><div class="marker-icon"><span class="count-label">${c.companyCount}</span></div></div>`,
      iconSize: null,
    });

    return (
      <Marker
        key={`marker-${idx}`}
        icon={icon}
        position={[c.geoLocation.latitude, c.geoLocation.longitude]}
        onClick={() => this.handleMarkerClick(c)}
      ></Marker>
    );
  }

  toggleDrawing() {
    this.setState({
      drawing: !this.state.drawing,
    });
  }

  getCurrentCompany() {
    if (!this.state.selectedCompany) {
      return null;
    }
    return (
      <div className='selected-company' ref={this.refContainer}>
        <div className='header-company'>
          <h2>
            {this.state.selectedCompany.companyCount} entreprises dans la zone
          </h2>
          <p>Liste des 5 premières</p>
          <span className='close' onClick={this.removeCurrentCompany}>
            <span className='icon icon-close'></span>
          </span>
        </div>
        <div className='top-hits'>
          <>
            <ul>
              {this.state.selectedCompany.topHits.map((h, i) => {
                return (
                  <li key={i}>
                    <div className='photo-holder'>
                      <span className='icon icon-camera'></span>
                    </div>
                    <div className='content-holder'>
                      <h3>
                        <a
                          href={h.url}
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          {h.businessName}
                        </a>
                      </h3>
                      <p>{h.coreBusiness}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        </div>
      </div>
    );
  }

  handleSizeChanges() {
    // If fullscreen changed
    if (this.state.isFullScreen !== window.fullScreenApi.isFullScreen()) {
      // Get new list of companies
      if (this.zoomTimer) {
        clearTimeout(this.zoomTimer);
      }
      this.zoomTimer = setTimeout(() => {
        if (window.fullScreenApi.isFullScreen()) {
          this.zoomInTwice();
        } else {
          this.zoomOutTwice();
        }
        this.setState({
          isFullScreen: window.fullScreenApi.isFullScreen(),
        });
      }, 300);
    }
  }

  /**
   * Récupération de la précision pour une recherche géolocalisée sur ES.
   * @param {Number} distance en kilomètres
   * @returns {Number} niveau à utiliser sur ES.
   */
  _getPrecisionLevel = (distance) => {
    var lvl = 8;
    if (distance >= 5000) {
      lvl = 1;
    } else if (distance >= 1250 && distance < 5000) {
      lvl = 2;
    } else if (distance >= 156 && distance < 1250) {
      lvl = 3;
    } else if (distance >= 39 && distance < 156) {
      lvl = 4;
    } else if (distance >= 19.5 && distance < 39) {
      lvl = 5;
    } else if (distance >= 4.9 && distance < 19.5) {
      lvl = 6;
    } else if (distance >= 1.2 && distance < 4.9) {
      lvl = 7;
    } else if (distance >= 0.152 && distance < 1.2) {
      lvl = 8;
    } else if (distance >= 0.038 && distance < 0.152) {
      lvl = 9;
    } else if (distance >= 0.0048 && distance < 0.038) {
      lvl = 10;
    }
    return lvl;
  };

  /**
   * Calcule la distance entre deux points (latitudes, longitudes) en kilomètres.
   * @param {type} lat1 latitude 1
   * @param {type} lon1 longitude 1
   * @param {type} lat2 latitude 2
   * @param {type} lon2 longitude 2
   * @returns {Number} distance en kilomètres
   */
  _getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Rayon de la Terre en km
    var dLat = this._deg2rad(lat2 - lat1);
    var dLon = this._deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this._deg2rad(lat1)) *
        Math.cos(this._deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance en km
    return d;
  };

  _deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  render() {
    if (!this.props) {
      return null;
    }

    const controlSettings = {
      edit: {
        remove: this.state.drawing,
        edit: false,
      },
      draw: {
        polyline: false,
        marker: false,
        rectangle: false,
        circlemarker: false,
        polygon: this.state.drawing,
        circle: this.state.drawing,
        toolbar: false,
      },
    };

    const controlComponent = (
      <Control position='topleft'>
        <div
          className='leaftleft-drawing-toggle'
          onClick={this.toggleDrawing}
        ></div>
        <EditControlFeatureGroup
          controlProps={controlSettings}
          onCreated={this._handleCreated}
          onDeleted={this._handleDeleted}
        >
          {[...this.state.mapElements.keys()].map((id) => {
            const { type, ...props } = this.state.mapElements.get(id);
            const Element = {
              polygon: Polygon,
              circle: Circle,
            }[type];
            return <Element key={id} {...props} />;
          })}
        </EditControlFeatureGroup>
      </Control>
    );

    return (
      <LeafletMap
        key={this.state.mapKey}
        bounds={this.state.bounds}
        onzoomend={this.handleLocationChange}
        onmoveend={this.handleLocationChange}
        onresize={this.handleSizeChanges}
        animate={true}
        zoom={this.state.zoom}
        zoomControl={false}
      >
        <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
        {this.props.showEdit ? controlComponent : null}
        <FullscreenControl
          forceSeparateButton={true}
          content={"<span class='icon icon-full-2'></span>"}
          position='topright'
        />
        <ZoomControl
          position='bottomright'
          zoomInText={"<span class='icon icon-plus-sm'></span>"}
          zoomOutText={"<span class='icon icon-minus'></span>"}
        />

        {/* {Print selected companies info} */}
        {this.getCurrentCompany()}
        {/* {Print all markers} */}
        {this.state.companiesList.map((c, idx) => this.getMarker(c, idx))}
      </LeafletMap>
    );
  }
}
