import React, { useState, useEffect } from "react";

import logo from "../../../assets/images/logo-mlist.png";
import logoS from "../../../assets/images/salesintelligence.png";
import Select, { components } from "react-select";
import * as _ from "lodash";
import "./Header.scss";
import { Link } from "react-router-dom";
import mCampaign from "../../../assets/images/mCampaign.svg";
import mList from "../../../assets/images/mList.svg";

export default function Header({ onAccountIdChange, header, accountId }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    const orderedAccounts = _.sortBy(header.accounts, ({ name }) => name);
    setOptions(
      _.map(orderedAccounts, (value) => {
        if (value.id === accountId) {
          setSelectedOption({
            value: value.id,
            label: value.name,
          });
        }
        return {
          value: value.id,
          label: value.name,
        };
      }),
    );
  }, [header, accountId]);

  const handleClickClosePanelFromOutside = (e) => {
    if (e.target.className !== "icon icon-icon-menu") {
      setOpened(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleClickClosePanelFromOutside);
    return () => {
      document.body.removeEventListener(
        "click",
        handleClickClosePanelFromOutside,
      );
    };
  });

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onAccountIdChange(selectedOption.value);
  };

  const toggleDropdown = () => {
    setOpened(!opened);
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected && "#b6c4dd",
        color: isDisabled ? "#ccc" : data.color,
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    input: (styles) => ({
      ...styles,
      fontSize: "15px",
      fontWeight: "500",
    }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: "15px",
      fontWeight: "500",
    }),
    menu: (styles) => ({ ...styles, whiteSpace: "pre-wrap !important" }),
  };

  return (
    <div
      className={"header"}
      style={{ backgroundColor: header.backgroundColor }}
    >
      <div className={"row"}>
        <div className='begin'>
          <a href='/'>
            <img src={logo} className={"logo"} alt={header.primaryLogo} />
          </a>
          {options.length > 1 && (
            <Select
              className='select'
              classNamePrefix='react-select'
              value={selectedOption}
              onChange={handleChange}
              options={options.sort((a, b) =>
                a.label.localeCompare(b.label, _, { sensitivity: "base" }),
              )}
              styles={colourStyles}
              isSearchable={true}
              components={{
                NoOptionsMessage: ({ children, ...rest }) => {
                  return (
                    <components.NoOptionsMessage {...rest}>
                      Aucun résultat
                    </components.NoOptionsMessage>
                  );
                },
                SingleValue: ({ children, ...rest }) => {
                  return (
                    <components.SingleValue {...rest}>
                      {children}
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                        }}
                      >
                        {accountId && accountId.toString().substring(0, 8)}
                      </span>
                    </components.SingleValue>
                  );
                },
                Option: ({ children, ...rest }) => {
                  return (
                    <components.Option {...rest}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "15px",
                        }}
                      >
                        {children}
                        <span
                          style={{
                            fontSize: "11px",
                          }}
                        >
                          {rest.data.value.toString().substring(0, 8)}
                        </span>
                      </div>
                    </components.Option>
                  );
                },
              }}
            />
          )}
        </div>
        <div>
          <a href='/'>
            <img
              src={logoS}
              className={"logo logoManageo"}
              style={{ marginTop: 4 }}
              alt={header.Logo}
            />
          </a>
          <button className='toogle' onClick={toggleDropdown}>
            <span className='icon icon-icon-menu' />
          </button>

          <div className={"user-dropdown " + (opened ? "active" : "")}>
            <ul className='apps'>
              <li>
                <Link
                  className='link'
                  to={{
                    pathname: `/`,
                  }}
                >
                  <img src={mList} alt='mList' className='img mList' />
                </Link>
              </li>
              <li>
                <a
                  className='link'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://mcampaign.manageo.com/'
                >
                  <img
                    src={mCampaign}
                    alt='mCampaign'
                    className='img mCampaign'
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
