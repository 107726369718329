import React, { Component } from "react";

import "./CheckBoxListModalComponent.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import ModalSearch from "../../ModalSearch/ModalSearchFilterCpmponent";
import CheckBox from "../../../../../fields/Checkbox/Checkbox";
import ResetFilter from "../reset/ResetFilters";

class CheckBoxListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.props.content.concepts[0].values,
      optionsChecked: [],
      exclude: false,
      filterStr: "",
    };

    this.handler = this.handler.bind(this);
    this.handleCheckChildElement = this.handleCheckChildElement.bind(this);
    this.ifItChecked = this.ifItChecked.bind(this);
    this.handlerSearch = this.handlerSearch.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
    this.removeAllSelectedCheck = this.removeAllSelectedCheck.bind(this);
  }

  componentDidMount() {
    if (this.props.concept && this.props.concept.length > 0) {
      let newChecked = [];

      let filters = this.props.concept[0].filters;

      let newEx = filters[0].not;

      this.setState({
        exclude: newEx,
      });

      filters.forEach((f, i) => {
        f.expressions.forEach((expression) => {
          const val = {
            value: expression,
            label: this.getValueLabel(expression),
          };
          newChecked.push(val);
        });
      });

      this.setState({
        optionsChecked: newChecked,
      });
    }
  }

  updateQuery() {
    const checkedValues = this.state.optionsChecked.map(({ value }) => value);
    this.props.updateQuery(
      checkedValues.length ? [checkedValues] : [],
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal,
    );
  }

  getValueLabel(val) {
    let label = "";
    if (this.state.values) {
      this.state.values.forEach((v) => {
        if (v.value === val) {
          label = v.label;
        }
      });
    }
    return label;
  }

  handleCheckChildElement(event, elem) {
    let checkedArray = this.state.optionsChecked;
    let index = -1;

    checkedArray.forEach((e, i) => {
      if (e.value === elem.value) {
        index = i;
      }
    });

    if (index > -1) {
      checkedArray.splice(index, 1);
    } else {
      checkedArray.push(elem);
    }

    this.setState(
      {
        optionsChecked: checkedArray,
      },
      () => {
        this.updateQuery();
      },
    );
  }

  removeSelectedCheck(elem) {
    let checkedArray = this.state.optionsChecked;
    let values = this.state.values;

    let valueIndex = checkedArray.indexOf(elem);
    if (valueIndex > -1) {
      checkedArray.splice(valueIndex, 1);
    }

    values.forEach((el) => {
      if (el.value === elem.value) {
        el.checked = false;
      }
    });

    this.setState(
      {
        values: values,
        optionsChecked: checkedArray,
      },
      () => {
        this.updateQuery();
      },
    );
  }

  removeAllSelectedCheck() {
    let values = this.state.values;
    values.forEach((el) => {
      el.checked = false;
    });
    this.setState(
      {
        values: values,
        optionsChecked: [],
      },
      () => {
        this.updateQuery();
      },
    );
  }

  ifItChecked(i) {
    if (!this.state.optionsChecked) {
      return null;
    }
    const newArray = this.state.optionsChecked.filter((e) => {
      return e.value === i;
    });
    return newArray.length > 0;
  }

  getCheckboxes(checkbox_list) {
    if (!checkbox_list) {
      return null;
    }
    return checkbox_list
      .filter((el) => {
        return el.label.toLowerCase().indexOf(this.state.filterStr) > -1;
      })
      .map((el, i) => {
        return (
          <li key={i}>
            <CheckBox
              handleCheckChildElement={(e) => {
                this.handleCheckChildElement(e, el);
              }}
              checked={this.ifItChecked(el.value)}
              value={el.value}
            >
              {this.ifItChecked(el.value)}
              {el.label}
            </CheckBox>
          </li>
        );
      });
  }

  getCurrentList(el) {
    return el.map((e, i) => {
      return (
        <li key={i}>
          <span
            onClick={() => this.removeSelectedCheck(e)}
            className='icon-close'
          />
          {e.label}
        </li>
      );
    });
  }

  handlerSearch(s) {
    this.setState({
      filterStr: s.toLowerCase(),
    });
  }

  getSearch() {
    return <ModalSearch handleChange={this.handlerSearch} />;
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        if (this.state.optionsChecked.length) {
          this.updateQuery();
        }
      },
    );
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  render() {
    return (
      <div className='modal'>
        <ResetFilter handleDelete={this.removeAllSelectedCheck} />
        <div
          className={
            "modal-content" + (this.state.exclude ? " exclude" : " include")
          }
        >
          {this.getSearch()}
          <div className='checkboxes-wrapper'>
            <div className='col'>
              <div className='col-holder'>
                <>
                  <ul className='checkboxes-list'>
                    {this.getCheckboxes(this.state.values)}
                  </ul>
                </>
              </div>
            </div>
            <div className='col'>
              <div className='col-holder'>
                <>
                  <ul className='current-checkboxes'>
                    {this.getCurrentList(this.state.optionsChecked)}
                  </ul>
                </>
              </div>
            </div>
          </div>
        </div>
        {this.getFooter()}
      </div>
    );
  }
}
export default CheckBoxListView;
