import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./RangeDateModalComponent.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import RangeDateComponent from "./RangeDateComponent";
import SelectedValuesBox from "../selectedValuesBox/SelectedValuesBox";
import ResetFilter from "../reset/ResetFilters";

class RangeDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exclude: false,
      concepts: [],
      selectedValues: [],
    };

    this.handler = this.handler.bind(this);
    this.removeConcept = this.removeConcept.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setNewValues = this.setNewValues.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    // Set selected values
    this.setNewValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(np) {
    this.setNewValues(np);
  }

  setNewValues(np) {
    if (np) {
      if (np.concept[0]) {
        const filter = np
          ? np.concept[0].filters
          : this.props.concept[0].filters;

        if (np.concept[0].filters.length) {
          this.setState({
            exclude: np.concept[0].filters[0].not,
          });
        }

        this.setState({
          concepts: filter,
          selectedValues: this.makeSelectedValues(filter),
        });
      }
    }
  }

  updateQuery() {
    this.props.updateQuery(
      this.state.selectedValues,
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal,
    );
  }

  makeSelectedValues(filters) {
    const newSelectedValues = [];

    filters.forEach((f) => {
      newSelectedValues.push({
        operator: f.operator,
        values: [...f.expressions],
      });
    });
    return newSelectedValues;
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        if (this.state.selectedValues.length) {
          this.updateQuery();
        }
      },
    );
  }

  handleClick(value) {
    const selectedValues = this.state.selectedValues;

    selectedValues.push(value);

    this.setState(
      {
        selectedValues,
      },
      () => {
        this.updateQuery();
      },
    );
  }

  removeConcept(index) {
    let conceptsList = this.state.concepts;
    let selectedValues = this.state.selectedValues;
    conceptsList.splice(index, 1);
    selectedValues.splice(index, 1);

    this.setState(
      {
        concepts: conceptsList,
        selectedValues,
      },
      () => {
        this.updateQuery();
      },
    );
  }

  handleReset(index) {
    let conceptsList = this.state.concepts;
    let selectedValues = this.state.selectedValues;
    conceptsList.splice(index, conceptsList.length);
    selectedValues.splice(index, selectedValues.length);

    this.setState(
      {
        concepts: [],
        selectedValues: [],
      },
      () => {
        this.updateQuery();
      },
    );
  }

  getSelectedItems() {
    if (!this.state.concepts.length > 0) {
      return null;
    }
    return (
      <SelectedValuesBox
        concepts={this.state.concepts}
        removeConcept={this.removeConcept}
      />
    );
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  render() {
    return (
      <div className='modal'>
        <ResetFilter handleDelete={this.handleReset} />
        <div
          className={
            "modal-content" + (this.state.exclude ? " exclude" : " include")
          }
        >
          <RangeDateComponent handleClick={this.handleClick} />
          {this.getSelectedItems()}
        </div>
        {this.getFooter()}
      </div>
    );
  }
}
export default RangeDate;
