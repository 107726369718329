import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Col } from "react-flexbox-grid";
import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");
export default function AdminStatTab({ table, downloadStat }) {
  const {
    dataviz,
    headers,
    rowData,
    defaultSortColumnId,
    defaultSortDescending,
    options,
  } = table;
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(
    defaultSortDescending ? "desc" : "asc",
  );

  useEffect(() => {
    setSortField(defaultSortColumnId);
  }, [defaultSortColumnId]);

  const handleSort = (id) => {
    if (id === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(id);
      setSortOrder("asc");
    }
  };

  const addHeader = (header) => {
    return (
      <div className='stat-header' onClick={() => handleSort(header.id)}>
        <div className='label'>{header.label}</div>
        {header.sortable && (
          <div className='sort'>
            {(header.id !== sortField || sortOrder === "asc") && (
              <span className='icon icon-chevron-down asc' />
            )}
            {(header.id !== sortField || sortOrder === "desc") && (
              <span className='icon icon-chevron-down desc' />
            )}
          </div>
        )}
      </div>
    );
  };

  const getData = (key, header, row) => {
    if (row.data.total && key === 0) {
      return "TOTAL";
    }
    switch (header.type) {
      case "LIST":
        return (
          <>
            {row.data[header.id].map((e, key) => (
              <p key={key}>{e}</p>
            ))}
          </>
        );
      default:
        return <>{row.data[header.id]}</>;
    }
  };

  const barOptions = {
    legend: {
      display: false,
    },
    title: {
      display: true,
      fontColor: "#4a63a1",
      fontSize: "14",
      fontStyle: "bold",
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    tooltips: {
      labels: false,
      displayColors: false,
    },
  };
  const data = headers.find((h) => h.type === "DATAVIZ") && {
    labels: rowData
      .filter((row) => row.data["datavizValue"] > 0)
      .sort(
        (row1, row2) => row2.data["datavizValue"] - row1.data["datavizValue"],
      )
      .map((row) => row.data["datavizLabel"]),
    datasets: [
      {
        backgroundColor: [
          "#8dd674",
          "#f7776b",
          "#a9524e",
          "#c8d4e8",
          "#91a8d0",
          "#4e5c7b",
          "#fbe337",
          "#ffb400",
          "#AF33FF",
          "#FF33A5",
        ],
        data: rowData
          .filter((row) => row.data["datavizValue"] > 0)
          .sort(
            (row1, row2) =>
              row2.data["datavizValue"] - row1.data["datavizValue"],
          )
          .map((row) => row.data["datavizValue"]),
      },
    ],
  };

  const tableClassName = headers.find((h) => h.type === "DATAVIZ")
    ? "table-targeting table-section export-pack"
    : "table-targeting table-section";

  const total = table !== null &&
    table.id === "global" && {
      label: "Toutes les actions ",
      data: dataviz.map((el) => el["total"]),
      fill: false,
      backgroundColor: "#8dd674",
      borderColor: "#8dd674",
      tension: 0,
    };

  const exportlist = table !== null &&
    table.id === "global" && {
      label: "Listes d'export ",
      data: dataviz.map((el) => el["export"]),
      fill: false,
      hidden: true,
      backgroundColor: "#FFB400",
      borderColor: "#FFB400",
      tension: 0,
    };

  const send = table !== null &&
    table.id === "global" && {
      label: "Listes d'envoi ",
      data: dataviz.map((el) => el["send"]),
      fill: false,
      hidden: true,
      backgroundColor: "#F7776B",
      borderColor: "#F7776B",
      tension: 0,
    };

  const targeting = table !== null &&
    table.id === "global" && {
      label: "Ciblages ",
      data: dataviz.map((el) => el["targeting"]),
      fill: false,
      hidden: true,
      backgroundColor: "#A9524E",
      borderColor: "#A9524E",
      tension: 0,
    };

  const prefilter = table !== null &&
    table.id === "global" && {
      label: "Préfiltres ",
      data: dataviz.map((el) => el["preFilter"]),
      fill: false,
      hidden: true,
      backgroundColor: "#4E5C7B",
      borderColor: "#4E5C7B",
      tension: 0,
    };

  const empty = {
    label: "",
    data: "",
    hidden: true,
    backgroundColor: "#FFFFFF",
    borderColor: "#FFFFFF",
  };

  const datavizData = table !== null &&
    table.id === "global" && {
      labels: dataviz.map((el) => el["createAt"]),
      datasets: [
        headers !== null && headers.find((h) => h.id === "sum") ? total : empty,
        headers !== null && headers.find((h) => h.id === "export")
          ? exportlist
          : empty,
        headers !== null && headers.find((h) => h.id === "send") ? send : empty,
        headers !== null && headers.find((h) => h.id === "targeting")
          ? targeting
          : empty,
        headers !== null && headers.find((h) => h.id === "preFilter")
          ? prefilter
          : empty,
      ],
    };

  const optionDataviz = {
    legend: {
      onHover: function (e) {
        e.target.style.cursor = "pointer";
      },
    },
    hover: {
      onHover: function (e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = "pointer";
        else e.target.style.cursor = "default";
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
          },
          type: "time",
          time: {
            parser: "YYYY-MM-DD",
            tooltipFormat: "DD/MM/YYYY",
            unit: "day",
            unitStepSize: 1,
            displayFormats: {
              month: "DD/MM/YYYY",
            },
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      {options && options.block_info_label && (
        <div
          className='sub-title'
          style={{
            marginTop: "20px",
          }}
        >
          <div className='info'>
            {options.block_info_icon && <i className='icon icon-propos' />}
            {options.block_info_label}
          </div>

          <Col md={3} className='text-right'>
            <button
              className='btn-download'
              onClick={downloadStat}
              type='button'
              title='Télécharger'
            >
              <span className='icon icon-on-load' />
            </button>
          </Col>
        </div>
      )}
      {table && table.id === "global" && (
        <div style={{ padding: "0px 10px" }}>
          <div style={{ backgroundColor: "#FFFFFF" }}>
            <Line
              data={datavizData}
              options={optionDataviz}
              width={4}
              height={1}
            >
              >
            </Line>
          </div>
        </div>
      )}
      <div className={tableClassName}>
        <div className='table-holder'>
          <div className='table-wrapper'>
            <div className='colored-heading'>
              <div className='white-borders'>
                <table>
                  <thead>
                    <tr>
                      {headers.map((header, key) => {
                        let className = "";
                        if (header.type === "NUMBER") {
                          className = "credit";
                        } else if (header.type === "STRING") {
                          className = "name";
                        } else if (header.type === "LIST") {
                          className = "name";
                        }
                        return (
                          <th key={key} className={className}>
                            {addHeader(header)}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {rowData
                      .sort((row1, row2) => {
                        if (!sortField) {
                          return 0;
                        }
                        if (!headers.find((h) => h.id === sortField)) {
                          return 0;
                        }
                        if (row1.data.total) {
                          return -1;
                        } else if (row2.data.total) {
                          return 1;
                        }
                        const value1 = row1.sortKey.find(
                          (s) => s.fieldName === sortField,
                        ).value;
                        const value2 = row2.sortKey.find(
                          (s) => s.fieldName === sortField,
                        ).value;
                        const type = headers.find(
                          (h) => h.id === sortField,
                        ).type;
                        if (type === "NUMBER") {
                          return sortOrder === "asc"
                            ? value1 - value2
                            : value2 - value1;
                        } else {
                          return sortOrder === "asc"
                            ? value1.localeCompare(value2)
                            : value2.localeCompare(value1);
                        }
                      })
                      .map((row, index) => {
                        return (
                          <tr
                            key={index}
                            className={row.data.total ? "total" : ""}
                          >
                            {headers.map((header, key) => {
                              if (header.type === "DATAVIZ") {
                                return (
                                  index === 0 && (
                                    <td
                                      key={key}
                                      className='dataviz'
                                      rowSpan={rowData.length}
                                    >
                                      <Bar options={barOptions} data={data} />
                                    </td>
                                  )
                                );
                              }
                              return (
                                <td key={key}>{getData(key, header, row)}</td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
