import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";

import "./App.scss";

import { ErrorContext } from "./shared/context/ErrorContext";
import Header from "./features/Header/Header";
import ErrorBar from "./shared/components/ErrorBar/ErrorBar";
import ErrorBoundary from "./shared/error/ErrorBoundary";
import SideBar from "./features/SideBar/Sidebar";
import RoutMap from "./router";
import { ParamsProvider } from "./hooks/ParamsContext";

const axiosErrorContext = {};

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = process.env.SERVER_API_URL;

const setupAxiosInterceptors = (router) => {
  const onRequestSuccess = (config) => {
    return config;
  };
  const onResponseSuccess = (response) => {
    if (axiosErrorContext.error) {
      axiosErrorContext.setError(false);
    }
    return response;
  };
  const onResponseError = (err) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status && status === 500) {
      //Showing error bar
      axiosErrorContext.setError(true);
    } else if (status && status === 401) {
      router.push("/");
    } else if (!axios.isCancel(err)) {
      window.location = "/oauth2/authorization/oidc";
    }

    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default function App() {
  const [cookies, setCookie] = useCookies(["accountId"]);
  const [accounts, setAccounts] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [sideBar, setSideBar] = useState(null);
  const [error, setError] = useContext(ErrorContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [shownMenu, setShownMenu] = useState(false);
  const [active, setActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const router = useHistory();
  const location = useLocation();

  axiosErrorContext.error = error;
  axiosErrorContext.setError = setError;

  useEffect(() => {
    setupAxiosInterceptors(router);
  }, [router]);

  useEffect(() => {
    const cookieAccountId = cookies["accountId"];
    if (cookieAccountId && cookieAccountId !== accountId) {
      setAccountId(cookieAccountId);
      router.push("/dashboard/" + cookieAccountId + "/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, accountId]);

  useEffect(() => {
    var config = {
      headers: { "Access-Control-Allow-Origin": "*" },
      maxRedirects: 0,
    };

    let urlId = location.pathname.split("/").filter((url) => url !== "");
    axios
      .get(process.env.REACT_APP_API_URL + "getHeader", config)
      .then((r) => {
        if (r.data.accountId && r.data.accountId !== null) {
          const cookieAccoundId = cookies["accountId"];
          setAccounts(r.data);
          let accountId;
          if (!cookieAccoundId || !(cookieAccoundId in r.data.accountIds)) {
            accountId = r.data.accountId;
          } else {
            accountId = cookieAccoundId;
          }
          r.data.accounts &&
            r.data.accounts.map((account) =>
              account.id.includes(urlId[urlId.length - 1]) &&
              location.pathname !== "/"
                ? (accountId = account.id)
                : "",
            );

          if (
            accountId !== urlId[urlId.length - 1] &&
            location.pathname !== "/" &&
            location.pathname !== "/logout" &&
            location.pathname !== "/logout-mApp"
          ) {
            router.push("/dashboard/" + accountId + "/");
          }
          setCookie("accountId", accountId, {
            path: "/",
            domain: ".manageo.com",
            secure: true,
            sameSite: "Lax",
          });
          setAccountId(accountId);
          setActive(r.data.accounts.find((acc) => acc.id === accountId).active);
          if (r.data.accounts.find((acc) => acc.id === accountId)) {
            axios
              .get(process.env.REACT_APP_API_URL + "getMenu?ds=" + accountId)
              .then((response) => {
                setSideBar(response.data);
              })
              .then(() => {
                setIsLoading(false);
              });
          }
        } else {
          axios.post("/api/logout").then(function (response) {
            window.location.href =
              response.data.logoutUrl +
              "?returnTo=" +
              encodeURIComponent(window.location.origin);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [accountId, cookies, active, setCookie, location.pathname, router]);

  const closesMobileMenu = () => setMobileOpen(false);

  const toggleMobileMenu = () => setMobileOpen(!mobileOpen);

  const onAccountIdChange = (accountId) => {
    setCookie("accountId", accountId, {
      path: "/",
      domain: ".manageo.com",
      secure: true,
      sameSite: "Lax",
    });
    setAccountId(accountId);
    setIsLoading(true);
    router.push("/" + location.pathname.split("/")[1] + "/" + accountId + "/");
  };

  const sibeBar = active ? (
    <div className={"menu" + (mobileOpen === true ? " show" : "")}>
      <SideBar
        sideBar={sideBar}
        accountId={accountId}
        closesMobileMenu={closesMobileMenu}
        mobileOpen={mobileOpen}
        shownMenu={shownMenu}
        setShownMenu={setShownMenu}
        setIsLoading={setIsLoading}
      />
    </div>
  ) : null;

  return (
    <div className='App h-100'>
      <ParamsProvider>
        <ErrorBar />
        <Header
          header={accounts}
          accountId={accountId}
          onAccountIdChange={onAccountIdChange}
        />
        <div className='container-fluid d-flex h-100'>
          <button
            onClick={toggleMobileMenu}
            className={
              "mobile-opener" + (mobileOpen === true ? " nav-active" : "")
            }>
            <span></span>
          </button>
          {sibeBar}
          <div className='main-content h-100'>
            <ErrorBoundary>
              <RoutMap
                defaultId={sideBar ? sideBar.sideBarMenu[0].id : null}
                accountId={accountId}
                active={active}
                isLoading={isLoading}
              />
            </ErrorBoundary>
          </div>
        </div>
      </ParamsProvider>
    </div>
  );
}
