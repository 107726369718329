import React, { Component } from "react";

import "./RangeNumberModalComponent.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import SelectedValuesBox from "../selectedValuesBox/SelectedValuesBox";
import RangeNumberComponent from "./RangeNumberComponent";
import ResetFilter from "../reset/ResetFilters";
class RangeNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exclude: false,
      selectedValues: [],
      concepts: [],
      resetField: false,
    };
    this.handler = this.handler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setNewValues = this.setNewValues.bind(this);
    this.removeConcept = this.removeConcept.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    this.setNewValues(this.props);
  }

  componentDidUpdate() {
    if (this.state.resetField) {
      this.setState({ resetField: false });
    }
  }

  UNSAFE_componentWillReceiveProps(np) {
    this.setNewValues(np);
  }

  setNewValues(np) {
    if (np) {
      if (np.concept[0]) {
        const filter = np
          ? np.concept[0].filters
          : this.props.concept[0].filters;

        if (np.concept[0].filters.length) {
          this.setState({
            exclude: np.concept[0].filters[0].not,
          });
        }

        this.setState({
          concepts: filter,
          selectedValues: this.makeSelectedValues(filter),
        });
      }
    }
  }

  makeSelectedValues(filters) {
    const newSelectedValues = [];

    filters.forEach((f) => {
      newSelectedValues.push({
        operator: f.operator,
        values: [...f.expressions],
      });
    });
    return newSelectedValues;
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        if (this.state.selectedValues.length) {
          this.updateQuery();
        }
      },
    );
  }

  getUnit() {
    if (!this.props) {
      return null;
    }
    switch (this.props.content.concepts[0].unit) {
      case "EURO":
        return "euro";
      case "POURCENT":
        return "percent";
      default:
        return null;
    }
  }

  updateQuery() {
    this.props.updateQuery(
      this.state.selectedValues,
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal,
    );
  }

  handleClick(value) {
    let selectedValues = this.state.selectedValues;

    selectedValues.push(value);

    this.setState(
      {
        selectedValues,
      },
      () => {
        this.updateQuery();
      },
    );
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  removeConcept(index) {
    let concepts = this.state.concepts;
    let selectedValues = this.state.selectedValues;
    concepts.splice(index, 1);
    selectedValues.splice(index, 1);

    this.setState(
      {
        concepts,
        selectedValues,
      },
      () => {
        this.updateQuery();
      },
    );
  }

  getSelectedItems() {
    if (!this.state.concepts.length > 0) {
      return null;
    }
    return (
      <SelectedValuesBox
        concepts={this.state.concepts}
        removeConcept={this.removeConcept}
      />
    );
  }

  handleReset() {
    let concepts = this.state.concepts;
    let selectedValues = this.state.selectedValues;
    concepts.splice(0, concepts.length);
    selectedValues.splice(0, selectedValues.length);

    this.setState(
      {
        concepts,
        selectedValues,
        resetField: true,
      },
      () => {
        this.updateQuery();
      },
    );
  }

  render() {
    return (
      <div className='modal number-modal'>
        <ResetFilter handleDelete={this.handleReset} />
        <div
          className={
            "modal-content" +
            (this.state.exclude ? " exclude" : " include") +
            " unit-" +
            this.getUnit()
          }
        >
          {
            <RangeNumberComponent
              resetField={this.state.resetField}
              handleClick={this.handleClick}
            />
          }
          {this.getSelectedItems()}
        </div>
        {this.getFooter()}
      </div>
    );
  }
}

export default RangeNumber;
