import React, { useState, useEffect } from "react";
import { createListConsts } from "../../utils/constants";
import {
  addConceptToQuery,
  removeConceptToQueryAllValues,
} from "./../CreateList/QueryTool";
import "./SearchBar.scss";

export default function SearchBarComponent({
  searchBarComponent,
  sendQuery,
  currentQuery,
}) {
  const [inputValue, setInputValue] = useState(
    searchBarComponent.value ? searchBarComponent.value : "",
  );

  useEffect(() => {
    setInputValue(searchBarComponent.value ? searchBarComponent.value : "");
  }, [searchBarComponent]);

  const setHandleSearch = () => {
    const newQuery = inputValue
      ? addConceptToQuery(
          {
            query: currentQuery.query ? JSON.parse(currentQuery.query) : [],
            queryCondition: currentQuery.queryCondition
              ? JSON.parse(currentQuery.queryCondition)
              : [],
          },
          searchBarComponent.concept,
          [inputValue],
          null,
          searchBarComponent.operator,
          false,
          true,
        )
      : removeConceptToQueryAllValues(
          {
            query: currentQuery.query ? JSON.parse(currentQuery.query) : [],
            queryCondition: currentQuery.queryCondition
              ? JSON.parse(currentQuery.queryCondition)
              : [],
          },
          searchBarComponent.concept,
          [inputValue],
          null,
          searchBarComponent.operator,
          false,
          true,
        );

    sendQuery(
      createListConsts.firstStep,
      inputValue
        ? {
            query: JSON.stringify(newQuery.query),
            queryCondition: JSON.stringify(newQuery.queryCondition),
            etablissement_search: true,
          }
        : {
            query: JSON.stringify(newQuery.query),
            queryCondition: JSON.stringify(newQuery.queryCondition),
          },
      true,
    );
  };

  return (
    <div className='search-holder'>
      <div className='search-heading'>
        <label className={"search-table active"}>
          <span
            className='icon-search'
            onClick={() => setHandleSearch(inputValue)}
          />
          <input
            value={inputValue}
            onChange={({ target }) => setInputValue(target.value)}
            placeholder={searchBarComponent.title}
            name='rechercher'
            className='input'
            type='text'
            autoFocus={false}
            onKeyPress={(event) =>
              event.key === "Enter" && setHandleSearch(inputValue)
            }
          />
        </label>
      </div>
    </div>
  );
}
