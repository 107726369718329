import React, { useState } from "react";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import Pagination from "react-js-pagination";

import { Row, Col } from "react-flexbox-grid";
import MapComponent from "../Map/MapComponent";
import PotentialSectionComponent from "../PotentialSection/PotentialSectionComponent";
import ColumnChartComponent from "../ColumnChart/ColumnChartComponent";
import IconChartComponent from "../IconChart/IconChartComponent";
import DoughnutGraphicComponent from "../DoughnutGraphic/DoughnutGraphicComponent";
import CompagniesListsTableComponent from "../../../CompagniesListsTable/CompagniesListsTableComponent";
import SearchBarComponent from "../../../SearchBar/SearchBarComponent";
import Ventilation from "./Ventilation";

import "./EstablishedSectionComponent.scss";

export default function EstablishedSectionComponent({
  components,
  sendQuery,
  currentQuery,
  ds,
  isMobile,
  mapContent,
}) {
  const [state, setState] = useState({
    activePage: 1,
    colsPerPage: 20,
    tabActive: components.wizardContent.secondaryPanel.tabControl.tabActive,
  });

  const cards = components.establishmentCard;
  const content = components.wizardContent.secondaryPanel;
  const charts = components.wizardStep1MapContent;
  const establishmentSearchComponent = components.EstablishmentSearchCard;

  const addTabsHeading = (tabs) =>
    tabs.map(
      ({ id, componentRef, selected, icon, label }, i) =>
        componentRef && (
          <TabLink
            key={i}
            to={componentRef}
            default={selected}
            onClick={() => setState({ ...state, tabActive: id })}
          >
            <span className={`icon icon-${icon}`}></span>
            {label}
          </TabLink>
        ),
    );

  const getMap = () => {
    return (
      mapContent.showGeoMap && (
        <MapComponent
          currentQuery={currentQuery}
          ds={ds}
          sendQuery={sendQuery}
          showEdit={mapContent.showEditGeoMap}
        />
      )
    );
  };

  const getCharts = (charts) =>
    !!charts.length &&
    charts.map((chart, i) => {
      switch (chart.chartType) {
        case "doughnut":
          return (
            <Col key={i} className='card-item chart-item' sm={12} md={6}>
              <DoughnutGraphicComponent item={chart} />
            </Col>
          );
        case "bar":
          return (
            <Col key={i} className='card-item chart-item' sm={12} md={6}>
              <ColumnChartComponent item={chart} />
            </Col>
          );
        case "icon":
          return (
            <Col key={i} className='card-item chart-item' sm={12} md={6}>
              <IconChartComponent item={chart} />
            </Col>
          );
        default:
          return null;
      }
    });

  const addTabsContent = () => {
    let tabContent;
    switch (state.tabActive) {
      case "wizard-step1-map":
        tabContent = (
          <TabContent for='wizardStep1MapContent' className='map-tab'>
            <Row className='straight'>
              <Col className='card-item card-map' sm={12} md={7}>
                {getMap()}
              </Col>
              <Col className='card-item ' sm={12} md={5}>
                {charts && (
                  <PotentialSectionComponent
                    content={charts.potentialPerPack}
                  />
                )}
              </Col>
            </Row>
            <Row className='xs no-b-padding'>
              {charts && getCharts(charts.charts)}
            </Row>
          </TabContent>
        );
        break;
      case "wizard-step1-details":
        tabContent = (
          <TabContent for={cards.id}>
            {getCards(cards)}
            {getPagination()}
          </TabContent>
        );
        break;
      case "wizard-step1-establishment-search":
        tabContent = (
          <TabContent for='establishmentSearch'>
            <SearchBarComponent
              searchBarComponent={establishmentSearchComponent.searchBar}
              sendQuery={sendQuery}
              currentQuery={currentQuery}
            />
            <CompagniesListsTableComponent
              table={establishmentSearchComponent.establishments}
              sendQuery={sendQuery}
              currentQuery={currentQuery}
            />
          </TabContent>
        );
        break;
      case "wizard-step1-ventilation":
        tabContent = (
          <TabContent for='ventilation'>
            <Ventilation
              ventilation={components.Ventilation}
              sendQuery={sendQuery}
            />
          </TabContent>
        );
        break;
      default:
        tabContent = null;
    }
    return <div>{tabContent}</div>;
  };

  const getContent = (tabs) => {
    const tab = tabs.find((tab) => tab.id === state.tabActive);
    return (
      <div className='established-section'>
        <header className='text-holder'>
          <h1>{tab.title}</h1>
          <h3>{tab.subTitle}</h3>
        </header>
      </div>
    );
  };

  const getCards = (cards) =>
    cards && (
      <Row start='xs' className='cards-holder'>
        {getCardsItems(cards.cards)}
      </Row>
    );

  const openTabmCompagny = (url) => {
    var otherWindow = window.open();
    otherWindow.opener = null;
    otherWindow.location = url;
  };

  const getCardsItems = (cards) => {
    const maxIndex = state.colsPerPage * state.activePage - 1;
    const minIndex = maxIndex - (state.colsPerPage - 1);
    return cards
      .filter((_, index) => index >= minIndex && index <= maxIndex)
      .map((card, i) => (
        <Col className='card-item ' xs={3} key={i}>
          <div
            className='card-content with-shadow'
            onClick={() => openTabmCompagny(card.url)}
          >
            <h3>{card.title}</h3>
            <h4>
              <span className='icon-map-pin'></span> {card.subtitle}
            </h4>
            <h5>{card.content}</h5>
          </div>
        </Col>
      ));
  };

  const handlePageChange = (pageNumber) =>
    setState({ ...state, activePage: pageNumber });

  const getPagination = () => (
    <div className='pagination-holder'>
      <Pagination
        activePage={state.activePage}
        itemsCountPerPage={20}
        totalItemsCount={cards.cards.length}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
        firstPageText={<span className='icon icon-angle-double-left'></span>}
        prevPageText={<span className='icon icon-angle-left'></span>}
        nextPageText={<span className='icon icon-angle-right'></span>}
        lastPageText={<span className='icon icon-angle-double-right'></span>}
      />
    </div>
  );

  return (
    content && (
      <>
        {getContent(content.tabControl.tabs)}
        <div className='established-tabs'>
          <Tabs>
            <div className='tab-heading end capitalize-text'>
              {addTabsHeading(content.tabControl.tabs)}
            </div>
            {addTabsContent()}
          </Tabs>
        </div>
      </>
    )
  );
}
