/* eslint-disable array-callback-return */
import * as _ from "lodash";

export function extractStructure(filterTabs) {
  return _.map(filterTabs, (filterTab) => {
    const concepts = [];
    filterTab.criterias.map((criteria) => {
      criteria.concepts.map((concept) => {
        concepts.push(concept.conceptName);
      });
    });
    return { concepts };
  });
}

export function removeConceptToQuery(query, concept, values, operator) {
  const newQuery = [];
  const newQueryCondition = [];

  const currentQuery = query.query ? query.query : [];
  const currentQueryCondition = query.queryCondition
    ? query.queryCondition
    : [];

  currentQuery.map((queryGroup, index) => {
    var newQueryGroup = queryGroup;
    if (_queryGroupContainConcept(queryGroup, concept)) {
      newQueryGroup = _removeConceptValues(
        queryGroup,
        concept,
        values,
        operator,
      );
    }
    if (!_.isEmpty(newQueryGroup)) {
      newQuery.push(newQueryGroup);
      newQueryCondition.push(currentQueryCondition[index]);
    }
  });

  query.query = newQuery;
  query.queryCondition = newQueryCondition;
  return query;
}

export function removeConceptToQueryAllValues(query, concept) {
  const newQuery = [];
  const newQueryCondition = [];

  const currentQuery = query.query ? query.query : [];
  const currentQueryCondition = query.queryCondition
    ? query.queryCondition
    : [];

  currentQuery.map((queryGroup, index) => {
    var newQueryGroup = queryGroup;
    if (_queryGroupContainConcept(queryGroup, concept)) {
      newQueryGroup = [];
    }
    if (!_.isEmpty(newQueryGroup)) {
      newQuery.push(newQueryGroup);
      newQueryCondition.push(currentQueryCondition[index]);
    }
  });

  query.query = newQuery;
  query.queryCondition = newQueryCondition;
  return query;
}

export function addConceptToQuery(
  query,
  concept,
  values,
  structure,
  operator,
  exclude,
  cleanValues,
) {
  const newQuery = [];
  const newQueryCondition = [];

  const currentQuery = query.query ? query.query : [];
  const currentQueryCondition = query.queryCondition
    ? query.queryCondition
    : [];

  const conceptStructure = _extractConceptStructure(structure, concept);

  var finded = false;
  currentQuery.map((queryGroup, index) => {
    var newQueryGroup = queryGroup;
    if (_queryGroupContainConcept(queryGroup, concept, conceptStructure)) {
      finded = true;
      newQueryGroup = _updateQueryGroup(
        queryGroup,
        concept,
        values,
        operator,
        exclude,
        cleanValues,
      );
    }
    newQuery.push(newQueryGroup);
    newQueryCondition.push(currentQueryCondition[index]);
  });

  if (!finded) {
    newQuery.push([_createFilterQuery(concept, operator, values, exclude)]);
    newQueryCondition.push("OR");
  }

  query.query = newQuery;
  query.queryCondition = newQueryCondition;
  return query;
}

export function transformExportPackJsonToQuery(values) {
  const array2send = [];
  Object.keys(values).forEach((k) => {
    const item = values[k];
    array2send.push(_transformToArray(item));
  });
  return JSON.stringify(array2send);
}

export function transformExportPackQueryToJson(query) {
  var queryJsons = JSON.parse(query ? query : "[]");
  var values = {};
  queryJsons.forEach((queryJson) => {
    const id = queryJson[0];
    values[id] =
      queryJson.length > 2
        ? _createValue(
            queryJson[0],
            null,
            queryJson[1],
            queryJson[2],
            queryJson[3],
          )
        : _createValue(queryJson[0], queryJson[1]);
  });
  return values;
}

export function addItemToExportPackQuery(
  query,
  id,
  options,
  liasseUnChecked,
  selectedGrpDonnees,
  yearChecked,
) {
  const values = transformExportPackQueryToJson(query);
  const value = _createValue(
    id,
    options,
    liasseUnChecked,
    selectedGrpDonnees,
    yearChecked,
  );
  values[id] = value;
  return transformExportPackJsonToQuery(values);
}

export function removeItemToExportPackQuery(query, id) {
  const values = transformExportPackQueryToJson(query);
  delete values[id];
  return transformExportPackJsonToQuery(values);
}

export function isExportPackInQuery(query, id) {
  return query ? query.includes(`"${id}"`) : false;
}

export function getItemFromQuery(query, id) {
  const values = transformExportPackQueryToJson(query);
  return values[id] ? values[id] : {};
}

export function getItemFromQueryOnQueryFormat(query, id) {
  const item = getItemFromQuery(query, id);
  if (item && item.id) {
    return JSON.stringify(_transformToArray(item));
  }
}

function _removeConceptValues(queryGroup, concept, values, operator) {
  var newQueryGroup = [];
  queryGroup.forEach((filterQuery) => {
    if (filterQuery[1] === concept) {
      if (operator === "IN") {
        filterQuery[3] = _.difference(filterQuery[3], values);
        if (!_.isEmpty(filterQuery[3])) {
          newQueryGroup.push(filterQuery);
        }
      } else {
        if (filterQuery[2] !== operator || !_.isEqual(filterQuery[3], values)) {
          newQueryGroup.push(filterQuery);
        }
      }
    } else {
      newQueryGroup.push(filterQuery);
    }
  });

  return newQueryGroup;
}

function _extractConceptStructure(structure, concept) {
  const filtered = _.filter(structure, (group) =>
    group.concepts.includes(concept),
  );
  return _.isEmpty(filtered) ? { concepts: [] } : filtered[0];
}

function _updateQueryGroup(
  queryGroup,
  concept,
  values,
  operator,
  exclude,
  cleanValues,
) {
  var newQueryGroup = [];
  if (operator === "IN") {
    var finded = false;
    queryGroup.forEach((filterQuery) => {
      if (filterQuery[1] === concept) {
        finded = true;
        filterQuery[3] = cleanValues
          ? values
          : _.uniq([...filterQuery[3], ...values]);
      }
      newQueryGroup.push(filterQuery);
    });
    if (!finded) {
      newQueryGroup.push(
        _createFilterQuery(concept, operator, values, exclude),
      );
    }
  } else {
    newQueryGroup = queryGroup;
    newQueryGroup.push(_createFilterQuery(concept, operator, values, exclude));
  }

  return newQueryGroup;
}

function _createFilterQuery(concept, operator, values, exclude) {
  return [0, concept, operator, values, exclude ? 1 : 0];
}

function _queryGroupContainConcept(queryGroup, concept, conceptStructure) {
  var finded = false;
  queryGroup.map((filterQuery) => {
    const filterQueryConcept = filterQuery[1];
    if (
      filterQueryConcept === concept ||
      (conceptStructure &&
        conceptStructure.concepts.includes(filterQueryConcept))
    ) {
      finded = true;
    }
  });
  return finded;
}

function _createValue(
  id,
  options,
  liasseUnChecked,
  selectedGrpDonnees,
  yearChecked,
) {
  const value = {
    id: id,
  };
  if (id === "export.pack.donneesFinancieres") {
    value["liasseUnChecked"] = liasseUnChecked ? liasseUnChecked : [];
    value["selectedGrpDonnees"] = selectedGrpDonnees
      ? selectedGrpDonnees
      : "NOT_SET";
    value["yearChecked"] = yearChecked ? yearChecked : [];
  } else {
    value["options"] = options ? options : [];
  }
  return value;
}

function _transformToArray(item) {
  if (item.id === "export.pack.donneesFinancieres") {
    return [
      item.id,
      item.liasseUnChecked ? item.liasseUnChecked : [],
      item.selectedGrpDonnees ? item.selectedGrpDonnees : "",
      item.yearChecked ? item.yearChecked : [],
    ];
  } else {
    return [item.id, item.options ? item.options : []];
  }
}
