import React from "react";

import "./Establissement.scss";

import EstablissementHeader from "./EstablissementHeader";
import EstablissementTitle from "./EstablissementTitle";
import GeneralInformation from "./GeneralInformation";
import ContactDetail from "./ContactDetail";
import KeyValue from "./KeyValue";
import Leader from "./Leader";
import ActionnarySubsidiary from "./ActionnarySubsidiary";
import Score from "./Score";
import BilanConsolide from "./BilanConsolide";
import FinancialData from "./FinancialData";
import Brand from "./Brand";
import { createListConsts } from "../../utils/constants";

export default function Establissement({
  accountId,
  establishment,
  sendQuery,
  goNext,
}) {
  return establishment ? (
    <>
      <EstablissementHeader
        accountId={accountId}
        closePage={() => {
          sendQuery(
            createListConsts.firstStep,
            {
              page_siret: "",
            },
            false,
          );
        }}
      />
      <>
        <div className='establishment scrollAuto'>
          <EstablissementTitle
            establishmentHeader={establishment.establishmentHeader}
            siret={establishment.siret}
            goNext={goNext}
          />
          <GeneralInformation
            generalInformation={establishment.generalInformation}
          />
          <ContactDetail contactDetail={establishment.contactDetail} />
          {establishment.keyValues && (
            <KeyValue keyValues={establishment.keyValues} />
          )}
          <Leader leaders={establishment.leaders} />
          {establishment.actionnary && (
            <ActionnarySubsidiary
              actionnarySubsidiary={establishment.actionnary}
            />
          )}
          <Score score={establishment.score} />
          {establishment.bilanConsolide && (
            <BilanConsolide bilanConsolide={establishment.bilanConsolide} />
          )}

          {establishment.donneeFinanciere && (
            <FinancialData
              datas={establishment.donneeFinanciere}
              goNext={goNext}
            />
          )}
          {establishment.brandBlock && (
            <Brand brand={establishment.brandBlock} goNext={goNext} />
          )}
        </div>
      </>
    </>
  ) : (
    <></>
  );
}
