import React from "react";
import ReactDOM from "react-dom";
import AppComponent from "./App";
import { Router } from "react-router-dom";
import { history } from "./history";
import { UserProvider } from "./shared/context/UserContext";
import { ErrorProvider } from "./shared/context/ErrorContext";
import * as Sentry from "@sentry/browser";
import TagManager from "react-gtm-module";
import * as serviceWorker from "./serviceWorker";
import { Helmet } from "react-helmet";

let tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

if (
  process.env.NODE_ENV === "production" &&
  !process.env.REACT_APP_GTM_PREVIEW_DISABLED
) {
  tagManagerArgs.auth = process.env.REACT_APP_GTM_AUTH;
  tagManagerArgs.preview = process.env.REACT_APP_GTM_PREVIEW;
}

if (
  process.env.NODE_ENV === "production" &&
  !process.env.REACT_APP_SENTRY_DISABLED
) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: "mlist-frontend@" + process.env.REACT_APP_CI_COMMIT_SHORT_SHA,
  });
}

TagManager.initialize(tagManagerArgs);

const rootEl = document.getElementById("root");

const render = (Component) =>
  ReactDOM.render(
    <ErrorProvider>
      <Helmet htmlAttributes={{ lang: "fr" }}></Helmet>
      <UserProvider>
        <Router history={history}>
          <Component />
        </Router>
      </UserProvider>
    </ErrorProvider>,
    rootEl,
  );

render(AppComponent);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
