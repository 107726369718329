import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import { NavLink } from "react-router-dom";

import NoData from "../NoData/NoData";
import PopUpModal from "../PopUpModal/PopUpModalComponent";
import downloadFile from "../../utils/downloadFile";
import CrmInsertReport from "./CrmInsertReport";

const STATUS_LABELS = {
  REQUESTED: "Démarrage de l'export",
  PENDING: "En cours d'envoi vers le CRM",
  SUCCEED: "Export terminé",
  REJECTED: "Export rejeté",
  FAILED: "Echec de génération de l'export",
};

export default function TableBody({
  headers,
  rowData,
  editResource,
  accountId,
  pageId,
  setUpdating,
  deleteModalMessage,
  deleteModalTitle,
  refreshTable,
  setOpen,
  setIsTestCrmSuccess,
  setCrmType,
}) {
  const [state, setState] = useState({
    editing: {
      index: null,
      value: "",
    },
    isReloadModalOpen: false,
    isDeleteModalOpen: false,
    isSendToMCampaignOpen: false,
    rowToDelete: null,
    rowToSend: null,
    rowToStop: null,
  });

  const firstHeaderId = headers[0].id;

  const renderListName = (listName, index) => {
    if (state.editing.index === index) {
      return (
        <React.Fragment>
          <input
            className={"update"}
            type='text'
            maxLength={255}
            autoFocus
            onChange={(e) => {
              setState({
                ...state,
                editing: {
                  ...state.editing,
                  value: e.target.value,
                },
              });
            }}
            value={state.editing.value}
          />
        </React.Fragment>
      );
    } else {
      return <React.Fragment>{listName}</React.Fragment>;
    }
  };

  const renderListNameConnector = (headerId, listName, index) => {
    if (state.editing.index === index) {
      return (
        <React.Fragment>
          <input
            className={"edit-input"}
            type='text'
            maxLength={255}
            autoFocus
            onChange={(e) => {
              let stateValue = state.editing.value;
              stateValue[headerId] = e.target.value;
              setState({
                ...state,
                editing: {
                  ...state.editing,
                  value: stateValue,
                },
              });
            }}
            value={state.editing.value[headerId]}
          />
        </React.Fragment>
      );
    } else {
      return <React.Fragment>{listName}</React.Fragment>;
    }
  };

  const renderAuthor = (data, name) => {
    return (
      <div>
        {data.givenName ?? <p>{data.givenName}</p>}
        <p>{name}</p>
        <i>{data.function ?? <p>{data.function}</p>}</i>
      </div>
    );
  };

  const renderTypeList = (data) => {
    return (
      data.userQuota && (
        <ul className='ul-element-list'>
          {data.userQuota.map((quota, index) => (
            <li key={index}>{quota}</li>
          ))}
        </ul>
      )
    );
  };

  const renderRoles = (data) => {
    const roles = data.roles.includes("role.admin")
      ? ["role.admin"]
      : data.roles;
    return (
      <div style={{ display: "flex" }}>
        {roles.map((role, key) => {
          let value = "";
          let backgroundColor = ";";
          switch (role) {
            case "role.read":
              value = "Lecture";
              backgroundColor = "#DCDCDC";
              break;
            case "role.write":
              value = "Ecriture";
              backgroundColor = "#5BC0C1";
              break;
            case "role.admin":
              value = "Gestion du compte";
              backgroundColor = "#FFB400";
              break;
            case "role.access":
              value = "Gestion des accès";
              backgroundColor = "#F7786B";
              break;
            default:
              value = "";
              backgroundColor = "#DCDCDC";
          }
          return (
            <div
              key={key}
              style={{
                marginRight: "5px",
                padding: "8px 12px",
                borderRadius: "25px",
                backgroundColor: backgroundColor,
              }}>
              {value}
            </div>
          );
        })}
      </div>
    );
  };

  const toggleReloadModal = (data) => {
    setState({
      ...state,
      rowToReload: data,
    });
  };
  const showReload = (data) => {
    return (
      data.showReload && (
        <React.Fragment>
          <button className='reload' onClick={() => toggleReloadModal(data)}>
            <span className='icon icon-refresh' />
          </button>
        </React.Fragment>
      )
    );
  };

  const toggleStopModal = (data) => {
    setState({
      ...state,
      rowToStop: data,
    });
  };

  const handleStopRow = async (fileId) => {
    const formData = new FormData();
    formData.set("exportId", fileId);
    formData.set("ds", accountId);
    formData.set("id", pageId);

    await axios.put(process.env.REACT_APP_API_URL + "stop", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    setUpdating(true);
    setState({
      ...state,
      rowToStop: null,
    });
    setTimeout(() => {
      refreshTable();
    }, 2000);
    setUpdating(false);
    // refreshTable();
  };

  const updateRow = async (data) => {
    let objectToFind = "fileId";

    if (!data[objectToFind]) {
      objectToFind = "id";
    }

    const docId = data[objectToFind];
    const FULL_URL = firstHeaderId.includes("connector")
      ? `${process.env.REACT_APP_API_URL}${"connector/update"}`
      : `${process.env.REACT_APP_API_URL}${"update"}`;
    const object = {
      ds: accountId,
      id: pageId,
      docId: docId,
      title: state.editing.value,
    };
    let objBody = null;
    if (firstHeaderId.includes("connector")) {
      objBody = {
        connectorUrl: state.editing.value["connectorUrl"],
        connectorClientId: state.editing.value["connectorClientId"],
        connectorSecretId: state.editing.value["connectorSecretId"],
      };
    }

    setUpdating(true);
    setState({
      ...state,
      editing: {
        index: null,
        value: firstHeaderId.includes("connector") ? {} : "",
      },
    });
    // in the connector table, when we edit the connection infos and refresh the table, the info received in the table are not the new infos
    // it's caused by the fact that when we launch the update command in the backend, apparently the actual update happens after the table refresh call
    // so when we the data we receive are the older data and not the new ones. That's why I added a 1s timeout before calling refreshTable, so I can give
    // time for the axon server to return the new data
    if (firstHeaderId.includes("connector")) {
      await axios.post(FULL_URL, objBody, { params: object }).then(() => {
        setTimeout(() => {
          refreshTable();
        }, 1000);
      });
    } else {
      await axios.post(FULL_URL, objBody, { params: object });
      refreshTable();
    }
  };

  const toggleEditing = (data, index, firstHeaderId) => {
    if (state.editing.index === index) {
      setState({
        ...state,
        editing: {
          index: null,
          value: firstHeaderId.includes("connector") ? {} : "",
        },
      });
    } else {
      if (firstHeaderId.includes("connector")) {
        let stateValue = {};
        headers.forEach((header) => {
          if (header.id !== "connectorType" && header.id !== "actions") {
            stateValue[header.id] = data[header.id];
          }
        });
        setState({
          ...state,
          editing: {
            index,
            value: stateValue,
          },
        });
      } else {
        setState({
          ...state,
          editing: {
            index,
            value: data[firstHeaderId],
          },
        });
      }
    }
  };

  const showEdit = (data, index, firstHeaderId) => {
    if (!data.showEdit) {
      return null;
    }
    if (editResource) {
      return (
        <>
          <button className='edit' onClick={() => editResource(data.id)}>
            <span data-tip={data.editLabel} className='icon icon-edit' />
          </button>
        </>
      );
    } else if (state.editing.index === index) {
      return (
        <>
          <button className='edit' onClick={() => updateRow(data)}>
            <span
              data-tip={data.editLabel}
              className='icon icon-step-confirmation'
            />
          </button>
          <button
            className='validate'
            onClick={() => toggleEditing(data, index, firstHeaderId)}>
            <span className='icon icon-close' />
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            className='edit'
            onClick={() => toggleEditing(data, index, firstHeaderId)}>
            <span data-tip={data.editLabel} className='icon icon-edit' />
          </button>
        </>
      );
    }
  };

  const toggleDeleteModal = (data) => {
    setState({
      ...state,
      rowToDelete: data,
    });
  };

  const showDeleteButton = (data) => {
    return (
      data.showDelete && (
        <button
          data-tip={data.deleteLabel}
          className='delete'
          onClick={() => toggleDeleteModal(data)}>
          <span className='icon icon-remove' />
        </button>
      )
    );
  };

  const showTestCrmButton = (data) => {
    return (
      data.showShowTestCrm && (
        <button
          data-tip={data.testCrmLabel}
          className='delete'
          onClick={() => testCrmConnection(data)}>
          <span className='icon icon-gap' />
        </button>
      )
    );
  };

  const testCrmConnection = async (data) => {
    const FULL_URL = `${process.env.REACT_APP_API_URL}${"connector/test"}`;
    const object = {
      ds: accountId,
      crm: data.connectorType,
    };

    await axios.post(FULL_URL, null, { params: object }).then((response) => {
      setIsTestCrmSuccess(response.data);
      setCrmType(data.connectorType);
      setOpen(true);
    });
  };

  const toggleSendToMCampaignModal = (data) => {
    setState({
      ...state,
      rowToSend: data,
    });
  };

  const handleExportFile = async (fileId) => {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + "exports/" + fileId + "/file",
      null,
      { params: { ds: accountId } }
    );
    downloadFile(data.url);
  };

  const handleSendToMCampaign = async (fileId) => {
    await axios.post(
      process.env.REACT_APP_API_URL + "exports/" + fileId + "/mcampaign/send",
      null,
      { params: { ds: accountId } }
    );
    setState({
      ...state,
      rowToSend: null,
    });
    refreshTable();
  };

  const accessToMCampaign = async () => {
    if (accountId) {
      await axios
        .get(
          process.env.REACT_APP_OIDC_URL +
            "mcampaign/access/spothit/" +
            accountId
        )
        .then((response) => {
          if (response.data !== null) {
            window.open(response.data, "_blank");
          }
        });
    }
  };

  const exportTypeButton = (el) => {
    if (el.showDownloadFile) {
      switch (el.exportType) {
        case "EXCEL":
          return (
            <div>
              <button onClick={() => handleExportFile(el.fileId)}>
                <span
                  data-tip={el.downloadFileLabel}
                  className='icon icon-xls'
                />
              </button>
            </div>
          );
        case "CSV":
          return (
            <div>
              <button onClick={() => handleExportFile(el.fileId)}>
                <span
                  data-tip={el.downloadFileLabel}
                  className='icon icon-csv'
                />
              </button>
            </div>
          );
        default:
          return null;
      }
    } else if (pageId !== "myListsExport" && el.stateCode !== "SUCCEED") {
      return <span className={"in-prog"}>{el.status}</span>;
    }
  };

  const showUrl = (data, icon) => {
    if (!data.url) {
      return null;
    }

    if (icon === "icon icon-letter") {
      return (
        data.showAccess && (
          <>
            <div
              className='mylist-url'
              onClick={() => {
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}myListsPush/${data.id}/url`,
                    null,
                    { params: { ds: accountId } }
                  )
                  .then((response) => {
                    var otherWindow = window.open();
                    otherWindow.opener = null;
                    otherWindow.location = response.data;
                  });
              }}>
              <span data-tip={data.EmailLabel} className={icon} />
            </div>
          </>
        )
      );
    } else {
      return (
        <>
          <a href={data.url} target='_blank' rel='noopener noreferrer'>
            <span data-tip={data.AccessLabel} className={icon} />
          </a>
        </>
      );
    }
  };

  const elementActions = (el, index, firstHeaderId) => {
    const reload = showReload(el);
    const reloadLi = reload ? <li>{reload}</li> : null;

    const edit = showEdit(el, index, firstHeaderId);
    const editLi = edit ? <li>{edit}</li> : null;

    const showDelete = showDeleteButton(el);
    const deleteLi = showDelete ? <li>{showDelete}</li> : null;

    const showTestCrm = showTestCrmButton(el);
    const testCrmLi = showTestCrm ? <li>{showTestCrm}</li> : null;

    const exportType =
      typeof el.showDownloadFile !== "undefined" ? exportTypeButton(el) : null;
    const exportTypeLi = exportType ? <li>{exportType}</li> : null;

    const showUrlDiv = showUrl(
      el,
      pageId === "myCampaign" ? "icon icon-graph" : "icon icon-letter"
    );
    const showUrlLi = showUrl ? <li>{showUrlDiv}</li> : null;

    const showCRMActions =
      pageId === "myListsExport"
        ? el.exportType === "EXPORT_CRM" &&
          (el.crmStatus === "PENDING" ? (
            <li>
              <div>
                <button onClick={() => toggleStopModal(el)}>
                  <span
                    data-tip="Arrêter l'import"
                    className='icon icon-close'></span>
                </button>
              </div>
            </li>
          ) : (
            el.stateCode === "SUCCEED" && (
              <li>
                <div>
                  <button
                    onClick={async () => {
                      handleExportFile(el.fileId);
                    }}>
                    <span
                      data-tip={el.downloadFileLabel}
                      className='icon icon-csv'
                    />
                  </button>
                </div>
              </li>
            )
          ))
        : false;

    const showSendToMCampaign = el.showExportToMCampaignFile && (
      <li>
        <div>
          <button
            disabled={el.hideButtonSendToMCampaign}
            onClick={() => toggleSendToMCampaignModal(el.fileId)}>
            <span
              data-tip={el.EmailLabel}
              className={
                el.hideButtonSendToMCampaign
                  ? "icon icon-mail-to-2 disabled-send-to-mcampaign"
                  : "icon icon-mail-to-2"
              }></span>
          </button>
        </div>
      </li>
    );

    const showAccessToMCampaign = el.showAccessToMCampaign && (
      <li>
        <div>
          <button
            disabled={el.hideButtonAccessToMCampaign}
            onClick={() => {
              accessToMCampaign(el.fileId);
            }}>
            <span
              data-tip={el.AccessLabel}
              className={
                el.hideButtonAccessToMCampaign
                  ? "icon icon-step-exportation disabled-send-to-mcampaign"
                  : "icon icon-step-exportation"
              }></span>
          </button>
        </div>
      </li>
    );

    const showDownloadPushFile = pageId === "myListsPush" &&
      el.csvPath &&
      (el.showDownloadPushFile || el.exportType === "EXPORT_CRM") && (
        <li>
          <div>
            <button
              onClick={async () => {
                const { data } = await axios.post(
                  `${process.env.REACT_APP_API_URL}myListsPush/${el.id}/download`,
                  null,
                  { params: { ds: accountId } }
                );
                downloadFile(data);
              }}>
              <span data-tip={el.downloadFileLabel} className='icon icon-csv' />
            </button>
          </div>
        </li>
      );

    const navLinkTargetingLi = (pageId === "myTargeting" ||
      pageId === "mesCiblagesDashboard") &&
      el.showUse && (
        <li>
          <NavLink
            to={`/createList/${accountId}/?myTargetingId=${el.id}`}
            className={"action-btn"}>
            <span data-tip={el.useLabel} className='text'>
              Utiliser
            </span>
          </NavLink>
        </li>
      );
    return (
      <ul className='btn-line'>
        {navLinkTargetingLi}
        {reloadLi}
        {editLi}
        {deleteLi}
        {testCrmLi}
        {showUrlLi}
        {exportTypeLi}
        {showDownloadPushFile}
        {showSendToMCampaign}
        {showAccessToMCampaign}
        {showCRMActions}
      </ul>
    );
  };

  const deleteRow = async (data) => {
    let objectToFind = "fileId";

    if (!data[objectToFind]) {
      objectToFind = "id";
    }

    const docId = data[objectToFind];

    const FULL_URL = `${process.env.REACT_APP_API_URL}${"delete"}`;
    const object = {
      ds: accountId,
      id: pageId,
      docId: docId,
      segmentation: data.docType !== "user" && data.segmentation,
    };
    setUpdating(true);
    setState({
      ...state,
      rowToDelete: null,
    });
    await axios.post(FULL_URL, null, { params: object });
    refreshTable();
  };

  const reloadRow = async (docId) => {
    const FULL_URL = `${process.env.REACT_APP_API_URL}${"reload"}`;
    const object = {
      ds: accountId,
      id: pageId,
      docId: docId,
    };

    setUpdating(true);
    setState({
      ...state,
      rowToReload: null,
    });
    await axios.put(FULL_URL, null, { params: object });
    setState({
      ...state,
      rowToReload: null,
    });
    setUpdating(false);
    refreshTable();
  };

  const displayCrmPopupResult = async (element) => {
    const row = element.closest("tr");
    const nextrow = row.nextElementSibling;
    element.classList.toggle("open");
    if (element.classList.contains("open")) {
      if (nextrow.classList.contains("crm-report")) {
        nextrow.classList.remove("hidden");
        if (row) {
          const cells = row.querySelectorAll("td"); // Get all <td> elements within the <tr>
          cells.forEach((td) => {
            td.style.borderBottom = "none";
          });
        }
      }
    } else {
      if (nextrow.classList.contains("crm-report")) {
        nextrow.classList.add("hidden");
        if (row) {
          const cells = row.querySelectorAll("td");
          cells.forEach((td) => {
            td.style.borderBottom = "1px solid #b6c4dd"; // this is the same $light-blue color used for the table in the SCSS files
          });
        }
      }
    }
  };
  return (
    <>
      <ReactTooltip
        offset={{ top: 15 }}
        effect='solid'
        backgroundColor='#37444e'
      />
      {state.rowToStop && (
        <PopUpModal
          open={true}
          closeModal={() => toggleStopModal(null)}
          title="Arrêter l'insertion CRM"
          description="Voulez-vous vraiment arrêter l'insertion CRM de cette liste ?"
          content={
            <div className='btn-row'>
              <button
                className={"btn btn-primary"}
                onClick={() => {
                  handleStopRow(state.rowToStop.fileId);
                }}>
                Valider
              </button>
              <span
                onClick={() => toggleStopModal(null)}
                className='btn btn-secondary'>
                Annuler
              </span>
            </div>
          }
        />
      )}
      {state.rowToReload && (
        <PopUpModal
          open={true}
          closeModal={() => toggleReloadModal(null)}
          title="Relancer l'export"
          description='Voulez-vous vraiment relancer cet export ?'
          content={
            <div className='btn-row'>
              <button
                className={"btn btn-primary"}
                onClick={() => reloadRow(state.rowToReload.fileId)}>
                Valider
              </button>
              <span
                onClick={() => toggleReloadModal(null)}
                className='btn btn-secondary'>
                Annuler
              </span>
            </div>
          }
        />
      )}
      {state.rowToDelete && (
        <PopUpModal
          className='modalmList'
          open={true}
          closeModal={() => toggleDeleteModal(null)}
          title={deleteModalTitle ? deleteModalTitle : "Supprimer une liste"}
          description={
            deleteModalMessage
              ? deleteModalMessage
              : "Voulez-vous vraiment supprimer cette liste ?"
          }
          content={
            <div className='btn-row'>
              <button
                className={"btn btn-primary"}
                onClick={() => deleteRow(state.rowToDelete)}>
                Valider
              </button>
              <span
                onClick={() => toggleDeleteModal(null)}
                className='btn btn-secondary'>
                Annuler
              </span>
            </div>
          }
        />
      )}
      {state.rowToSend && (
        <PopUpModal
          open={true}
          closeModal={() => toggleSendToMCampaignModal(null)}
          title='Envoyer une liste sur mCampaign'
          description={
            deleteModalMessage
              ? deleteModalMessage
              : "Êtes-vous sûr de vouloir envoyer cette liste sur mCampaign ?"
          }
          content={
            <div className='btn-row'>
              <button
                className={"btn btn-primary"}
                onClick={() => handleSendToMCampaign(state.rowToSend)}>
                Valider
              </button>
              <span
                onClick={() => toggleSendToMCampaignModal(null)}
                className='btn btn-secondary'>
                Annuler
              </span>
            </div>
          }
        />
      )}
      {rowData?.length ? (
        rowData.map((row, i) => {
          return (
            <>
              <tr key={i} className={i === 0 ? "first-column" : ""}>
                {headers.map((header, key) => {
                  //Check row value of actual header id is not undefined
                  if (typeof row.data[header.id] !== "undefined") {
                    if (key === 0 && header.id === "email") {
                      return (
                        <td key={key}>
                          {renderAuthor(row.data, row.data.email)}
                        </td>
                      );
                    }
                    if (key === 0 && !firstHeaderId.includes("connector")) {
                      //If it's the name (so, it's editable)
                      return (
                        <td
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "442px",
                          }}
                          key={key}>
                          {pageId === "myListsExport" && (
                            <span
                              className={"crm-accordion"}
                              onClick={(e) =>
                                displayCrmPopupResult(e.target)
                              }></span>
                          )}
                        {renderListName(row.data[header.id], i)}
                        </td>
                      );
                    }
                    if (key === 0 && firstHeaderId.includes("connector")) {
                      // Connector type column, we can't edit
                      return (
                        <td key={key} className='display-mobile'>
                          {row.data[header.id]}
                        </td>
                      );
                    }
                    if (key !== 0 && firstHeaderId.includes("connector")) {
                      // All but the first column, we can edit
                      return (
                        <td
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "442px",
                          }}
                          key={key}>
                          {renderListNameConnector(
                            header.id,
                            row.data[header.id],
                            i
                          )}
                        </td>
                      );
                    }
                    if (
                      key !== 0 &&
                      key !== header.length - 1 &&
                      header.id === "date"
                    ) {
                      return (
                        <td key={key}>
                          {renderListName(row.data[header.id], i)}
                        </td>
                      );
                    }
                    if (
                      key !== 0 &&
                      key !== header.length - 3 &&
                      header.id === "statutSendToMCampaign"
                    ) {
                      if (pageId === "myListsExport") {
                        return (
                          <td key={key} className='display-mobile'>
                            <div
                              style={{ width: "70%" }}
                              className={`chip ${row.data.stateCode}`}
                              title={
                                row.data.crmStatus === "PENDING"
                                  ? STATUS_LABELS[row.data.crmStatus]
                                  : row.data.status
                              }>
                              {row.data.crmStatus === "PENDING"
                                ? STATUS_LABELS[row.data.crmStatus]
                                : row.data.status}
                            </div>
                          </td>
                        );
                      } else if (
                        row.data.statutSendToMCampaign.includes(
                          "Envoi en cours sur mCampaign"
                        )
                      )
                        return (
                          <td
                            key={key}
                            className='display-mobile spinner-table '>
                            <div style={{ width: "75%" }}>
                              {row.data.statutSendToMCampaign}
                            </div>
                            <div class='lds-spinner spinner-container'>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </td>
                        );
                    }
                    if (
                      key !== 0 &&
                      key !== header.length - 1 &&
                      header.id === "author"
                    ) {
                      return (
                        <td key={key} className='display-mobile'>
                          {renderAuthor(row.data, row.data.author)}
                        </td>
                      );
                    }
                    if (
                      key !== 0 &&
                      key !== header.length - 1 &&
                      header.type === "LIST"
                    ) {
                      return (
                        <td key={key} className='display-mobile'>
                          {renderTypeList(row.data)}
                        </td>
                      );
                    }
                    if (header.id === "roles") {
                      return (
                        <td key={key} className='display-mobile'>
                          {renderRoles(row.data)}
                        </td>
                      );
                    }
                    if (header.id === "errorLines") {
                      const linkDlError = row.data["showErrorLink"] && (
                        <a
                          href={`/api/mlist/filters/errors/${row.data.conceptName}`}>
                          <span
                            data-tip={row.data.downloadLabel}
                            className='icon icon-on-load'
                          />
                        </a>
                      );
                      return (
                        <td key={key} className='display-mobile'>
                          <div className='error-lines'>
                            {row.data[header.id]}
                            {linkDlError}
                          </div>
                        </td>
                      );
                    } else {
                      //Else it's not editable, simply display the value
                      return (
                        <td key={key} className='display-mobile'>
                          {row.data[header.id]}
                        </td>
                      );
                    }
                  } else {
                    return null;
                  }
                })}
                {headers[headers.length - 1].id === "actions" && (
                  <td>{elementActions(row.data, i, firstHeaderId)}</td>
                )}
              </tr>
              {pageId === "myListsExport" && (
                <CrmInsertReport
                  exportType={row.data.exportType}
                  nb_entreprises={row.data.numberOfEnterprises}
                  nb_contacts={row.data.numberOfContacts}
                  nb_rejets_entreprises={
                    row.data.numberOfRejectedEnterprises > 0
                      ? row.data.numberOfRejectedEnterprises
                      : "-"
                  }
                  nb_rejets_contacts={row.data.numberOfRejectedContacts}
                  urlRejectedAccounts={row.data.urlRejectedAccounts}
                  urlRejectedContacts={row.data.urlRejectedContacts}
                  fullName={row.data.givenName}
                  email={row.data.author}
                />
              )}
            </>
          );
        })
      ) : (
        <tr>
          <td colSpan={headers.length}>
            <NoData />
          </td>
        </tr>
      )}
    </>
  );
}
