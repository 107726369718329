import React, { useState } from "react";
import Modal from "react-responsive-modal";
import * as _ from "lodash";
import { Row, Col } from "react-flexbox-grid";

import CheckBox from "../../../../fields/Checkbox/Checkbox";
import { addItemToExportPackQuery } from "./../../QueryTool";
import DonneesFinanciairesRadioInput from "./DonneesFinanciairesRadioInput";

export default function DonneesFinancieresCategoryModal({
  selectedCategory,
  resetSelectedCategory,
  configHeader,
  packLinkedToCiblage,
  requestParams,
  updateQuery,
  packExport,
}) {
  const [step, setStep] = useState(1);
  const [selectedGrpDonnees, setSelectedGrpDonnees] = useState(
    packExport.selectedGrpDonnees ? packExport.selectedGrpDonnees : "NOT_SET",
  );
  const [yearChecked, setYearChecked] = useState(
    packExport.yearChecked ? packExport.yearChecked : [],
  );
  const [liasseUnChecked, setLiasseUnChecked] = useState(
    packExport.liasseUnChecked ? packExport.liasseUnChecked : [],
  );

  const today = new Date();
  const yearList = [];

  for (let i = 0; i < 11; i++) {
    if (i >= 1) {
      yearList.push(JSON.stringify(today.getFullYear() - i));
    }
  }

  const checkedAllLiasse = () => {
    setLiasseUnChecked([]);
  };

  const selectAllYear = () => {
    setYearChecked(yearList);
  };

  const unCheckedAllLiasse = () => {
    let liasse_list = [];
    selectedCategory.exportPackConfigContent.exportFields.forEach((element) =>
      liasse_list.push(element.id),
    );
    setLiasseUnChecked(liasse_list);
  };

  const handleClickCheckboxLiasse = (event, liasse) => {
    let liasse_list = [...liasseUnChecked];
    if (!liasse_list.includes(liasse)) {
      liasse_list.push(event.target.value);
    } else {
      liasse_list = liasse_list.filter((item) => item !== event.target.value);
    }
    setLiasseUnChecked(liasse_list);
  };

  const ifItChecked = (item, data) => {
    return data.indexOf(item) !== -1;
  };

  const initYearChecked = () => {
    let initYearChecked = [];
    _.forEach(JSON.parse(requestParams.query), function (groups) {
      _.forEach(groups, function (value) {
        if (value[1] === "mlist.bilans.anneeBilan") {
          initYearChecked = value[3];
        }
      });
    });
    return initYearChecked;
  };

  const unSelectAllYear = () => {
    setYearChecked(initYearChecked());
  };

  const handleClickCheckboxYear = (event, year) => {
    let year_list = [...yearChecked];
    if (!year_list.includes(year)) {
      year_list.push(event.target.value);
    } else {
      year_list = year_list.filter((item) => item !== year);
    }
    setYearChecked(year_list);
  };

  const addItem = (el) => {
    const query = addItemToExportPackQuery(
      requestParams.packExports,
      el.id,
      [],
      liasseUnChecked,
      selectedGrpDonnees,
      yearChecked,
    );
    updateQuery(query);
    resetSelectedCategory();
    setStep(1);
  };

  const getCheckboxesYear = (data) => {
    if (!data) {
      return null;
    }
    return data.map((item, i) => {
      return (
        <li key={i}>
          <CheckBox
            handleCheckChildElement={(e) => handleClickCheckboxYear(e, item)}
            checked={ifItChecked(item, yearChecked)}
            value={item}
          >
            {item}
          </CheckBox>
        </li>
      );
    });
  };

  const getCheckboxesLiasse = (data) => {
    return data
      ? data.map((item, i) => {
          return (
            <li key={i}>
              <CheckBox
                handleCheckChildElement={(e) => {
                  handleClickCheckboxLiasse(e, item.id);
                }}
                checked={!ifItChecked(item.id, liasseUnChecked)}
                value={item.id}
              >
                {item.label}
              </CheckBox>
            </li>
          );
        })
      : [];
  };

  const getStepGrpDonnees = () => {
    let exportPackDonneesFinancieres;

    const typeSelectedGrpDonnees = selectedGrpDonnees;

    if (packLinkedToCiblage) {
      exportPackDonneesFinancieres = packLinkedToCiblage.filter(
        (item) => item.id === "export.pack.donneesFinancieres",
      );
    }
    const buttonEnableClass = selectedGrpDonnees ? "" : "disabled";
    const buttonNext =
      typeSelectedGrpDonnees === "FULL" ? (
        <button
          className={`btn btn-primary ${buttonEnableClass}`}
          onClick={() => setStep(2)}
        >
          Suivant
        </button>
      ) : (
        <button
          className={`btn btn-primary ${buttonEnableClass}`}
          onClick={() => setStep(3)}
        >
          Suivant
        </button>
      );
    switch (step) {
      case 2:
        return (
          <>
            <div className='single-tab-modal-content modal-content'>
              <p>
                Vous avez choisi d'exporter le groupe de données au complet.
                <br />
                Sélectionnez les sections de la liasse à exporter.
              </p>
              <div className='selectAll'>
                <span onClick={checkedAllLiasse}>Tout sélectionner</span>
                &nbsp;|&nbsp;
                <span onClick={unCheckedAllLiasse}>Tout désélectionner</span>
              </div>
              <div className='modal-content__category-list modal-content-liasses'>
                <div>
                  <Row className='checkboxes-wrapper'>
                    <Col xs={12}>
                      <ul className='checkboxes-list'>
                        {getCheckboxesLiasse(
                          selectedCategory.exportPackConfigContent.exportFields,
                        )}
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className='actions-holder'>
                <button
                  className={`btn btn-primary`}
                  onClick={() => setStep(3)}
                >
                  Suivant
                </button>
                <button
                  className='btn btn-secondary'
                  onClick={closeCategoryModal}
                >
                  Annuler
                </button>
                <p onClick={() => setStep(1)}>Retour à l'étape précédente</p>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className='single-tab-modal-content modal-content'>
              <p>
                Vous avez choisi d'exporter le groupe de données{" "}
                {typeSelectedGrpDonnees === "FULL"
                  ? "au complet"
                  : typeSelectedGrpDonnees === "SIMPLE"
                    ? "simplifiés"
                    : typeSelectedGrpDonnees === "FILTER"
                      ? "filtrées"
                      : ""}
                .
                <br />
                Sélectionnez les années des bilans à exporter.
              </p>
              <div className='selectAll'>
                <span onClick={() => selectAllYear()}>Tout sélectionner</span>
                &nbsp;|&nbsp;
                <span onClick={() => unSelectAllYear()}>
                  Tout désélectionner
                </span>
              </div>
              <div className='modal-content__category-list'>
                <div>
                  <Row className='checkboxes-wrapper'>
                    <Col xs={12}>
                      <ul className='checkboxes-list'>
                        {getCheckboxesYear(yearList)}
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className='actions-holder'>
                <button
                  className={`btn btn-primary`}
                  onClick={() =>
                    addItem({ id: "export.pack.donneesFinancieres" })
                  }
                >
                  Valider
                </button>
                <button
                  className='btn btn-secondary'
                  onClick={closeCategoryModal}
                >
                  Annuler
                </button>
                {typeSelectedGrpDonnees === "FULL" ? (
                  <>
                    <p onClick={() => setStep(2)}>
                      Retour à l'étape précédente
                    </p>
                  </>
                ) : (
                  <>
                    <p onClick={() => setStep(1)}>
                      Retour à l'étape précédente
                    </p>
                  </>
                )}
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className='single-tab-modal-content modal-content'>
              <p>
                Sélectionnez le groupe de données que vous souhaitez exporter
              </p>
              <div className='modal-content__category-list'>
                <DonneesFinanciairesRadioInput
                  disabled={exportPackDonneesFinancieres.length}
                  id='FILTER'
                  setSelectedGrpDonnees={setSelectedGrpDonnees}
                  selectedGrpDonnees={selectedGrpDonnees}
                  label={"Uniquement les données filtrées"}
                />
                <DonneesFinanciairesRadioInput
                  disabled={true}
                  id='SIMPLE'
                  setSelectedGrpDonnees={setSelectedGrpDonnees}
                  selectedGrpDonnees={selectedGrpDonnees}
                  label={"Simplifié"}
                  p={
                    "Comprend 20 données financières essentielles du dernier  bilan publié sur 12 mois pour chaque entreprise + les champs sur lesquels vous avez filtré."
                  }
                />
                <DonneesFinanciairesRadioInput
                  disabled={true}
                  id='FULL'
                  setSelectedGrpDonnees={setSelectedGrpDonnees}
                  selectedGrpDonnees={selectedGrpDonnees}
                  label={"Complet"}
                  p={
                    "Comprend l'intégralité des champs du bilan complet et vous pourrez sélectionner les champs à exporter."
                  }
                />
              </div>
              <div className='actions-holder'>
                {buttonNext}
                <button
                  className='btn btn-secondary'
                  onClick={closeCategoryModal}
                >
                  Annuler
                </button>
              </div>
            </div>
          </>
        );
    }
  };

  const closeCategoryModal = () => {
    resetSelectedCategory();
  };

  return (
    <Modal
      open={!!selectedCategory}
      onClose={closeCategoryModal}
      focusTrapped={false}
      center
    >
      <div className='export-category-modal single-tab-modal'>
        {selectedCategory != null && (
          <>
            <header>
              <div className='left-header'>
                <span
                  className={"icon icon-" + configHeader.primaryLayoutIcon}
                />
                <div className={"title-count-container"}>
                  <div className='title-container'>
                    {configHeader.primaryLayoutTitle}
                  </div>
                  <div className='count-container'>
                    {configHeader.primaryLayoutSubTitle}
                  </div>
                </div>
              </div>
              <div className='separator'></div>
              <div className='right-header'>
                <div className='right-header__title'>
                  Choix du groupe de données à exporter
                </div>
              </div>
            </header>
            {getStepGrpDonnees()}
          </>
        )}
      </div>
    </Modal>
  );
}
