import React from "react";
import Dropdown from "../Dropdown/Dropdown";

import "../ModalWindow/components/modalViews/checkboxIcon/CheckBoxIconModalComponent.scss";

export default function Score({ score }) {
  const { icon, risque, description, procedure, date } = score;
  const isGreen = procedure === "NON";

  return (
    score && (
      <Dropdown
        title={<h2>Solvabilité</h2>}
        content={
          <div className='row score'>
            <div className='col card'>
              <p>Score</p>
              {icon && (
                <p>
                  <span
                    className={`icon icon-scoring-${icon}`}
                    style={
                      icon === "green"
                        ? { borderColor: "#71cc51" }
                        : icon === "orange"
                          ? { borderColor: "#f35f06" }
                          : icon === "red"
                            ? { borderColor: "#d22e26" }
                            : icon === "yellow"
                              ? { borderColor: "#f3b506" }
                              : { borderColor: "#000" }
                    }
                  />
                </p>
              )}
              <p
                style={
                  icon === "green"
                    ? { color: "#71cc51" }
                    : icon === "orange"
                      ? { color: "#f35f06" }
                      : icon === "red"
                        ? { color: "#d22e26" }
                        : icon === "yellow"
                          ? { color: "#f3b506" }
                          : { color: "#000" }
                }
              >
                {risque || "NON"}
              </p>
              <p className={risque || "backgroundText"}>
                {description.includes("0/20")
                  ? "Aucun score connu"
                  : description}
              </p>
            </div>
            <div className='col card'>
              <p>Procédure collective</p>
              <p style={isGreen ? { color: "#71cc51" } : { color: "#d22e26" }}>
                {procedure}
              </p>
              <p
                className='backgroundText'
                style={isGreen ? { color: "#71cc51" } : { color: "#d22e26" }}
              >
                {date}
              </p>
            </div>
          </div>
        }
      />
    )
  );
}
