import React, { useState, useEffect } from "react";
import "./TreeGroup.scss";
import Select from "react-select";
import RangeDateComponent from "../rangeDate/RangeDateComponent";
import SelectedValuesBox from "../selectedValuesBox/SelectedValuesBox";
import RangeNumberComponent from "../rangeNumber/RangeNumberComponent";
import { Col, Row } from "react-flexbox-grid";
import ResetFilter from "../reset/ResetFilters";

const TreeGroupComponent = ({
  addQuery,
  removeQuery,
  removeAllQuery,
  structure,
  content,
  tab,
}) => {
  const [selectFamilyValue, setSelectFamilyValue] = useState({});
  const [selectFamilyOptions, setSelectFamilyOptions] = useState([]);
  const [selectGroupValue, setSelectGroupValue] = useState({});
  const [selectGroupOptions, setSelectGroupOptions] = useState([]);
  const [selectConceptValue, setSelectConceptValue] = useState({});
  const [selectConceptOptions, setSelectConceptOptions] = useState([]);
  const [resetField, setResetField] = useState(false);

  const [selectedValues, setSelectedValues] = useState([]);
  const [concepts, setConcepts] = useState([]);

  const initFamilySelects = (treeGroupCriteria) => {
    setSelectFamilyValue({
      value: treeGroupCriteria[0].valueRef,
      label: treeGroupCriteria[0].valueRef,
    });
    setSelectFamilyOptions(
      treeGroupCriteria.map((element) => {
        return {
          value: element.valueRef,
          label: element.valueRef,
        };
      }),
    );

    initGroupSelects(treeGroupCriteria[0].values);
  };

  const initGroupSelects = (treeGroupCriteria) => {
    setSelectGroupValue({
      value: treeGroupCriteria[0].valueRef,
      label: treeGroupCriteria[0].valueRef,
    });
    setSelectGroupOptions(
      treeGroupCriteria.map((element) => {
        return {
          value: element.valueRef,
          label: element.valueRef,
        };
      }),
    );

    updateConceptSelect(
      treeGroupCriteria.filter(
        (element) => element.valueRef === treeGroupCriteria[0].valueRef,
      )[0],
    );
  };

  const initConceptSelects = (concepts) => {
    setSelectConceptValue({
      value: concepts[0].conceptName,
      label: concepts[0].displayName,
    });
    setSelectConceptOptions(
      concepts.map((concept) => {
        return {
          value: concept.conceptName,
          label: concept.displayName,
        };
      }),
    );
  };
  useEffect(() => {
    if (
      tab.treeGroupCriteria &&
      tab.treeGroupCriteria.length > 0 &&
      tab.treeGroupCriteria[0].values &&
      tab.treeGroupCriteria[0].values.length > 0
    ) {
      initFamilySelects(tab.treeGroupCriteria);
    } else if (tab.treeGroupCriteria && tab.treeGroupCriteria.length === 1) {
      initConceptSelects(content.concepts);
    } else if (
      tab.treeGroupCriteria &&
      tab.treeGroupCriteria.length > 0 &&
      tab.treeGroupCriteria[0].conceptRef &&
      tab.treeGroupCriteria[0].conceptRef.length > 0
    ) {
      initGroupSelects(tab.treeGroupCriteria);
    }

    if (tab.conceptClauses.length > 0) {
      setConcepts(tab.conceptClauses[0].filters);
    }
    //eslint-disable-next-line
  }, [content.concepts, tab.treeGroupCriteria, tab.conceptClauses]);

  useEffect(() => {
    resetField && setResetField(false);
  }, [resetField]);

  const updateConceptSelect = (findedGroupCriteria) => {
    initConceptSelects(
      findedGroupCriteria.conceptRef.map((c) => {
        const findedConcept = content.concepts.filter(
          (concept) => concept.conceptName === c,
        )[0];
        const d = findedConcept ? findedConcept.displayName : "";
        return {
          conceptName: c,
          displayName: d,
        };
      }),
    );
  };

  const addValue = (receivedValue) => {
    const newSelectedValues = [...selectedValues];
    receivedValue.ref = selectConceptValue.value;
    newSelectedValues.push(receivedValue);
    setSelectedValues(newSelectedValues);
    addQuery(receivedValue, false, structure);
  };

  const removeConcept = (index) => {
    const newConcepts = [...concepts];
    const newSelectedValues = [...selectedValues];
    const dropValue = concepts[index];
    newConcepts.splice(index, 1);
    newSelectedValues.splice(index, 1);

    setConcepts(newConcepts);
    setSelectedValues(newSelectedValues);

    removeQuery({
      ref: dropValue.conceptRef,
      values: dropValue.expressions,
      operator: dropValue.operator,
    });
  };

  const handleReset = () => {
    if (!!concepts.length) {
      const filterType = [...concepts].shift().conceptRef;

      setConcepts([]);
      setSelectedValues([]);
      removeAllQuery(filterType);
    }
    setResetField(true);
  };

  const handleChangeFamily = (val) => {
    setSelectFamilyValue(val);
    initGroupSelects(
      tab.treeGroupCriteria.filter(
        (element) => element.valueRef === val.value,
      )[0].values,
    );
  };

  const handleChangeGroup = (val) => {
    setSelectGroupValue(val);
    let findedCriteria;
    if (selectFamilyOptions.length > 0) {
      findedCriteria = tab.treeGroupCriteria.filter(
        (element) => element.valueRef === selectFamilyValue.value,
      )[0].values;
    } else {
      findedCriteria = tab.treeGroupCriteria;
    }

    updateConceptSelect(
      findedCriteria.filter((element) => element.valueRef === val.value)[0],
    );
  };

  const handleChangeConcept = (val) => {
    setSelectConceptValue(val);
  };

  const selectFamily =
    selectFamilyOptions.length > 0 ? (
      <Col md={4}>
        <Select
          className='select'
          classNamePrefix='react-select'
          value={selectFamilyValue}
          onChange={handleChangeFamily}
          options={selectFamilyOptions}
        />
      </Col>
    ) : (
      <></>
    );

  const selectConcept =
    selectConceptOptions.length > 0 ? (
      <Col md={selectFamilyOptions.length > 0 ? 4 : 6}>
        <Select
          className='select'
          classNamePrefix='react-select'
          value={selectConceptValue}
          onChange={handleChangeConcept}
          options={selectConceptOptions}
        />
      </Col>
    ) : (
      <></>
    );

  const selectGroup =
    selectGroupOptions.length > 0 ? (
      <Col md={selectFamilyOptions.length > 0 ? 4 : 6}>
        <Select
          className='select'
          classNamePrefix='react-select'
          value={selectGroupValue}
          onChange={handleChangeGroup}
          options={selectGroupOptions}
        />
      </Col>
    ) : (
      <></>
    );

  const getSelectedItems = concepts.length ? (
    <SelectedValuesBox concepts={concepts} removeConcept={removeConcept} />
  ) : null;

  const renderActiveComponent = selectConceptValue
    ? content.concepts
        .filter((concept) => selectConceptValue.value === concept.conceptName)
        .map((concept, key) => {
          switch (concept.family) {
            case "PROCOL":
              return <RangeDateComponent key={key} handleClick={addValue} />;
            case "DATE":
              return <RangeDateComponent key={key} handleClick={addValue} />;
            case "NUMBER":
              return (
                <RangeNumberComponent
                  key={key}
                  resetField={resetField}
                  handleClick={addValue}
                />
              );
            default:
              return null;
          }
        })
    : null;

  return (
    <div className='modal modal-tree-group'>
      <ResetFilter handleDelete={handleReset} />
      <div className={"modal-content include"}>
        <Row className={"select-container"}>
          {selectFamily}
          {selectGroup}
          {selectConcept}
        </Row>
        <div className='active-component-container'>
          {renderActiveComponent}
        </div>
        {getSelectedItems}
      </div>
    </div>
  );
};

export default TreeGroupComponent;
