import React, { useState } from "react";
import { Row, Col } from "react-flexbox-grid";

import SelectedCategoryModal from "./SelectedCategoryModal";
import TargetResumePrimaryPanel from "./TargetResumePimaryPanel";
import DonneesFinancieresCategoryModal from "./DonneesFinancieresCategoryModal";
import {
  isExportPackInQuery,
  addItemToExportPackQuery,
  removeItemToExportPackQuery,
  getItemFromQuery,
} from "./../../QueryTool";

import "./TargetResumeSection.scss";

export default function TargetResumeSection({
  item,
  goToFirstStep,
  requestParams,
  packConfig,
  updateQuery,
  isMobile,
  saveTargetingPopin,
  accountId,
}) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [packAvailable] = useState(
    item.secondaryPanel.packAvailable.exportPacks,
  );
  const [packLinkedToCiblage] = useState(
    item.secondaryPanel.packLinkedToCiblage.exportPacks,
  );

  const isCardSelected = (id) =>
    isExportPackInQuery(requestParams.packExports, id);

  const getPackAvailable = (el) => {
    if (!el) {
      return null;
    }
    return (
      <div className='pack-available-section'>
        <div className='title-holder'>
          <h3>{el.title.title}</h3>
          <p>{el.title.subTitle}</p>
        </div>
        <div className='list-holder'>
          <ul className='list-export'>
            {getExportListItems(packAvailable, "packAvailable")}
          </ul>
          <hr />
        </div>
      </div>
    );
  };

  const openCategoryModal = (el) => {
    const foundItem = packConfig.exportPackConfigs.find(
      (packConfig) => packConfig.id === el.refExportPackConfig,
    );

    if (foundItem) {
      setSelectedCategory(foundItem);
    }
  };

  const closeCategoryModal = () => {
    setSelectedCategory(null);
  };

  const getCategoryModal = () => {
    const configHeader = selectedCategory
      ? selectedCategory.exportPackConfigHeader
      : null;
    const configContent = selectedCategory
      ? selectedCategory.exportPackConfigContent
      : null;
    const packExport = configContent
      ? getItemFromQuery(requestParams.packExports, configContent.id)
      : {};
    if (!selectedCategory) {
      return null;
    } else if (
      selectedCategory.id === "exportPackConfig-export.pack.donneesFinancieres"
    ) {
      return (
        <DonneesFinancieresCategoryModal
          selectedCategory={selectedCategory}
          resetSelectedCategory={() => setSelectedCategory(null)}
          configHeader={configHeader}
          packLinkedToCiblage={packLinkedToCiblage}
          packAvailable={packAvailable}
          requestParams={requestParams}
          updateQuery={updateQuery}
          packExport={packExport}
        />
      );
    } else {
      return (
        <SelectedCategoryModal
          requestParams={requestParams}
          selectedCategory={selectedCategory}
          closeCategoryModal={closeCategoryModal}
          configHeader={configHeader}
          configContent={configContent}
          packExport={packExport}
          isPackSelected={isExportPackInQuery(
            requestParams.exportPacks,
            configContent.id,
          )}
          updateQuery={updateQuery}
        />
      );
    }
  };

  const getExportListItems = (el, type) => {
    if (!el) {
      return null;
    }
    return el.map((e, i) => {
      return (
        <li
          key={i}
          className={
            "card " +
            (isCardSelected(e.id) ? "selected" : "") +
            (e.mandatory ? " mandatory" : "")
          }
        >
          <div className='card-holder'>
            <div
              className='row-card'
              onClick={() => {
                if (!e.mandatory) {
                  addRemoveItem(e, type);
                }
              }}
            >
              <span
                className={"icon " + (e.icon ? "icon-" + e.icon : "default")}
              />
              <div className='card-text-holder'>
                <span className='card-title'>{e.label}</span>
                <span className='card-subtitle'>{e.subLabel}</span>
              </div>
            </div>
            <div className='card-btn-row'>
              <div className='column'>{getEdit(e)}</div>
              <div className='column'>{getCardOptions(e, type)}</div>
            </div>
          </div>
        </li>
      );
    });
  };

  const getPackLinkedToCiblage = (el) => {
    if (!el) {
      return null;
    }
    return (
      <div className='pack-linked-section'>
        <div className='title-holder'>
          <h3>{el.title.title}</h3>
          <p>{el.title.subTitle}</p>
        </div>
        <div className='list-holder'>
          <ul className='list-export'>
            {getExportListItems(packLinkedToCiblage, "packLinkedToCiblage")}
          </ul>
          <hr />
        </div>
      </div>
    );
  };

  const getEdit = (e) => {
    return (
      <button onClick={() => openCategoryModal(e)}>
        <span className='icon icon-edit' />
        <span className='label'>Modifier</span>
      </button>
    );
  };

  const addRemoveItem = (el) => {
    if (isExportPackInQuery(requestParams.packExports, el.id)) {
      removeItem(el);
    } else {
      addItem(el);
    }
  };

  const removeItem = (el) => {
    const query = removeItemToExportPackQuery(requestParams.packExports, el.id);
    updateQuery(query);
  };

  const addItem = (el, options) => {
    const query = addItemToExportPackQuery(
      requestParams.packExports,
      el.id,
      options,
    );
    updateQuery(query);
    setSelectedCategory(null);
  };

  const getCardOptions = (el, type) => {
    if (el.mandatory) {
      return (
        <button>
          <span className='icon icon-padlock' />
          <span className='label'>Obligatoire</span>
        </button>
      );
    } else {
      return (
        <button onClick={() => addRemoveItem(el, type)}>
          <span
            className={`icon ${
              isCardSelected(el.id) ? "icon-close" : "icon-plus"
            }`}
          />
          <span className='label'>
            {isCardSelected(el.id) ? "Supprimer" : "Ajouter"}
          </span>
        </button>
      );
    }
  };

  const getSecondaryPanel = (el) => {
    const panel = el.secondaryPanel;
    if (!panel) {
      return null;
    }
    return (
      <div className='export-section'>
        <header className='text-holder'>
          <h1>{panel.title.title}</h1>
          <hr />
        </header>
        {getPackLinkedToCiblage(panel.packLinkedToCiblage)}
        {getPackAvailable(panel.packAvailable)}
        {getCategoryModal()}
      </div>
    );
  };

  if (!item) {
    return null;
  }
  return (
    <Row className='PageContent h-100'>
      <Col sm={12} md={4} className='h-100 pb-10 with-shadow scrollAuto'>
        <TargetResumePrimaryPanel
          panel={item.primaryPanel}
          saveTargetingPopin={saveTargetingPopin}
          accountId={accountId}
          query={requestParams.query}
          queryCondition={requestParams.queryCondition}
          goToFirstStep={goToFirstStep}
        />
      </Col>
      <Col sm={12} md={8} className='h-100 pb-10 scrollAuto'>
        <>{getSecondaryPanel(item)}</>
      </Col>
    </Row>
  );
}
