import React from "react";
import downloadFile from "../../utils/downloadFile";

export default function CrmInsertReport({
  exportType,
  nb_entreprises,
  nb_contacts,
  nb_rejets_entreprises,
  nb_rejets_contacts,
  urlRejectedAccounts,
  urlRejectedContacts,
  fullName,
  email,
}) {
  const downloadRejectSirets = async () => {
    downloadFile(urlRejectedAccounts);
  };
  const downloadRejectContacts = async () => {
    downloadFile(urlRejectedContacts);
  };
  return (
    <tr className={"crm-report hidden"}>
      <td colSpan={6}>
        <div className={"crm-report-container"}>
          <span className='crm-report-info-block'>
            <span className='crm-report-info-line'>
              <label>Volume d'entreprises : </label>
              <span className={"value nb entreprises"}>
                {nb_entreprises > 0 ? nb_entreprises : "-"}
              </span>
            </span>
            {exportType === "EXPORT_CRM" && (
              <>
                <span className='crm-report-info-line'>
                  <label>Informations non insérées :</label>
                  <span
                    className={
                      nb_rejets_entreprises > 0
                        ? "value rejected entreprises"
                        : "value"
                    }
                    title={
                      nb_rejets_entreprises > 0
                        ? "Télécharger les siret rejetés"
                        : "Aucun siret rejeté"
                    }
                    onClick={() => {
                      if (nb_rejets_entreprises > 0) downloadRejectSirets();
                    }}>
                    {nb_rejets_entreprises > 0 ? nb_rejets_entreprises : "-"}
                  </span>
                </span>
              </>
            )}
          </span>
          <span className='crm-report-info-block'>
            <span className='crm-report-info-line'>
              <label>Volume de contacts : </label>
              <span className={"value nb contacts"}>
                {nb_contacts > 0 ? nb_contacts : "-"}
              </span>
            </span>

            {exportType === "EXPORT_CRM" && (
              <>
                <span className='crm-report-info-line'>
                  <label>Informations non insérées : </label>
                  <span
                    className={
                      nb_rejets_contacts > 0
                        ? "value rejected contacts"
                        : "value"
                    }
                    title={
                      nb_rejets_contacts > 0
                        ? "Télécharger les contacts rejetés"
                        : "Aucun contact rejeté"
                    }
                    onClick={() => {
                      if (nb_rejets_contacts > 0) downloadRejectContacts();
                    }}>
                    {nb_rejets_contacts > 0 ? nb_rejets_contacts : "-"}
                  </span>
                </span>
              </>
            )}
          </span>
          <span className='crm-report-info-block'>
            <span className='crm-report-info-line'>
              <label>Auteur :&nbsp;</label>
              {fullName !== null && fullName !== "" && (
                <span>{fullName} -&nbsp;</span>
              )}
              <span>{email}</span>
            </span>
          </span>
        </div>
      </td>
    </tr>
  );
}
