import React, { useState } from "react";

import axios from "axios";

import { Tabs, TabLink } from "react-tabs-redux";
import { Col, Row } from "react-flexbox-grid";

import DatePicker, { registerLocale } from "react-datepicker";
import { format, parse } from "date-fns";

import PageLoader from "../../pageLoader/pageLoaderComponent";
import AdminStatTab from "./AdminStatTab";

import fr from "date-fns/locale/fr";
import downloadFile from "../../../utils/downloadFile";
registerLocale("fr", fr);

export default function AdministrationStats({
  accountId,
  component,
  sendQueryToApi,
}) {
  const [isUpdating, setIsUpdating] = useState(false);

  const [dateBegin, setDateBegin] = useState(
    parse(component.dateBegin, "dd/MM/yyyy", new Date()),
  );
  const [dateEnd, setDateEnd] = useState(
    parse(component.dateEnd, "dd/MM/yyyy", new Date()),
  );

  const applyDate = (tab) => {
    setIsUpdating(true);
    sendQueryToApi(
      {
        tabId: "stats",
        stat_tab: tab.id || component.tabs.find((tab) => tab.selected).id,
        stat_date_begin: format(dateBegin, "dd/MM/yyyy"),
        stat_date_end: format(dateEnd, "dd/MM/yyyy"),
      },
      () => setIsUpdating(false),
    );
    setIsUpdating(true);
  };

  const downloadStat = async () => {
    const tabId = component.tabs.find((tab) => tab.selected).id;
    const begin = format(dateBegin, "dd/MM/yyyy");
    const end = format(dateEnd, "dd/MM/yyyy");
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}administration/${accountId}/download?tabId=stats&stat_tab=${tabId}&stat_date_begin=${begin}&stat_date_end=${end}`,
    );
    downloadFile(data);
  };

  return (
    <div className='admin-settings scrollAuto'>
      {isUpdating && <PageLoader opacity={true} />}
      <div className='page-heading'>
        <Row className='ovv'>
          <Col sm={12} md={9}>
            <h2>Statistiques</h2>
          </Col>
        </Row>
        <div className='sub-title'>
          <div className='info'>
            Analysez les statistiques de votre compte, au global ou selon vos
            utilisateurs.
          </div>
          <div className='dates'>
            <div className='label'>Depuis</div>
            <DatePicker
              dateFormat='dd/MM/yyyy'
              selected={dateBegin}
              maxDate={new Date()}
              onChange={setDateBegin}
              locale='fr'
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [0, 0],
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    rootBoundary: "viewport",
                    tether: false,
                    altAxis: true,
                  },
                },
              ]}
            />
            <div className='label'>Jusqu'à</div>
            <DatePicker
              dateFormat='dd/MM/yyyy'
              selected={dateEnd}
              minDate={dateBegin}
              maxDate={new Date()}
              locale='fr'
              onChange={setDateEnd}
              popperPlacement='bottom-start'
              popperModifiers={[
                {
                  name: "offset",
                  options: {
                    offset: [0, 0],
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    rootBoundary: "viewport",
                    tether: false,
                    altAxis: true,
                  },
                },
              ]}
            />
            <button
              className='btn btn-primary btn-primary-small'
              onClick={applyDate}
              type='button'
            >
              <span className='icon icon-step-confirmation' />
            </button>
          </div>
        </div>
        <Row></Row>
        <div className='established-tabs'>
          <Tabs>
            <div className='tab-heading capitalize-text'>
              {component.tabs.map((tab, key) => (
                <TabLink
                  key={key}
                  to={tab.id}
                  onClick={() => applyDate(tab)}
                  style={{
                    textTransform: "none",
                  }}
                >
                  {tab.label}
                </TabLink>
              ))}
            </div>
            {component.components.map((c, key) => (
              <div key={key}>
                {c.type === "DataTable" ? (
                  <AdminStatTab downloadStat={downloadStat} table={c} />
                ) : (
                  <></>
                )}
              </div>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
}
