import React, { Component } from "react";

import "./CheckBoxIconModalComponent.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import { Row, Col } from "react-flexbox-grid";
import ResetFilter from "../reset/ResetFilters";

const CustomCheckBox = (props) => {
  return (
    <label
      className={
        "icon-checkbox-holder " +
        (props.checked === true ? "checked-holder" : "")
      }
    >
      <div
        className={
          "custom-icon-checkbox " + (props.checked === true ? "checked" : "")
        }
      >
        <input
          key={props.id}
          onChange={props.handleCheckChildElement}
          type='checkbox'
          checked={props.checked}
          value={props.value}
        />
      </div>
      <span className='checkbox-content'>{props.children}</span>
    </label>
  );
};

class CheckBoxIconView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.props.content.concepts[0].values,
      optionsChecked: [],
      exclude: false,
    };

    this.handler = this.handler.bind(this);
    this.handleCheckChildElement = this.handleCheckChildElement.bind(this);
    this.ifItChecked = this.ifItChecked.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    if (this.props.concept && this.props.concept.length > 0) {
      let newChecked = [];

      let filters = this.props.concept[0].filters;

      let newEx = filters[0].not;
      this.setState({
        exclude: newEx,
      });

      filters.forEach((f, i) => {
        f.expressions.forEach((expression) => {
          newChecked.push({
            label: f.label,
            value: expression,
          });
        });
      });

      this.setState({
        optionsChecked: newChecked,
      });
    }
  }

  updateQuery() {
    const checkedValues = [];

    this.state.optionsChecked.forEach((option) => {
      checkedValues.push(option.value);
    });

    this.props.updateQuery(
      checkedValues.length ? [checkedValues] : [],
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal,
    );
  }

  handleCheckChildElement(event, elem) {
    let checkedArray = this.state.optionsChecked;
    let index = -1;

    checkedArray.forEach((e, i) => {
      if (e.value === elem.value) {
        index = i;
      }
    });

    if (index > -1) {
      checkedArray.splice(index, 1);
    } else {
      checkedArray.push(elem);
    }

    this.setState(
      {
        optionsChecked: checkedArray,
      },
      () => {
        this.updateQuery();
      },
    );
  }

  ifItChecked(i) {
    if (!this.state.optionsChecked) {
      return null;
    }
    const newArray = this.state.optionsChecked.filter((e) => {
      return e.value === i.value;
    });
    return newArray.length > 0;
  }

  getCheckboxes(checkbox_list) {
    if (!checkbox_list) {
      return null;
    }
    return checkbox_list.map((el, i) => {
      return (
        <Col
          sm={12}
          md={3}
          key={i}
          className={
            "checkbox-item checkbox-item-" + (el.icon ? el.icon : "default")
          }
        >
          <CustomCheckBox
            handleCheckChildElement={(e) => {
              this.handleCheckChildElement(e, el);
            }}
            checked={this.ifItChecked(el)}
            value={el.value}
          >
            {this.ifItChecked(el)}
            <span className={"checkbox-icon icon " + el.icon}></span>
            <span className='checkbox-title'>{el.label}</span>
          </CustomCheckBox>
        </Col>
      );
    });
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        if (this.state.optionsChecked.length) {
          this.updateQuery();
        }
      },
    );
  }

  handleReset() {
    this.setState(
      {
        optionsChecked: [],
      },
      () => {
        this.updateQuery();
      },
    );
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  getCheckBoxList() {
    const checkboxes = this.getCheckboxes(this.state.values);
    if (checkboxes.length < 5) {
      return (
        <div className='checkboxes-icon-wrapper'>
          <Row className='custom-checkbox-list'>{checkboxes}</Row>
        </div>
      );
    } else {
      return (
        <>
          <Row className='custom-checkbox-list'>
            {checkboxes.filter((el, index) => index < 3)}
          </Row>
          <Row className='custom-checkbox-list'>
            {checkboxes.filter((el, index) => index >= 3)}
          </Row>
        </>
      );
    }
  }

  render() {
    return (
      <div className='modal'>
        <ResetFilter handleDelete={this.handleReset} />
        <div
          className={
            "modal-content modal-icon-checkboxes" +
            (this.state.exclude ? " exclude" : " include")
          }
        >
          {this.getCheckBoxList()}
        </div>
        {this.getFooter()}
      </div>
    );
  }
}

export default CheckBoxIconView;
