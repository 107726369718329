import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";

import {
  addConceptToQuery,
  removeConceptToQuery,
} from "./../CreateList/QueryTool";
import NoData from "../NoData/NoData";

import "./CompagniesListsTable.scss";
import { createListConsts } from "../../utils/constants";

export default function CompagniesListsTableComponent({
  table,
  sendQuery,
  currentQuery,
}) {
  const {
    rowData,
    headers,
    options,
    defaultSortColumnId,
    defaultSortDescending,
  } = table;
  const [timer, setTimer] = useState(null);
  const [queryToSend, setQueryToSend] = useState(currentQuery);

  useEffect(() => {
    setQueryToSend(currentQuery);
  }, [currentQuery]);

  const handlePageChange = (pageNumber) => {
    const query = {};
    query[`tab_${table.id}_page`] = pageNumber;
    sendQuery(createListConsts.firstStep, query, false);
  };

  const handleClickSort = (id) => {
    if (rowData.length) {
      const query = {};
      query[`tab_${table.id}_sort_field`] = id;
      query[`tab_${table.id}_sort_order`] = table.defaultSortDescending
        ? "asc"
        : "desc";
      sendQuery(createListConsts.firstStep, query, false);
    }
  };

  const sendWithTimer = (query) => {
    if (timer) {
      clearTimeout(timer);
    }
    setQueryToSend(query);
    setTimer(
      setTimeout(() => {
        sendQuery(createListConsts.firstStep, query, true);
      }, 1000),
    );
  };

  const showSiret = ({ siret }) => {
    sendQuery(createListConsts.firstStep, { page_siret: siret }, false);
  };

  const addSiret = (
    { siret, selectedSiretNotExclude, selectedSiretExclude },
    exclude,
  ) => {
    const currentQuery = {
      query: queryToSend.query ? JSON.parse(queryToSend.query) : [],
      queryCondition: queryToSend.queryCondition
        ? JSON.parse(queryToSend.queryCondition)
        : [],
    };
    const newQuery =
      selectedSiretNotExclude || selectedSiretExclude
        ? removeConceptToQuery(
            currentQuery,
            table.options.actionConcept,
            [siret],
            table.options.actionConceptOperator,
          )
        : addConceptToQuery(
            currentQuery,
            table.options.actionConcept,
            [siret],
            null,
            table.options.actionConceptOperator,
            exclude,
          );

    const query = {
      query: JSON.stringify(newQuery.query),
      queryCondition: JSON.stringify(newQuery.queryCondition),
    };
    defaultSortColumnId &&
      (query[`tab_${table.id}_sort_field`] = defaultSortColumnId);
    query[`tab_${table.id}_sort_order`] = defaultSortDescending
      ? "asc"
      : "desc";
    query[`tab_${table.id}_page`] = options.currentPage;
    sendWithTimer(query);
  };

  const getActionDiv = (data) => {
    const classAdd = `action icon-confirm ${
      data.selectedSiretNotExclude && "checked"
    }`;
    const classRemove = `action icon-close ${
      data.selectedSiretExclude && "redCross"
    }`;
    const notSelected =
      !data.selectedSiretNotExclude && !data.selectedSiretExclude;
    return (
      <div className='actionDiv'>
        {(notSelected || data.selectedSiretNotExclude) && (
          <span className={classAdd} onClick={() => addSiret(data, false)} />
        )}
        {(notSelected || data.selectedSiretExclude) && (
          <span className={classRemove} onClick={() => addSiret(data, true)} />
        )}
        <span className='action icon-view' onClick={() => showSiret(data)} />
      </div>
    );
  };

  const getTableHeadingElements = (elements) =>
    elements.map(({ id, sortable, label }, i) => (
      <th
        key={i}
        id={id}
        onClick={() => sortable && handleClickSort(id)}
        className={
          "sort-item " +
          (defaultSortColumnId === id && table.defaultSortDescending
            ? "desc"
            : "asc")
        }
      >
        {label}
        {defaultSortColumnId === id && (
          <span className='icon icon-chevron-down visible' />
        )}
      </th>
    ));

  const getBodyRow = (header, row) => {
    if (header.id === "action") {
      return getActionDiv(row.data);
    } else if (header.id === "nomen") {
      return (
        <>
          <div title={row.data["nomenTruncate"] && row.data["nomen"]}>
            {row.data["nomenTruncate"]
              ? row.data["nomenTruncate"]
              : row.data["nomen"]}
          </div>
          {row.data["enseigne"] && (
            <div title={row.data["enseigneTruncate"] && row.data["enseigne"]}>
              {row.data["enseigneTruncate"]
                ? row.data["enseigneTruncate"]
                : row.data["enseigne"]}
            </div>
          )}
          {row.data["nom_commercial"] && (
            <div
              title={
                row.data["nom_commercialTruncate"] && row.data["nom_commercial"]
              }
            >
              {row.data["nom_commercialTruncate"]
                ? row.data["nom_commercialTruncate"]
                : row.data["nom_commercial"]}
            </div>
          )}
        </>
      );
    } else if (header.id === "activity") {
      return (
        <div title={row.data["activityTruncate"] && row.data["activity"]}>
          {row.data["activityTruncate"]
            ? row.data["activityTruncate"]
            : row.data["activity"]}
        </div>
      );
    }
    return row.data[header.id];
  };

  const getTableBodyRows = () => {
    return rowData?.length ? (
      rowData.map((row, i) => (
        <tr key={i}>
          {headers.map((header, key) => (
            <td key={key}>{getBodyRow(header, row)}</td>
          ))}
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={headers.length}>
          <NoData />
        </td>
      </tr>
    );
  };

  return (
    table && (
      <div className='table-targeting table-section'>
        <div className='table-holder'>
          <div className='table-wrapper'>
            <div className='colored-heading'>
              <div className='white-borders'>
                <table>
                  <thead>
                    <tr>{getTableHeadingElements(headers)}</tr>
                  </thead>
                  <tbody>{getTableBodyRows()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {options.elementPerPage < options.pageCount && (
          <div className='pagination-holder'>
            <Pagination
              activePage={options.currentPage}
              itemsCountPerPage={options.elementPerPage}
              totalItemsCount={options.pageCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              firstPageText={
                <span className='icon icon-angle-double-left'></span>
              }
              prevPageText={<span className='icon icon-angle-left'></span>}
              nextPageText={<span className='icon icon-angle-right'></span>}
              lastPageText={
                <span className='icon icon-angle-double-right'></span>
              }
            />
          </div>
        )}
      </div>
    )
  );
}
