import React, { Component } from "react";

import "./MultipleText.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import { Col } from "react-flexbox-grid";

class MultipleTextView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.props.content.concepts[0].values,
      selectedValues: [],
      inputValue: "",
      exclude: false,
      displayName: "",
    };

    this.handler = this.handler.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let displayName = "";
    let selectedValues = [];

    this.props.concept.forEach((concept) => {
      if (concept.filters && concept.filters.length) {
        concept.filters
          .filter(
            (filter) =>
              this.props.content.concepts[0].conceptName === filter.conceptRef,
          )
          .forEach((filter) => {
            this.setState({
              exclude: filter.not,
            });
            filter.expressions.forEach((expression) => {
              selectedValues.push({ value: expression });
            });
          });
      }
    });

    if (
      this.props.content.concepts[0] &&
      this.props.content.concepts[0].displayName
    ) {
      displayName = this.props.content.concepts[0].displayName;
    }

    this.setState({
      displayName,
      selectedValues,
    });
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        if (this.state.selectedValues.length) {
          this.sendQuery();
        }
      },
    );
  }

  sendQuery() {
    const selectedValues = [];

    this.state.selectedValues.forEach((selectedValue) => {
      selectedValues.push(selectedValue.value);
    });

    this.props.updateQuery(
      selectedValues.length ? [selectedValues] : [],
      this.props.content,
      this.state.exclude,
      this.props.tab,
      this.props.modal,
    );
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  addValue() {
    const selectedValues = this.state.selectedValues;

    this.setState({
      inputValue: "",
    });

    //Check if value doesn't exists
    if (
      !selectedValues.some(
        (el) => el.value.toLowerCase() === this.state.inputValue.toLowerCase(),
      )
    ) {
      const object = {
        value: this.state.inputValue,
      };
      selectedValues.push(object);

      this.setState(
        {
          selectedValues,
        },
        () => {
          this.sendQuery();
        },
      );
    }
  }

  removeValue(key) {
    const selectedValues = this.state.selectedValues;
    selectedValues.splice(key, 1);

    this.setState(
      {
        selectedValues,
      },
      () => {
        this.sendQuery();
      },
    );
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.addValue();
    }
  }

  handleChange(e) {
    this.setState({
      inputValue: e.target.value,
    });
  }

  renderSelectedValues() {
    return (
      <div className={"left-items"}>
        {this.state.selectedValues.map(({ value }, key) => {
          return (
            <div key={key} className={"label-entry"}>
              <span
                className='remove-item'
                onClick={() => this.removeValue(key)}
              >
                <span className='icon icon-close'></span>
              </span>
              <span className='label-value'>{value}</span>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div className='modal modal-multiple-text'>
        <Col
          md={12}
          className={
            "modal-content" + (this.state.exclude ? " exclude" : " include")
          }
        >
          <div className={"input-wrapper"}>
            <div className={"input-footer-container"}>
              <input
                placeholder={this.state.displayName}
                type='text'
                value={this.state.inputValue}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
              />
              {this.getFooter()}
            </div>
            <div className='selected-values-container'>
              {this.renderSelectedValues()}
            </div>
          </div>
        </Col>
      </div>
    );
  }
}

export default MultipleTextView;
