import React, { Component } from "react";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import Slider from "react-rangeslider";
import Collapsible from "react-collapsible";
import {
  addConceptToQuery,
  removeConceptToQuery,
  removeConceptToQueryAllValues,
} from "./../../QueryTool";
import ModalWindow from "../../../ModalWindow/ModalWindowComponent";
import CheckBox from "../../../../fields/Checkbox/Checkbox";
import createCriterion from "../../../../utils/createCriterion";
import { createListConsts } from "../../../../utils/constants";
import "react-rangeslider/lib/index.css";
import "./FilterSection.scss";
import { ParamsContext } from "../../../../../hooks/ParamsContext";

class FilterSection extends Component {
  static contextType = ParamsContext;

  constructor(props) {
    super(props);
    this.state = {
      checkedItems: new Map(),
      values: {},
      quickFilterCheckedItems: [],
      operatorArrayQuickFilter: [],
      filterStr: "",
      isModalLoading: false,
      preFiltersList: {},
      preFiltersInputValues: {},
      startedLoading: false,
      currentQuery: {},
    };

    this.resetFilters = this.resetFilters.bind(this);
    this.handleCheckChildElement = this.handleCheckChildElement.bind(this);
    this.ifItChecked = this.ifItChecked.bind(this);
    this.useSavedTargeting = this.useSavedTargeting.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    this.getSelectedQuickFilters();
    this.getSelectedPreFilters();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.pageIsLoading) {
      this.setState({
        startedLoading: true,
      });
    }
    if (!newProps.pageIsLoading && this.state.startedLoading) {
      this.getSelectedQuickFilters(newProps);
      this.getSelectedPreFilters(newProps);
      this.setState({
        startedLoading: false,
      });
    }

    this.setState({
      isModalLoading: false,
      currentQuery: newProps.currentQuery,
    });
  }

  getSelectedQuickFilters(props) {
    let currentProps = props;
    if (!props) {
      currentProps = this.props;
    }

    const qf = currentProps.components.quickFilter;
    const selectedItem = [];
    if (qf) {
      if (qf.famillies) {
        qf.famillies.forEach((el) => {
          if (el.famillyItems[0].conceptClauses[0].filters) {
            el.famillyItems[0].conceptClauses[0].filters.forEach((sf) => {
              const conceptName = sf.conceptRef;

              el.famillyItems[0].criterias[0].concepts.forEach((concept) => {
                if (concept.conceptName === conceptName) {
                  const _item = {
                    label: concept.values[0].label,
                    value: concept.values[0].value,
                    filterId: el.id,
                    family: concept.family,
                    name: concept.conceptName,
                    type: concept.type,
                  };
                  selectedItem.push(_item);
                }
              });
            });
          }
        });
      }
    }

    this.setState({
      quickFilterCheckedItems: selectedItem,
    });
  }

  getSelectedPreFilters(props) {
    let currentProps = props;
    if (!props) {
      currentProps = this.props;
    }
    const newProps = {};
    let name = "";
    const mf = currentProps.components.myFilters;
    if (mf) {
      if (mf.famillies) {
        mf.famillies.forEach((f) => {
          const selectedItems = {};
          const preFiltersInputValues = {};
          switch (f.id) {
            case "myPrefiltersList":
              f.famillyItems.forEach((fi) => {
                selectedItems[fi.id] = {
                  active: fi.active,
                  id: fi.id,
                  value: this.getPrefilterValue(fi.active, +fi.value),
                };

                if (fi.familyItemOption) {
                  selectedItems[fi.id]["familyItemOption"] =
                    fi.familyItemOption;
                  preFiltersInputValues[fi.id] = fi.familyItemOption.value;
                }
              });

              this.setState({
                preFiltersList: selectedItems,
                preFiltersInputValues,
              });
              break;
            case "myfiltersList":
              name = "customFiltersList";

              newProps[name] = selectedItems;
              this.setState({
                customFiltersList: selectedItems,
              });
              break;
            default:
              break;
          }
        });
      }
    }
  }

  getPrefilterValue(active, val) {
    let value = 2;
    if (active) {
      value = val === 0 ? 1 : 3;
    }
    return value;
  }

  getComponentById(id) {
    let item = null;
    Object.keys(this.props.components).forEach((i) => {
      const cm = this.props.components[i];
      if (cm.id === id) {
        item = cm;
      }
    });
    return item;
  }

  addTabsHeading() {
    return this.props.components.wizardContent.primaryPanel.filterToolbar.tabs.map(
      (tab, i) => {
        if (!tab.componentRef) {
          return null;
        }
        return (
          <TabLink key={i} to={tab.componentRef}>
            {tab.label}
          </TabLink>
        );
      },
    );
  }

  updateQueryOnQuickFilter = (elem, deleting = false) => {
    let newQueryObject = {
      query: this.state.currentQuery.query
        ? JSON.parse(this.state.currentQuery.query)
        : [],
      queryCondition: this.state.currentQuery.queryCondition
        ? JSON.parse(this.state.currentQuery.queryCondition)
        : [],
    };
    if (deleting) {
      //Deleting quick filter element from query
      if (this.state.currentQuery.query) {
        newQueryObject.query.forEach((criterionGroup, criterionGroupKey) => {
          criterionGroup.forEach((criterion, criterionKey) => {
            if (criterion[1] === elem.name && criterion[3][0] === elem.value) {
              newQueryObject.query[criterionGroupKey].splice(criterionKey, 1);
              if (!newQueryObject.query[criterionGroupKey].length) {
                newQueryObject.query.splice(criterionGroupKey, 1);
                newQueryObject.queryCondition.splice(criterionGroupKey, 1);
              }
            }
          });
        });
      }
    } else {
      //Adding quick filter element to query
      let isCriterionGroupExisting = false;
      let type = elem.type === "DIMENSION" ? 0 : 1;
      let operator;
      switch (elem.type) {
        case "SLIDER":
          operator = "NBT";
          break;
        case "AROUND_ME":
          operator = "EQ";
          break;
        default:
          operator = "EQ";
      }

      let value = [elem.value];

      let newCriteria = [type, elem.name, operator, value, 0];
      if (this.state.currentQuery.query) {
        newQueryObject.query.forEach((criterionGroup, criterionGroupKey) => {
          criterionGroup.forEach((criterion) => {
            if (criterion[1] === elem.name && !isCriterionGroupExisting) {
              newQueryObject.query[criterionGroupKey].push(newCriteria);
              isCriterionGroupExisting = true;
            }
          });
        });
      }

      if (!this.state.currentQuery.query || !isCriterionGroupExisting) {
        newQueryObject.query.push([newCriteria]);
        newQueryObject.queryCondition.push("OR");
      }
    }

    newQueryObject.query = JSON.stringify(newQueryObject.query);
    newQueryObject.queryCondition = JSON.stringify(
      newQueryObject.queryCondition,
    );

    this.props.sendQuery(createListConsts.firstStep, newQueryObject, true);
  };

  removeQuery = (element) => {
    const newQuery = removeConceptToQuery(
      {
        query: this.state.currentQuery.query
          ? JSON.parse(this.state.currentQuery.query)
          : [],
        queryCondition: this.state.currentQuery.queryCondition
          ? JSON.parse(this.state.currentQuery.queryCondition)
          : [],
      },
      element.ref,
      element.values,
      element.operator,
    );
    this.sendQueryWithTimer({
      query: JSON.stringify(newQuery.query),
      queryCondition: JSON.stringify(newQuery.queryCondition),
    });
  };

  removeAllQuery = (type) => {
    const newQuery = removeConceptToQueryAllValues(
      {
        query: this.state.currentQuery.query
          ? JSON.parse(this.state.currentQuery.query)
          : [],
        queryCondition: this.state.currentQuery.queryCondition
          ? JSON.parse(this.state.currentQuery.queryCondition)
          : [],
      },
      type,
    );
    this.sendQueryWithTimer({
      query: JSON.stringify(newQuery.query),
      queryCondition: JSON.stringify(newQuery.queryCondition),
    });
  };

  addQuery = (element, exclude, structure) => {
    const newQuery = addConceptToQuery(
      {
        query: this.state.currentQuery.query
          ? JSON.parse(this.state.currentQuery.query)
          : [],
        queryCondition: this.state.currentQuery.queryCondition
          ? JSON.parse(this.state.currentQuery.queryCondition)
          : [],
      },
      element.ref,
      element.values,
      structure,
      element.operator,
      exclude,
    );
    this.sendQueryWithTimer({
      query: JSON.stringify(newQuery.query),
      queryCondition: JSON.stringify(newQuery.queryCondition),
    });
  };

  addTabsContent(tabs) {
    if (!tabs) {
      return null;
    }
    return tabs.map((tab, i) => {
      if (!tab.componentRef) {
        return null;
      }
      const componentRef = this.getComponentById(tab.componentRef);
      if (!componentRef) {
        return null;
      }

      return (
        <TabContent key={i} for={tab.componentRef}>
          {this.addAccordion(componentRef, tab.id)}
        </TabContent>
      );
    });
  }

  setModalById(modalId, filterID) {
    return null;
  }

  /********
   * Gestion Filtre
   ********/

  updateQuery = (
    elements,
    content,
    exclude,
    activeTab,
    modal,
    conditionAnd,
  ) => {
    this.setState({
      isModalLoading: true,
    });

    // Generate query & queryCondition for each elements sent from this component
    let newCriterionGroup = [];
    elements.forEach((element) => {
      newCriterionGroup.push(
        createCriterion(element, content, exclude, conditionAnd),
      );
    });

    let newQueryObject = {
      query: this.state.currentQuery.query
        ? JSON.parse(this.state.currentQuery.query)
        : [],
      queryCondition: this.state.currentQuery.queryCondition
        ? JSON.parse(this.state.currentQuery.queryCondition)
        : [],
    };

    //Is it a useTreeGroupCriteriaHasCondition or groupCriteria
    let groupCriteria = null;
    let foundGroupCriteria = false;

    if (content.option.useTreeGroupCriteriaHasCondition) {
      groupCriteria = activeTab.treeGroupCriteria;
    } else if (modal && modal.groupCriteria) {
      groupCriteria = modal.groupCriteria;
    }

    if (groupCriteria) {
      groupCriteria.forEach((actualGroup) => {
        actualGroup.conceptRef.forEach((actualGroupConceptRef) => {
          // Mapping on criterias inside a group to find the right group
          content.concepts.forEach((concept) => {
            if (actualGroupConceptRef === concept.conceptName) {
              groupCriteria = actualGroup;
              foundGroupCriteria = true;
            }
          });
        });
      });
    }

    // We will use this boolean to check if we already added criterions inside a criterionGroup,
    // if true, don't do anything else
    // if false, let's simply add newCriterionGroup to newQueryObject
    let isCriterionGroupExisting = false;

    if (this.state.currentQuery.query) {
      // Simple feature to send query
      // Mapping on criterionGroups
      newQueryObject.query.forEach((criterionGroup, criterionGroupKey) => {
        // Mapping on criterions for each criterionGroups
        criterionGroup.forEach((criterion) => {
          // Mapping on component concepts (normally 1, but for example 2 inside CheckboxTree component)
          content.concepts.forEach((concept) => {
            const criterionConceptName = criterion[1];
            // Checking if we have to add our newCriterionGroup inside this existing criterionGroup
            if (
              criterionConceptName === concept.conceptName &&
              !isCriterionGroupExisting &&
              !foundGroupCriteria
            ) {
              // We've found a criterionGroup where to set our newCriterionGroup
              // Setting criterionGroup to newCriterionGroup
              newQueryObject.query[criterionGroupKey] = [];
              newCriterionGroup.forEach((newCriterionGroup) => {
                newQueryObject.query[criterionGroupKey].push(
                  newCriterionGroup.query,
                );
              });
              // Checking if the criterionGroup is now empty
              // If empty, remove this criterionGroup
              if (
                !newQueryObject.query[criterionGroupKey].length ||
                !newCriterionGroup.length
              ) {
                // Delete criterion group
                newQueryObject.query.splice(criterionGroupKey, 1);
                newQueryObject.queryCondition.splice(criterionGroupKey, 1);
              } else {
                // Set queryCondition if exclude changed
                newQueryObject.queryCondition[criterionGroupKey] =
                  newCriterionGroup[0].queryCondition;
              }
              // Setting groupExisting to true to stop mapping & prevent adding values again after
              isCriterionGroupExisting = true;
            }
          });
        });
      });

      // groupCriteria feature, the goal is to add multiple conceptRefs inside one criteriaGroup
      if (foundGroupCriteria && !isCriterionGroupExisting) {
        // Now mapping on criterionGroups to find the criterionGroup where adding values from this component
        newQueryObject.query.forEach((criterionGroup, criterionGroupKey) => {
          // Mapping on criterions for each criterionGroups
          criterionGroup.forEach((criterion) => {
            const criterionConceptName = criterion[1];
            groupCriteria.conceptRef.forEach((actualGroupConceptRef) => {
              if (
                actualGroupConceptRef === criterionConceptName &&
                !isCriterionGroupExisting
              ) {
                // Removing all values with the same component refs inside the criterionGroup
                let elementsToRemove = [];
                content.concepts.forEach((concept) => {
                  criterionGroup.forEach((criterion, criterionKey) => {
                    if (criterion[1] === concept.conceptName) {
                      elementsToRemove.push(criterion);
                    }
                  });
                });

                elementsToRemove.forEach((element) => {
                  const indexToRemove =
                    newQueryObject.query[criterionGroupKey].indexOf(element);
                  if (indexToRemove > -1) {
                    newQueryObject.query[criterionGroupKey].splice(
                      indexToRemove,
                      1,
                    );
                  }
                });

                // Adding all component criterions inside this existing criterionGroup
                newCriterionGroup.forEach((newCriterion) => {
                  newQueryObject.query[criterionGroupKey].push(
                    newCriterion.query,
                  );
                });

                // Check if the criterionGroup is empty, if so, delete the criterionGroup & queryCondition
                if (!newQueryObject.query[criterionGroupKey].length) {
                  newQueryObject.query.splice(criterionGroupKey, 1);
                  newQueryObject.queryCondition.splice(criterionGroupKey, 1);
                }

                // Setting groupExisting to true to stop mapping & prevent adding values again after
                isCriterionGroupExisting = true;
              }
            });
          });
        });
      }
    }

    // We didn't find a criterionGroup or it's the first criterion to add
    // Let's create the criterionGroup with all components values
    if (
      (!isCriterionGroupExisting || !this.state.currentQuery.query) &&
      newCriterionGroup.length
    ) {
      newCriterionGroup.forEach((criterionGroup) => {
        newQueryObject.query.push([criterionGroup.query]);
      });
      if (groupCriteria) {
        newQueryObject.queryCondition.push(groupCriteria.clause);
      } else {
        newQueryObject.queryCondition.push(newCriterionGroup[0].queryCondition);
      }
    }

    newQueryObject.query = JSON.stringify(newQueryObject.query);
    newQueryObject.queryCondition = JSON.stringify(
      newQueryObject.queryCondition,
    );

    // Use timer to send query, stop timer and launch it again if the timer is actually running
    this.sendQueryWithTimer(newQueryObject);
  };

  sendQueryWithTimer(newQueryObject) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.setState(
      {
        currentQuery: newQueryObject,
      },
      () => {
        this.timer = setTimeout(() => {
          this.props.sendQuery(
            createListConsts.firstStep,
            newQueryObject,
            false,
          );
        }, 1000);
      },
    );
  }

  resetFilters() {
    let blankQuery = {
      clear_query: true,
    };

    this.props.sendQuery(createListConsts.firstStep, blankQuery, true);
  }

  sendPreFilterQuery() {
    const segments = [];

    Object.keys(this.state.preFiltersList).forEach((id) => {
      const filter = this.state.preFiltersList[id];

      if (+filter.value === 1 || +filter.value === 3) {
        let newVal = 0;
        if (+filter.value === 3) {
          newVal = 1;
        }
        if (filter.familyItemOption) {
          const preFilterInputvalue = this.state.preFiltersInputValues[id];
          segments.push([id, newVal, preFilterInputvalue]);
        } else {
          segments.push([id, newVal]);
        }
      }
    });

    // Ready URL
    const q = {
      segments: JSON.stringify(segments),
    };

    this.props.sendQuery(createListConsts.firstStep, q, true);
  }
  /********
   * Gestion Filtre
   ********/

  makeUrl(q, qc) {
    const urlStr = "";
    return urlStr;
  }

  addAccordion(accordionElement, id) {
    if (!accordionElement) {
      return null;
    }
    switch (id) {
      case "filterToolbar-quickFilter":
        return accordionElement.famillies.map((el, i) => {
          const items = [];

          el.famillyItems[0].criterias[0].concepts.forEach((f) => {
            const _item = {
              label: f.values[0].label,
              value: f.values[0].value,
              filterId: el.id,
              family: f.family,
              name: f.conceptName,
              type: f.type,
            };
            items.push(_item);
          });

          return (
            <Collapsible
              key={i}
              trigger={
                <div className='title-container'>
                  <span className='title'>
                    <span
                      className={"icon icon-" + (el.icon ? el.icon : "support")}
                    ></span>
                    {el.label}
                    <span className={"selection" + (el.active ? " on" : "")}>
                      {el.description}
                    </span>
                  </span>
                </div>
              }
            >
              <React.Fragment>
                <div className='check-wrapper'>
                  <ul>{this.getFamilyCheckboxes(items)}</ul>
                </div>
              </React.Fragment>
            </Collapsible>
          );
        });
      case "filterToolbar-mlistFilter":
        return accordionElement.famillyFilter.map((el, i) => {
          return (
            <Collapsible
              key={i}
              trigger={
                <div className='title-container'>
                  <span className='title'>
                    <span
                      className={"icon icon-" + (el.icon ? el.icon : "support")}
                    ></span>
                    {el.label}
                    <span className={"selection" + (el.active ? " on" : "")}>
                      {el.description}
                    </span>
                  </span>
                </div>
              }
            >
              <React.Fragment>
                <div className='btn-wrapper'>
                  {this.getCartridgeModal(el, "filterWrapper")}
                </div>
              </React.Fragment>
            </Collapsible>
          );
        });
      case "filterToolbar-myFilters":
        return accordionElement.famillies.map((el, i) => {
          return (
            <div
              className={"mes-filters" + (el.subLabel ? " with-subtitle" : "")}
              key={i}
            >
              <Collapsible
                trigger={
                  <div className='title-container'>
                    <span className='title'>
                      {el.label}
                      <span className={"selection" + (el.active ? " on" : "")}>
                        {el.description}
                      </span>
                    </span>
                  </div>
                }
              >
                <React.Fragment>
                  <div className='check-wrapper'>
                    {el.subLabel ? (
                      <span className='subtitle'>{el.subLabel}</span>
                    ) : null}
                    <div className='my-filters-holder'>
                      {this.getMyFilters(el.famillyItems, el.id)}
                    </div>
                  </div>
                </React.Fragment>
              </Collapsible>
            </div>
          );
        });
      default:
        return null;
    }
  }

  getCartridgeModal(el, filterID) {
    let items = [];
    if (el.cartridgeFilters) {
      items = el.cartridgeFilters;
    }
    if (el.famillyItems) {
      items = el.famillyItems;
    }

    return items.map((m, i) => {
      return (
        <ModalWindow
          updateQuery={this.updateQuery}
          addQuery={this.addQuery}
          removeQuery={this.removeQuery}
          removeAllQuery={this.removeAllQuery}
          sendQueryWithTimer={this.sendQueryWithTimer}
          key={i}
          active={m.active}
          modalTitle={m.label}
          accId={el.id}
          currentModal={this.setModalById(m.refModalFilterId, filterID)}
          modalFilterId={m.refModalFilterId}
          currentQuery={this.state.currentQuery}
          updateValue={this.updateValue}
          isLoading={this.props.isModalLoading}
        />
      );
    });
  }

  handleCheckChildElement(elem) {
    let checkedArray = this.state.quickFilterCheckedItems;
    let index = -1;
    let isDeleting = false;

    checkedArray.forEach((e, i) => {
      if (JSON.stringify(e) === JSON.stringify(elem)) {
        index = i;
      }
    });

    if (index > -1) {
      isDeleting = true;
      checkedArray.splice(index, 1);
    } else {
      checkedArray.push(elem);
    }

    this.setState(
      {
        quickFilterCheckedItems: checkedArray,
      },
      () => {
        this.updateQueryOnQuickFilter(elem, isDeleting);
      },
    );
  }

  ifItChecked(i) {
    if (!this.state.quickFilterCheckedItems) {
      return null;
    }
    const newArray = this.state.quickFilterCheckedItems.filter((e) => {
      return JSON.stringify(e) === JSON.stringify(i);
    });
    return newArray.length > 0;
  }

  getFamilyCheckboxes(items) {
    if (!items) {
      return null;
    }

    return items.map((el, i) => {
      return (
        <li key={i}>
          <CheckBox
            handleCheckChildElement={() => {
              this.handleCheckChildElement(el);
            }}
            checked={this.ifItChecked(el)}
            value={el.value}
          >
            {this.ifItChecked(el)}
            {el.label}
          </CheckBox>
        </li>
      );
    });
  }

  getTitle(content) {
    if (content === undefined) {
      return null;
    }
    return <h2>{content.primaryPanel.title.title}</h2>;
  }

  getSubTitle(content) {
    if (content === undefined) {
      return null;
    }
    return <h3>{content.primaryPanel.title.subTitle}</h3>;
  }

  getTabs(content) {
    if (content === undefined) {
      return null;
    }
    if (!content.primaryPanel) {
      return null;
    }
    if (!content.primaryPanel.filterToolbar) {
      return null;
    }
    return (
      <Tabs className='tabs'>
        <div className='tab-heading'>
          {this.addTabsHeading()}
          {this.getExtraActions(
            content.primaryPanel.filterToolbar.extraActions,
          )}
        </div>
        {this.addTabsContent(content.primaryPanel.filterToolbar.tabs)}
      </Tabs>
    );
  }

  selectPreFilter = (id, value) => {
    const preFiltersList = this.state.preFiltersList;

    if (preFiltersList[id]) {
      if (preFiltersList[id].value === value) {
        return null;
      }
    }

    preFiltersList[id].value = value;

    this.setState(
      {
        preFiltersList,
      },
      () => {
        // Create query and send it
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.sendPreFilterQuery();
        }, 1000);
      },
    );
  };

  getSliderClass(item) {
    let classText = "";

    if (item.value === 1) {
      classText = "red";
    }
    if (item.value === 3) {
      classText = "green";
    }

    return classText;
  }

  useSavedTargeting(id) {
    this.props.useSavedTargeting(id);
  }

  updatePreFilterInputValue(id, value) {
    let preFiltersInputValues = this.state.preFiltersInputValues;

    preFiltersInputValues[id] = value;

    this.setState({
      preFiltersInputValues,
    });
  }

  getMyFilters(el, id) {
    if (!el) {
      return null;
    }
    switch (id) {
      case "targetingList":
        return (
          <ul className='my-filters'>
            {el.map((e, i) => {
              return (
                <li key={i}>
                  <span className='title'>{e.label}</span>
                  <span
                    onClick={() => this.useSavedTargeting(e.id)}
                    className='fake-link'
                  >
                    Utiliser
                  </span>
                </li>
              );
            })}
          </ul>
        );
      case "myPrefiltersList":
        if (!Object.keys(this.state.preFiltersList).length) {
          return null;
        }
        return (
          <ul className='my-filters'>
            {el.map((e, i) => {
              return (
                <li key={i}>
                  <span className='title'>{e.label}</span>
                  {e.familyItemOption && (
                    <div className='family-item-option'>
                      <span className='label'>{e.familyItemOption.label}</span>
                      <input
                        type='number'
                        value={this.state.preFiltersInputValues[e.id]}
                        onChange={(event) =>
                          this.updatePreFilterInputValue(
                            e.id,
                            event.target.value,
                          )
                        }
                      />
                      <span className='label'>{e.familyItemOption.unit}</span>
                    </div>
                  )}
                  <div
                    className={
                      "prefilter-slider " +
                      this.getSliderClass(this.state.preFiltersList[e.id])
                    }
                  >
                    <Slider
                      min={1}
                      max={3}
                      orientation='horizontal'
                      value={
                        this.state.preFiltersList[e.id]
                          ? this.state.preFiltersList[e.id].value
                          : 2
                      }
                      onChange={(val) => this.selectPreFilter(e.id, val)}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        );
      case "myfiltersList":
        return el.map((e, i) => {
          return (
            <div key={i} className='btn-wrapper'>
              <ModalWindow
                updateQuery={this.updateQuery}
                key={i}
                active={e.active}
                modalTitle={e.label}
                modalFilterId={e.refModalFilterId}
                currentModal={this.setModalById(
                  e.refModalFilterId,
                  "myFiltersWrapper",
                )}
                currentQuery={this.props.currentQuery}
                isLoading={this.props.isModalLoading}
              />
            </div>
          );
        });
      default:
        return null;
    }
  }

  getExtraActions(actions) {
    return <ul className='extra-actions'>{this.getResetFilters(actions)}</ul>;
  }

  getResetFilters(actions) {
    if (!actions) {
      return null;
    }
    return actions.map((action, i) => {
      return (
        <li key={i} className='extra-action-el'>
          <span
            onClick={(e) => this.resetFilters()}
            className={
              "icon icon-" +
              (action.id === "filterToolbar-reset" ? "refresh" : "")
            }
          ></span>
        </li>
      );
    });
  }

  render() {
    if (Object.keys(this.props.components).length === 0) {
      return null;
    }
    return (
      <>
        <div className='filter-section h-100'>
          <header className='header-section'>
            {this.getTitle(this.props.components.wizardContent)}
            {this.getSubTitle(this.props.components.wizardContent)}
          </header>

          {this.getTabs(this.props.components.wizardContent)}
        </div>
      </>
    );
  }
}

export default FilterSection;
