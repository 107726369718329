import React from "react";
import { CSVLink } from "react-csv";

import { createListConsts } from "../../../../utils/constants";

export default function Ventilation({ ventilation, sendQuery }) {
  const headers = [
    { label: "Identifiant", key: "id" },
    { label: "Dimension", key: "dimension" },
    { label: "Potentiel", key: "potentiel" },
  ];
  const data =
    ventilation &&
    ventilation.lines.map((line) => {
      return { id: line.id, dimension: line.label, potentiel: line.value };
    });

  const csvReport = data && {
    data: data,
    headers: headers,
    filename: "ventilation.csv",
  };

  const changeDimension = (dimension) => {
    sendQuery(
      createListConsts.firstStep,
      {
        ventilation: dimension,
      },
      true,
    );
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
          }}
        >
          Famille d'activité{" "}
          <input
            id={"radio-dimension"}
            type='radio'
            checked={ventilation.dimension === "mlist.familleActivites"}
            onChange={() => changeDimension("mlist.familleActivites")}
          />
        </div>
        <div
          style={{
            marginLeft: "10px",
          }}
        >
          Activité{" "}
          <input
            id={"radio-dimension"}
            type='radio'
            checked={ventilation.dimension === "mlist.activites"}
            onChange={() => changeDimension("mlist.activites")}
          />
        </div>
        <div
          style={{
            marginLeft: "10px",
          }}
        >
          Forme juridique
          <input
            id={"radio-dimension"}
            type='radio'
            checked={ventilation.dimension === "mlist.formeJuridique"}
            onChange={() => changeDimension("mlist.formeJuridique")}
          />
        </div>
        <div
          style={{
            marginLeft: "10px",
          }}
        >
          Région{" "}
          <input
            id={"radio-dimension"}
            type='radio'
            checked={ventilation.dimension === "mlist.region"}
            onChange={() => changeDimension("mlist.region")}
          />
        </div>
        <div
          style={{
            marginLeft: "10px",
          }}
        >
          Département{" "}
          <input
            id={"radio-dimension"}
            type='radio'
            checked={ventilation.dimension === "mlist.departement"}
            onChange={() => changeDimension("mlist.departement")}
          />
        </div>

        <div
          style={{
            fontSize: "30px",
            marginLeft: "10px",
            alignItems: "center",
          }}
        >
          <CSVLink {...csvReport}>
            <i className='icon icon-csv' />
          </CSVLink>
        </div>
      </div>
      <div className='table-targeting table-section'>
        <div className='table-holder'>
          <div className='table-wrapper'>
            <div className='colored-heading'>
              <div className='white-borders'>
                <table>
                  <thead>
                    <tr>
                      <th>Identifiant</th>
                      <th>Dimension</th>
                      <th>Potentiel</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ventilation &&
                      ventilation.lines.map((line, key) => (
                        <tr key={key}>
                          <td>{line.id}</td>
                          <td>{line.label}</td>
                          <td>{line.formatedValue}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
