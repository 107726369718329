import React, { useState } from "react";
import Modal from "react-responsive-modal";
import axios from "axios";
import Select from "react-select";
import { NavLink } from "react-router-dom";

import AnimatedDropdown from "../../AnimatedDropdown/AnimatedDropdown";
import CreateAdminUserQuota from "./CreateAdminUserQuota";

export default function CreateAdminUser({
  accountId,
  closePopup,
  user,
  options,
  sendQueryToApi,
}) {
  const {
    segmentations,
    formatedCreditSiret,
    creditSiret,
    formatedCreditEmailNominatif,
    creditEmailNominatif,
    hasCreditSiret,
    hasCreditEmailNominatif,
    hasUsersQuotaCreditSiret,
    hasUsersQuotaCreditEmailNominatif,
    formatedCreditSiretLeft,
    formatedCreditEmailNominatifLeft,
    canEditSegmentation,
    canEditQuota,
  } = options;
  const [state, setState] = useState({
    email: user.email ? user.email : "",
    read: !!user.roles.includes("role.read"),
    write: !!user.roles.includes("role.write"),
    admin: !!user.roles.includes("role.admin"),
    access: !!user.roles.includes("role.access"),
    organization: user.organization || "",
    segmentation: {
      value: user.segmentation || null,
      label: segmentations[user.segmentation] || "Toute la base mList",
    },
    editRoles: user.editRoles === false ? false : true,
    quotaSiret: user.quotaSiret,
    quotaEmailNominatif: user.quotaEmailNominatif,
  });
  const [error, setError] = useState({});
  const {
    read,
    write,
    admin,
    access,
    email,
    segmentation,
    quotaSiret,
    quotaEmailNominatif,
    organization,
  } = state;

  const emailRegexp = /^[\w\-+]+(.[\w-]+)*@[\w-]+(.[\w-]+)*.[\w-]{2,4}$/;

  const updateRole = (id) => {
    switch (id) {
      case "read":
        if (read) {
          setState({
            ...state,
            read: false,
            admin: false,
          });
        } else {
          setState({
            ...state,
            read: true,
            write: false,
            admin: false,
          });
        }
        break;
      case "write":
        if (write) {
          setState({
            ...state,
            write: false,
            admin: false,
          });
        } else {
          setState({
            ...state,
            write: true,
            read: false,
            admin: false,
          });
        }
        break;
      case "admin":
        setState({
          ...state,
          admin: !admin,
          read: !admin,
          write: !admin,
          access: !admin,
        });
        break;
      case "access":
        if (access) {
          setState({
            ...state,
            access: false,
            admin: false,
          });
        } else {
          setState({
            ...state,
            access: true,
            admin: false,
          });
        }
        break;
      default:
        setState({
          ...state,
          read: false,
          write: false,
          access: false,
          admin: false,
        });
    }
  };
  return (
    <Modal
      classNames={{
        modal: "modal create-filter-modal",
      }}
      open={true}
      onClose={closePopup}
      focusTrapped={false}
      center
    >
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          const headers = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          const roles = [
            read && "role.read",
            write && "role.write",
            admin && "role.admin",
            access && "role.access",
          ];
          const users = {
            email: email,
            accountId,
            roles,
            segmentation: segmentation.value,
            quotaSiret,
            quotaEmailNominatif,
            organization,
          };
          if (!emailRegexp.test(email)) {
            setError({
              ...error,
              email: "Veuillez respecter le format requis.",
            });
            return;
          }
          if (user.email) {
            await axios.put("/api/mlist/web/users", users, headers);
            sendQueryToApi({}, closePopup);
          } else {
            const { data } = await axios.get(
              `/api/mlist/web/users/${accountId}/exist/${email}`,
            );
            if (data) {
              setError({
                ...error,
                email: "Cet utilisateur existe déjà.",
              });
              return;
            } else {
              await axios.post("/api/mlist/web/users", users, headers);
              sendQueryToApi({}, closePopup);
            }
          }
        }}
      >
        <div className='single-tab-modal create-filter'>
          <header>
            <span>Créer un accès</span>
          </header>
          <AnimatedDropdown
            title='Ajouter un utilisateur'
            isClose={user.email}
            content={
              <div className='single-tab-modal-content admin-user'>
                <h3 className='step-title'>
                  Saisissez l'email de l'utilisateur.
                </h3>
                <input
                  type='text'
                  name='filter-name'
                  id='filter-name'
                  className='step-input'
                  value={email}
                  onChange={(e) => {
                    setError({
                      ...error,
                      email: "",
                    });
                    setState({
                      ...state,
                      email: e.target.value,
                    });
                  }}
                  placeholder='Email'
                  disabled={user.email}
                  formNoValidate
                />
                {error.email && <p className='error-input'>{error.email}</p>}
              </div>
            }
          />
          {user.canRoles && (
            <AnimatedDropdown
              title='Gérer les permissions'
              isClose={!state.editRoles}
              content={
                <div className='single-tab-modal-content'>
                  <h3 className='step-title'>
                    Affectez une ou plusieurs permission(s) à l'utilisateur.
                  </h3>
                  <div className='main-checkbox'>
                    <label
                      className={`${!state.editRoles ? "label-disable" : ""}`}
                    >
                      <div
                        className={`custom-checkbox ${admin ? "checked" : ""} ${
                          !state.editRoles ? " custom-checkbox-disable" : ""
                        }`}
                      >
                        <input
                          type='checkbox'
                          onChange={() => updateRole("admin")}
                          checked={admin}
                          disabled={!state.editRoles}
                        />
                      </div>
                      Gestion du compte
                    </label>
                    <p className='sub-text'>
                      L'utilisateur peut créer des ciblages, listes d'export ou
                      d'envoi, filtres personnalisés et préfiltres. Il peut
                      également intervenir sur les fichiers créés par un autre
                      utilisateur et a accès à l'ensemble des fonctionnalités
                      disponibles dans l'Administration.
                    </p>
                  </div>
                  <div className='sub-checkbox'>
                    <label
                      className={`${!state.editRoles ? "label-disable" : ""}`}
                    >
                      <div
                        className={`custom-checkbox ${write ? "checked" : ""} ${
                          !state.editRoles ? " custom-checkbox-disable" : ""
                        }`}
                      >
                        <input
                          type='checkbox'
                          onChange={() => updateRole("write")}
                          checked={write}
                          disabled={!state.editRoles}
                        />
                      </div>
                      Écriture
                    </label>
                    <p className='sub-text'>
                      L'utilisateur peut créer des ciblages, listes d'export ou
                      d'envoi, filtres personnalisés et préfiltres. Il peut
                      également accéder aux fichiers créés par un autre
                      utilisateur.
                    </p>
                  </div>
                  <div className='sub-checkbox'>
                    <label
                      className={`${!state.editRoles ? "label-disable" : ""}`}
                    >
                      <div
                        className={`custom-checkbox ${read ? "checked" : ""} ${
                          !state.editRoles ? " custom-checkbox-disable" : ""
                        }`}
                      >
                        <input
                          type='checkbox'
                          onChange={() => updateRole("read")}
                          checked={read}
                          disabled={!state.editRoles}
                        />
                      </div>
                      Lecture
                    </label>
                    <p className='sub-text'>
                      L'utilisateur peut créer des ciblages et accéder aux
                      fichiers créés par un autre utilisateur.
                    </p>
                  </div>
                  <hr />
                  <div className='sub-checkbox'>
                    <label
                      className={`${!state.editRoles ? "label-disable" : ""}`}
                    >
                      <div
                        className={`custom-checkbox ${
                          access ? "checked" : ""
                        } ${
                          !state.editRoles ? " custom-checkbox-disable" : ""
                        }`}
                      >
                        <input
                          type='checkbox'
                          onChange={() => updateRole("access")}
                          checked={access}
                          disabled={!state.editRoles}
                        />
                      </div>
                      Gestion des accès
                    </label>
                    <p className='sub-text'>
                      L'utilisateur peut ajouter un nouvel utilisateur et/ou
                      modifier les conditions d'accès d'un utilisateur existant.
                    </p>
                  </div>
                </div>
              }
            />
          )}
          {canEditSegmentation && (
            <AnimatedDropdown
              title='Affecter une segmentation (optionnel)'
              isClose={!user.email}
              content={
                <div className='single-tab-modal-content'>
                  <h3 className='step-title'>
                    Vous pouvez définir une segmentation par défaut à partir de
                    vos ciblages.
                    <br />
                    Celle-ci servira de base de travail à votre utilisateur.
                  </h3>
                  <Select
                    className='select'
                    classNamePrefix='react-select'
                    value={segmentation}
                    onChange={(selectedSegmentation) => {
                      setState({
                        ...state,
                        segmentation: selectedSegmentation,
                      });
                    }}
                    options={[
                      {
                        value: null,
                        label: "Toute la base mList",
                      },
                    ].concat(
                      Object.keys(segmentations).map((key) => ({
                        value: key,
                        label: segmentations[key],
                      })),
                    )}
                  />
                  <div className='link-row'>
                    <span className='icon icon-createList' />
                    <NavLink
                      to={`/createList/${accountId}/`}
                      className='link-entry link-icon link-tooltip'
                    >
                      Créer une segmentation
                      <p className='tooltiptext'>
                        Attention : en cliquant sur ce lien vous quittez le
                        formulaire. Vos données ne seront pas enregistrées.
                      </p>
                    </NavLink>
                  </div>
                </div>
              }
            />
          )}
          {canEditQuota && (
            <AnimatedDropdown
              title='Limiter la consommation de crédits (optionnel)'
              isClose={!user.email}
              content={
                <div className='single-tab-modal-content'>
                  <h3 className='step-title'>
                    Saisissez le volume de crédits maximum que l'utilisateur
                    pourra consommer.
                  </h3>
                  <div className='quota-credit'>
                    <i className='icon icon-propos' />
                    <i>
                      NB : les crédits d'envoi d'emails ne peuvent pas être
                      limités par utilisateur.
                      <br />
                      Si vous souhaitez limiter la consommation par utilisateur
                      pensez à affecter des limites de crédits à chacun d'entre
                      eux. Un utilisteur sans limite peut consommer la totalité
                      des crédits disponibles sur le compte.
                    </i>
                  </div>
                  {hasCreditSiret && (
                    <CreateAdminUserQuota
                      hasUsersQuotaCredit={hasUsersQuotaCreditSiret}
                      label={"de données entreprises"}
                      quota={state.quotaSiret}
                      updateQuota={(quota) =>
                        setState({
                          ...state,
                          quotaSiret: quota,
                        })
                      }
                      formatedCreditLeft={formatedCreditSiretLeft}
                      formatedCredit={formatedCreditSiret}
                      credit={creditSiret}
                    />
                  )}
                  {hasCreditEmailNominatif && (
                    <CreateAdminUserQuota
                      hasUsersQuotaCredit={hasUsersQuotaCreditEmailNominatif}
                      label={"d'emails fonctionnels"}
                      quota={state.quotaEmailNominatif}
                      updateQuota={(quota) =>
                        setState({
                          ...state,
                          quotaEmailNominatif: quota,
                        })
                      }
                      formatedCreditLeft={formatedCreditEmailNominatifLeft}
                      formatedCredit={formatedCreditEmailNominatif}
                      credit={creditEmailNominatif}
                    />
                  )}
                </div>
              }
            />
          )}
          <AnimatedDropdown
            title='Organisation (optionnel)'
            isClose={!user.email}
            content={
              <div className='single-tab-modal-content'>
                <h3 className='step-title'>
                  Précisez le département et/ou le service de l'utilisateur pour
                  affiner votre organisation.
                </h3>
                <input
                  type='text'
                  name='organisation-filter'
                  id='organisation-filter'
                  className='step-input'
                  value={state.organization}
                  maxLength={255}
                  onChange={(e) =>
                    setState({
                      ...state,
                      organization: e.target.value,
                    })
                  }
                  placeholder='Département, service, etc.'
                />
              </div>
            }
          />
        </div>
        <div className='admin-actions-holder actions-holder'>
          <button
            className={
              "btn btn-primary " +
              (email && (read || write || admin || access) ? "" : "disabled")
            }
            type='submit'
          >
            Enregistrer
          </button>
          <button className='btn btn-secondary' onClick={closePopup}>
            Annuler
          </button>
        </div>
      </form>
    </Modal>
  );
}
