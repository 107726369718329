import React, { Component } from "react";

import "./SelectModalComponent.scss";
import { Col, Row } from "react-flexbox-grid";
import Select from "react-select";

class SelectModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.props.content.concepts[0].values,
      selectedValue: null,
      defaultLabel: this.props.content.option.defaultLabel,
      resetEnabled: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.resetSelect = this.resetSelect.bind(this);
  }

  componentDidMount() {
    this.setValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.concept !== this.props.concept) {
      this.setValues(newProps);
    }
  }

  setValues(props) {
    this.setState(
      {
        resetEnabled: false,
      },
      () => {
        props.concept.forEach((concept) => {
          if (concept.filters && concept.filters.length) {
            concept.filters
              .filter(
                (filter) =>
                  props.content.concepts[0].conceptName === filter.conceptRef,
              )
              .forEach((filter) => {
                props.content.concepts[0].values.forEach((value, index) => {
                  if (value.value === filter.expressions[0]) {
                    this.setState({
                      selectedValue: props.content.concepts[0].values[index],
                      resetEnabled: true,
                    });
                  }
                });
              });
          } else {
            this.setState({
              selectedValue: null,
            });
          }
        });
      },
    );
  }

  handleChange(selectedValue) {
    this.setState(
      {
        selectedValue,
      },
      () => this.sendQuery([selectedValue]),
    );
  }

  sendQuery(arrayToSend) {
    this.props.updateQuery(
      arrayToSend,
      this.props.content,
      0,
      this.props.tab,
      this.props.modal,
    );
  }

  resetSelect() {
    this.setState(
      {
        selectedValue: null,
        resetEnabled: false,
      },
      () => {
        this.sendQuery([]);
      },
    );
  }

  render() {
    return (
      <Row className='modal modal-select modal-content'>
        <Col md={6}>
          <Select
            className='select'
            classNamePrefix='react-select'
            value={this.state.selectedValue}
            placeholder={this.state.defaultLabel}
            onChange={this.handleChange}
            options={this.state.values}
          />
        </Col>
        <Col md={6}>
          <button
            onClick={this.resetSelect}
            className={
              "refresh-btn " + (!this.state.resetEnabled ? "disabled" : "")
            }
          >
            Réinitialiser <span className='icon icon-refresh'></span>
          </button>
        </Col>
      </Row>
    );
  }
}

export default SelectModalComponent;
