import React, { Component } from "react";

import "./RadioIconModalComponent.scss";
import ModalFooter from "../../ModalFooter/ModalFooterComponent";
import { Row, Col } from "react-flexbox-grid";
import ResetFilter from "../reset/ResetFilters";

class RadioIconView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.props.content.concepts[0].values,
      exclude: false,
      selectedRadioVal: "cancel",
    };

    this.handler = this.handler.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    // Update values
    const cancelButton = this.props.content.option.cancelButton;
    if (cancelButton) {
      const values = this.state.values;

      let haveCancel = false;

      values.forEach((el) => {
        if (el.value === "cancel") {
          haveCancel = true;
        }
      });

      if (!haveCancel) {
        values.unshift({
          label: cancelButton.label,
          icon: cancelButton.icon, // Need to update once mock is done
          value: "cancel",
        });
      }

      this.setState({
        values,
      });
    }

    if (this.props.concept && this.props.concept.length > 0) {
      let val = this.props.concept[0].filters[0].expressions[0];

      let newEx = this.props.concept[0].filters[0].not;

      this.setState({
        exclude: newEx,
      });

      this.setState({
        selectedRadioVal: val,
      });
    }
  }

  updateRadio(e) {
    this.setState(
      {
        selectedRadioVal: e.value,
      },
      () => {
        this.props.updateQuery(
          this.state.selectedRadioVal !== "cancel"
            ? [
                {
                  value: this.state.selectedRadioVal,
                },
              ]
            : [],
          this.props.content,
          this.state.exclude,
          this.props.tab,
          this.props.modal,
        );
      },
    );
  }

  getRadio(radio_list) {
    if (!radio_list) {
      return null;
    }
    return radio_list.map((el, i) => {
      return (
        <Col sm={12} md={3} key={i} className='radio-item'>
          <label
            className={
              "radio-icon-holder" +
              (this.state.selectedRadioVal === el.value ? " active" : "")
            }
          >
            <div className='custom-icon-radio'>
              <input
                id={"radio-" + i}
                type='radio'
                checked={this.state.selectedRadioVal === el.value}
                onChange={(e) => this.updateRadio(el)}
                value={el.value}
              />
            </div>
            <span className='radio-content'>
              <span className={"radio-icon icon " + el.icon}></span>
              <span className='radio-title'>{el.label}</span>
            </span>
          </label>
        </Col>
      );
    });
  }

  handler(checked) {
    this.setState(
      {
        exclude: checked,
      },
      () => {
        var value =
          this.state.selectedRadioVal !== "cancel"
            ? [
                {
                  value: this.state.selectedRadioVal,
                },
              ]
            : [];
        if (value.length) {
          this.props.updateQuery(
            value,
            this.props.content,
            this.state.exclude,
            this.props.tab,
            this.props.modal,
          );
        }
      },
    );
  }

  getFooter() {
    return (
      <ModalFooter
        handleChange={this.handler}
        exclude={this.state.exclude}
        option={this.props.content.option}
      />
    );
  }

  handleReset = () => {
    this.setState(
      {
        selectedRadioVal: "cancel",
      },
      () => {
        this.props.updateQuery(
          this.state.selectedRadioVal !== "cancel"
            ? [
                {
                  value: this.state.selectedRadioVal,
                },
              ]
            : [],
          this.props.content,
          this.state.exclude,
          this.props.tab,
          this.props.modal,
        );
      },
    );
  };

  render() {
    return (
      <div className='modal'>
        <ResetFilter handleDelete={this.handleReset} />
        <div
          className={
            "modal-content modal-icon-radio" +
            (this.state.exclude ? " exclude" : " include")
          }
        >
          <div className='radio-icon-wrapper'>
            <Row className='custom-radio-list'>
              {this.getRadio(this.state.values)}
            </Row>
          </div>
        </div>
        {this.getFooter()}
      </div>
    );
  }
}
export default RadioIconView;
