import React, { Component } from "react";
import axios from "axios";
import { ParamsContext } from "../hooks/ParamsContext";
import { Col, Row } from "react-flexbox-grid";

import ContentHeader from "../shared/components/ContentHeader/ContentHeader";
import ListsTableComponent from "../shared/components/ListsTable/ListsTableComponent";
import CreateListPageComponent from "../shared/components/CreateList/CreateListComponent";
import PageLoader from "../shared/components/pageLoader/pageLoaderComponent";
import AccountPageComponent from "../shared/components/Account/AccountPage";
import MyPreFiltersComponent from "../shared/components/MyPreFilters/MyPreFiltersComponent";
import Loader from "../shared/components/loader/loaderComponent";
import MyFiltersComponent from "../shared/components/MyFilters/MyFiltersComponent";
import Dashboard from "../shared/components/Dashboard/Dashboard";
import Admin from "../shared/components/Admin/Admin";
import { format, parse } from "date-fns";

class Page extends Component {
  BASE_DOMAIN = process.env.REACT_APP_API_URL;
  static contextType = ParamsContext;
  cancelPageAxios;

  config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  constructor(props) {
    super(props);
    this.myInterval = null;
    this.state = {
      data: null,
      pageIsLoading: true,
      opacityLoader: false,
      modalIsLoading: false,
      callingAPI: true,
      createListStepLoading: false,
      isMobile: false,
    };
  }

  handleIsMobile = () => {
    window.innerWidth <= 767
      ? this.setState({ isMobile: true })
      : this.setState({ isMobile: false });
  };

  componentDidMount() {
    this.callApi(
      this.props.match.params.id,
      this.props.match.params.accountId,
      getAllParameter(this.props.location.search),
    );
    // Get props for page
    this.handleIsMobile();
    window.addEventListener("resize", this.handleIsMobile.bind(this));
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (
      this.props.history.location.pathname.includes("myListsExport") &&
      this.myInterval === null
    ) {
      this.myInterval = setInterval(() => {
        this.callApi(
          this.props.match.params.id,
          this.props.match.params.accountId,
          getAllParameter(this.props.location.search),
        );
        this.handleIsMobile();
        window.addEventListener("resize", this.handleIsMobile.bind(this));
      }, 30000);
    } else if (
      !this.props.history.location.pathname.includes("myListsExport")
    ) {
      clearInterval(this.myInterval);
      this.myInterval = null;
    }
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
    this.myInterval = null;
    window.removeEventListener("resize", this.handleIsMobile.bind(this));
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState(
        {
          data: null,
          pageIsLoading: true,
        },
        () => {
          this.callApi(
            nextProps.match.params.id,
            nextProps.match.params.accountId,
          );
        },
      );
    }
  };

  reloadPage = () => {
    this.setState(
      {
        pageIsLoading: true,
        opacityLoader: true,
      },
      () => {
        this.callApi(
          this.props.match.params.id,
          this.props.match.params.accountId,
        );
      },
    );
  };

  callApi = (id, accountId, dataRequest, callBack) => {
    if (this.cancelPageAxios) {
      this.cancelPageAxios();
    }

    let FULL_URL = `${this.BASE_DOMAIN}getPage?id=${id}&ds=${accountId}&hl=fr`;

    const query = new URLSearchParams();
    let stepId = "step-ciblage";
    let listTypeValue = "EXPORT";

    if (id === "createList") {
      if (dataRequest) {
        if (dataRequest.stepId) {
          stepId = dataRequest.stepId;
        }

        if (dataRequest.params) {
          Object.keys(dataRequest.params).forEach((j) => {
            query.append(j, dataRequest.params[j]);
          });
        }
      }

      let targetingId = "";
      if (this.props.location.search) {
        const parsedQuery = this.parseQuery(this.props.location.search);
        if (parsedQuery.myTargetingId) {
          targetingId = `&myTargetingId=${parsedQuery.myTargetingId}`;
        }
        // Clear search params
        this.props.history.replace(this.props.location.pathname);
      }

      // Set stepId in the URL
      FULL_URL = `${FULL_URL}&stepId=${stepId}${targetingId}`;
    } else if (dataRequest) {
      Object.keys(dataRequest).forEach((j) => {
        query.append(j, dataRequest[j]);
      });
    }

    if (id === "myLists") {
      if (dataRequest) {
        if (dataRequest.listTypeValue) {
          listTypeValue = dataRequest.listTypeValue;
        }
      }

      // Set listTypeValue in the URL
      FULL_URL = `${FULL_URL}&listType=${listTypeValue}`;
    }

    axios
      .post(FULL_URL, query, {
        ...this.config,
        cancelToken: new axios.CancelToken((c) => {
          this.cancelPageAxios = c;
        }),
      })
      .then((response) => {
        this.cancelPageAxios = null;
        this.setState(() => ({
          data: response.data,
          currentPage: id,
          pageIsLoading: false,
          opacityLoader: false,
          modalIsLoading: false,
          callingAPI: false,
          createListStepLoading: false,
        }));
        callBack && callBack();
      })
      .catch((error) => {
        this.setState(() => ({
          currentPage: id,
          pageIsLoading: false,
          opacityLoader: false,
          modalIsLoading: false,
          callingAPI: false,
          createListStepLoading: false,
        }));
      });
  };

  sendQueryToApiWithCallBack = (params, callBack) => {
    this.sendQueryToApi(params, false, callBack);
  };

  sendQueryToApi = (params, betweenSteps, callBack) => {
    const newState = {
      modalIsLoading: true,
      callingAPI: true,
    };
    if (betweenSteps) {
      newState["createListStepLoading"] = true;
    }
    this.context.setParams(params);
    this.setState(newState, () => {
      this.callApi(
        this.props.match.params.id,
        this.props.match.params.accountId,
        params,
        callBack,
      );
    });
  };

  getPageContent() {
    if (this.state.data === null) {
      return null;
    }

    // Create an object of components keys
    const page = {
      id: this.state.data.id,
      type: this.state.data.type,
      components: {},
    };
    if (this.state.data.components) {
      Object.keys(this.state.data.components).map((i) => {
        let item = this.state.data.components[i];
        page.components[item.id || item.type] = item;
        return false;
      });
    }
    const tableId = this.state.data.id;

    if (this.state.currentPage === "myAccount") {
      return (
        <>
          <ContentHeader accountId={this.props.match.params.accountId} />
          <AccountPageComponent
            components={page.components}
            data={this.state.data}
            isMobile={this.state.isMobile}
            page={page}
          />
        </>
      );
    } else if (!this.props.active) {
      return (
        <>
          <ContentHeader accountId={this.props.match.params.accountId} />
          <div className='page-heading'>
            <Row className='ovv'>
              <Col xs={12}>
                <h2>Votre compte est inactif</h2>
                <p>
                  Votre compte a été désactivé, pour plus d'informations notre
                  service clients se tient à votre disposition :{" "}
                  <a href='mailto:contact.aix@altares.com'>
                    contact.aix@altares.com
                  </a>
                </p>
              </Col>
            </Row>
          </div>
        </>
      );
    } else if (
      page.components[tableId] &&
      page.components[tableId].type === "MesListesTable"
    ) {
      return (
        <>
          <ContentHeader accountId={this.props.match.params.accountId} />
          <div className='scrollAuto'>
            <div className='page-heading'>
              <Col xs={12}>
                <h2>{page.components[tableId].title}</h2>
                <p>{page.components[tableId].subTitle}</p>
              </Col>
            </div>
            <ListsTableComponent
              pageId={this.state.currentPage}
              key={tableId}
              table={page.components[tableId]}
              accountId={this.props.match.params.accountId}
              sendQueryToApi={this.sendQueryToApi}
            />
          </div>
        </>
      );
    }

    switch (this.state.currentPage) {
      case "dashboard":
        return (
          <Dashboard
            key={page.id}
            isModalLoading={this.state.modalIsLoading}
            pageIsLoading={this.state.callingAPI}
            components={page.components}
            accountId={this.props.match.params.accountId}
            isMobile={this.state.isMobile}
          />
        );
      case "createList":
        let parameters = {};
        if (this.state.data.parameters) {
          parameters = this.state.data.parameters;
        } else {
          parameters = {
            id: this.props.match.params.id,
            stepId: this.state.stepId || "step-ciblage",
          };
        }
        return (
          <>
            <div
              id='loadercontainer'
              className={
                this.state.createListStepLoading || this.props.isLoading
                  ? "active"
                  : ""
              }
            >
              <Loader
                isLoading={
                  this.state.createListStepLoading || this.props.isLoading
                }
              ></Loader>
            </div>
            <CreateListPageComponent
              key={page.id}
              parameters={parameters}
              isModalLoading={this.state.modalIsLoading}
              pageIsLoading={this.state.callingAPI}
              sendQueryToApi={this.sendQueryToApi}
              components={page.components}
              accountId={this.props.match.params.accountId}
              isMobile={this.state.isMobile}
            />
          </>
        );
      case "myPrefilter":
        return (
          <>
            <ContentHeader accountId={this.props.match.params.accountId} />
            <MyPreFiltersComponent
              pageId={this.state.currentPage}
              key={tableId}
              table={page.components[tableId]}
              accountId={this.props.match.params.accountId}
              reloadPage={this.reloadPage}
              isMobile={this.state.isMobile}
              sendQueryToApi={this.sendQueryToApi}
            />
          </>
        );
      case "myFilter":
        return (
          <>
            <ContentHeader accountId={this.props.match.params.accountId} />
            <MyFiltersComponent
              pageId={this.state.currentPage}
              accountId={this.props.match.params.accountId}
              components={page.components}
              data={this.state.data}
              reloadPage={this.reloadPage}
              isMobile={this.state.isMobile}
              sendQueryToApi={this.sendQueryToApi}
            />
          </>
        );
      case "administration":
        return (
          <Admin
            pageId={this.state.currentPage}
            accountId={this.props.match.params.accountId}
            components={page.components}
            isMobile={this.state.isMobile}
            sendQueryToApi={this.sendQueryToApiWithCallBack}
          />
        );
      default:
        return null;
    }
  }

  showPageLoader() {
    if (!this.state.pageIsLoading && !this.props.isLoading) {
      return null;
    }
    return <PageLoader opacity={this.state.opacityLoader} />;
  }

  parseQuery(queryString) {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  render() {
    return (
      <div className='board-content h-100'>
        <div className='h-100 por'>
          {this.showPageLoader()}
          {this.getPageContent()}
        </div>
      </div>
    );
  }
}

function getDateParameters(searchParam) {
  let date00 = format(new Date(), "yyyyMMdd");
  let date01 = format(new Date(), "yyyyMMdd");
  const params = new URLSearchParams(searchParam);
  if (params.has("date00")) {
    date00 = parse(params.get("date00"), "yyyyMMdd", new Date());
    if (params.has("date01")) {
      date01 = parse(params.get("date01"), "yyyyMMdd", new Date());
    }
  } else {
    if (params.has("date01")) {
      date01 = parse(params.get("date01"), "yyyyMMdd", new Date());
      date00 = new Date(date01.setMonth(date01.getMonth() + 1));
    } else {
      return null;
    }
  }
  return [date00, date01];
}
function getTabIdParameter(searchParam) {
  const params = new URLSearchParams(searchParam);
  if (params.has("tabId")) {
    return params.get("tabId");
  }
  return null;
}

function getAllParameter(searchParam) {
  if (getTabIdParameter(searchParam) != null) {
    if (getDateParameters(searchParam) != null) {
      const dates = getDateParameters(searchParam);
      return {
        tabId: getTabIdParameter(searchParam),
        stat_date_begin: format(dates[0], "yyyyMMdd"),
        stat_date_end: format(dates[1], "yyyyMMdd"),
      };
    } else {
      return { tabId: getTabIdParameter(searchParam) };
    }
  } else {
    if (getDateParameters(searchParam) != null) {
      const dates = getDateParameters(searchParam);
      return {
        stat_date_begin: format(dates[0], "yyyyMMdd"),
        stat_date_end: format(dates[1], "yyyyMMdd"),
      };
    } else {
      return null;
    }
  }
}

export default Page;
